import React from 'react';

import Tooltip from 'components/tooltip';
import IconComponent from 'components/icon';

const SingleTagWithAttributes = ({ handleCopy, attributes, tagName }) => (
  <Tooltip content="Clip to copy code to your clipboard!">
    <div
      className="implementation-container"
      onClick={() => handleCopy('#markup-with-attributes')}
    >
      <section
        id="markup-with-attributes"
        className="markup-with-attributes"
      >
        <div className="line">
          <span className="tag-name">{`<${tagName}`}</span>
        </div>
        {attributes?.map((attribute, index) => {
          return attribute?.value ? (
            <div className={`line line-${index}`} key={attribute.name}>
              <span className="tag-attribute">{attribute?.name}</span>
              <span className="tag-attribute">=</span>
              <span className="tag-attribute-value">
                {attribute?.value}
              </span>
            </div>
          ) : (
            <div className={`line line-${index}`}>
              <span className="tag-attribute">{`${attribute?.name}`}</span>
            </div>
          );
        })}
        <div className={`line`}>
          <span className="tag-name">{` >`}</span>
        </div>
      </section>
      <div className="tag-copy">
        <IconComponent iconName="IoClipboardOutline" size="30px" />
      </div>
    </div>
  </Tooltip>
);

export default SingleTagWithAttributes;
