import React from 'react';

import Text from 'components/text';

const ReleaseNoteClosed = ({
  entry,
  setShowNote,
  setReleaseToDisplay
}) => {
  const handleClick = (entry) => {
    setReleaseToDisplay(entry);
    setShowNote(true);
  };

  return (
    <section className="release-note">
      <div className="release-note-row" onClick={() => handleClick(entry)}>
        <Text text="Release" fontWeight="bold" margin="0 10px 0 0" />
        <Text text={entry?.version} />
      </div>
    </section>
  );
};

export default ReleaseNoteClosed;
