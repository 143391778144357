import React from 'react';

import Text from 'components/text';
import Container from 'components/container';

const AttributeDescription = ({ attribute }) => {
  return (
    <Container>
      <Text
        margin="0"
        width="100%"
        fontSize="18px"
        textAlign="left"
        fontWeight="bold"
        text="Description"
      />
      <Text
        width="100%"
        textAlign="left"
        margin="20px 0 40px 0"
        text={attribute?.description}
      />
    </Container>
  );
};

export default AttributeDescription;
