import React from 'react';

import { learnCss } from 'data/css/learn';

import DescriptionEntry from 'components/description-entry';

const RuleStructure = () => {
  const item = learnCss?.items[2];

  return <DescriptionEntry entry={item} />;
};

export default RuleStructure;
