import React from 'react';

import Text from 'components/text';
import Tooltip from 'components/tooltip';

const DisplayedSearchResults = ({ results, makeButtonNavigate }) => (
  <div className="search-results">
    {results?.map((entry) => {
      return (
        <div
          key={entry?.title}
          className="search-result"
          onClick={() => makeButtonNavigate(entry)}
        >
          <Tooltip top content="Click to learn more information.">
            <Text
              fontSize="14px"
              margin="20px 0"
              textAlign="left"
              fontWeight="bold"
              text={entry.title}
            />
            {entry?.value && (
              <Text
                fontSize="14px"
                lineHeight={2}
                textAlign="left"
                margin="0 0 20px 0"
                text={entry?.value}
              />
            )}
            {entry?.description && (
              <Text
                fontSize="14px"
                lineHeight={2}
                textAlign="left"
                margin="0 0 20px 0"
                text={entry?.description}
              />
            )}
          </Tooltip>
        </div>
      );
    })}
  </div>
);

export default DisplayedSearchResults;
