import React from 'react';

import Text from 'components/text';

const PropertyDescription = ({ property }) => {
  return (
    <div className="property-description">
      <Text
        margin="0"
        width="100%"
        fontSize="18px"
        textAlign="left"
        fontWeight="bold"
        text="Description"
      />
      <Text
        width="100%"
        textAlign="left"
        lineHeight="1.5"
        margin="20px 0 40px 0"
        text={property?.description}
      />
    </div>
  );
};

export default PropertyDescription;
