import React from 'react';

import './index.scss';

const Button = ({ buttonText, handleClick, type, restOfProps }) => {
  return (
    <button
      onClick={() => handleClick()}
      className={type === 'secondary' ? 'button-secondary' : 'button'}
      {...restOfProps}
    >
      {buttonText}
    </button>
  );
};

export default Button;
