import React from 'react';

import { learnData } from 'data/html/learn';

import DescriptionEntry from 'components/description-entry';

const FileNaming = () => {
  const item = learnData?.items.find(
    (item) => item.title === 'HTML File Naming'
  );

  return <DescriptionEntry entry={item} />;
};

export default FileNaming;
