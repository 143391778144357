import React from 'react';

import Text from 'components/text';
import IconComponent from 'components/icon';

const InfobookIcon = ({
  iconName,
  disabled,
  itemText,
  ...restOfProps
}) => (
  <div
    className={disabled ? 'infobook-item-disabled' : 'infobook-item'}
    {...restOfProps}
  >
    <IconComponent iconName={iconName} size="40px" color="#26e07f" />
    <Text
      color="#222"
      fontSize="22px"
      margin="0 auto"
      text={itemText}
      fontWeight="bold"
    />
  </div>
);

export default InfobookIcon;
