import React from 'react';

import Text from 'components/text';
import Container from 'components/container';
import CssImplementation from 'components/css-implementation';

const RuleExampleClass = () => (
  <Container width="80%" margin="20px auto">
    <Text
      fontSize="18px"
      textAlign="left"
      fontWeight="bold"
      margin="0 auto 10px 0"
      text="CSS Class Rule Example"
    />
    <Text
      lineHeight="2"
      textAlign="left"
      margin="0 auto 10px 0"
      text="Here we are targeting all elements with the class 'note' and giving them a width of 100px."
    />
    <CssImplementation
      type="class"
      selectorName=".note"
      idForCopy="css-class-example"
      properties={[{ name: 'width', value: '100px' }]}
    />
  </Container>
);

export default RuleExampleClass;
