import React, { useContext, useEffect } from 'react';

import GlobalContext from '../../GlobalContext';

import Text from 'components/text';

import './index.scss';

const NotificationModal = () => {
  const { setShowNotification, notificationStatus, notificationText } =
    useContext(GlobalContext);

  const textColor = notificationStatus === 'error' ? '#eb144c' : '#fff';

  useEffect(() => {
    setTimeout(() => setShowNotification(false), 5000);
  }, []);

  return (
    <div
      className={
        notificationStatus
          ? `notification-modal-${notificationStatus}`
          : 'notification-modal'
      }
    >
      <Text
        text={notificationText}
        color={textColor}
        fontWeight="bold"
        fontSize="20px"
      />
    </div>
  );
};

export default NotificationModal;
