import { useNavigate } from 'react-router-dom';

import Button from 'components/button';

const LinkButton = ({ buttonText, route, type }) => {
  const navigate = useNavigate();
  const makeButtonNavigate = (route) => navigate(route);

  return (
    <Button
      type={type}
      buttonText={buttonText}
      handleClick={() => makeButtonNavigate(route)}
    />
  );
};

export default LinkButton;
