import React from 'react';

import Menu from './Menu';
import LogoLink from './LogoLink';

import './index.scss';

const Header = () => {
  return (
    <div className="header">
      <LogoLink />
      <Menu />
    </div>
  );
};

export default Header;
