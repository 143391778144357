import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AboutView from 'views/about';
import NotFoundView from 'views/not-found';
import LandingPage from 'views/landing-page';
import InfobookView from 'views/infobook/home';
import InfobookCssView from 'views/infobook/css';
import InfobookHtmlView from 'views/infobook/html';
import InfobookJavaScriptView from 'views/infobook/javascript';

import Header from 'components/header';
import ViewContainer from 'components/view-container';
import NotificationModal from 'components/notification-modal';

import GlobalContext from 'GlobalContext';

import './index.scss';
import './App.scss';

function App() {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState('');
  const [notificationText, setNotificationText] = useState(
    'example notification text'
  );

  const [currentTag, setCurrentTag] = useState({});
  const [currentProperty, setCurrentProperty] = useState({});
  const [currentAttribute, setCurrentAttribute] = useState({});
  const [showDetailModal, setShowDetailModal] = useState(false);

  const [jsViewTab, setJsViewTab] = useState('About');
  const [cssViewTab, setCssViewTab] = useState('About');
  const [htmlViewTab, setHtmlViewTab] = useState('About');

  return (
    <GlobalContext.Provider
      value={{
        jsViewTab,
        currentTag,
        cssViewTab,
        htmlViewTab,
        currentProperty,
        showDetailModal,
        notificationText,
        showNotification,
        currentAttribute,
        notificationStatus,
        setJsViewTab,
        setCssViewTab,
        setCurrentTag,
        setHtmlViewTab,
        setCurrentProperty,
        setShowDetailModal,
        setNotificationText,
        setShowNotification,
        setCurrentAttribute,
        setNotificationStatus,
      }}
    >
      <BrowserRouter>
        <Header />
        {showNotification && (
          <NotificationModal notificationText={notificationText} />
        )}
        <ViewContainer>
          <Routes>
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/about" element={<AboutView />} />
            <Route exact path="/infobook" element={<InfobookView />} />
            <Route
              exact
              path="/infobook/html"
              element={<InfobookHtmlView />}
            />
            <Route exact path="/*" element={<NotFoundView />} />
            <Route
              exact
              path="/infobook/css"
              element={<InfobookCssView />}
            />
            {/* <Route
              exact
              path="/infobook/javascript"
              element={<InfobookJavaScriptView />}
            /> */}
          </Routes>
        </ViewContainer>
      </BrowserRouter>
    </GlobalContext.Provider>
  );
}

export default App;
