import React from 'react';

import LinkButton from 'components/link-button';

import './index.scss';

const NotFoundView = () => {
  return (
    <div className="not-found-view">
      <h1>Page not found . . .</h1>
      <h2>You found a url that we don't use.</h2>
      <div className="not-found-button-container">
        <h3>Try visiting our InfoBook!</h3>
        <LinkButton route="/infobook" buttonText="InfoBook" />
      </div>
    </div>
  );
};

export default NotFoundView;
