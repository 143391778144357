import React from 'react';

import IconComponent from 'components/icon';

import './index.scss';

const Search = ({ searchString, setSearchString, placeholder }) => {
  return (
    <div className="search">
      <input
        type="text"
        value={searchString}
        className="search-input"
        placeholder={placeholder}
        onChange={(e) => setSearchString(e.target.value)}
      />
      <div
        className="clear-search-icon"
        onClick={() => setSearchString('')}
      >
        <IconComponent iconName="IoCloseOutline" color="#999" />
      </div>
    </div>
  );
};

export default Search;
