import React from 'react';

import { learnCss } from 'data/css/learn';

import DescriptionEntry from 'components/description-entry';

const FileNaming = () => {
  const item = learnCss?.items[1];

  return <DescriptionEntry entry={item} />;
};

export default FileNaming;
