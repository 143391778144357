import React from 'react';

const ContactUsAccentText = () => (
  <p className="contact-us-text">
    Our site is a <span className="contact-us-text-accent">free</span> and{' '}
    <span className="contact-us-text-accent">easy</span> way to quickly
    save code snippets and other development info to use for your own
    projects!
  </p>
);

export default ContactUsAccentText;
