import LinkButton from 'components/link-button';

const ButtonSection = () => (
  <div className="landing-page-buttons">
    <LinkButton
      type="secondary"
      route="/infobook"
      buttonText="try it out"
    />
    <LinkButton buttonText="about us" route="/about" />
  </div>
);

export default ButtonSection;
