import React from 'react';

import Tooltip from 'components/tooltip';

const TableRow = ({ entry, handleRowClick, itemDescriptor = 'DATA' }) => (
  <Tooltip
    content={`Click to see more information about this ${itemDescriptor}`}
  >
    <div className="list-table-row" onClick={() => handleRowClick(entry)}>
      {entry?.listDisplay && (
        <>
          <p className="list-table-row-title">{entry?.title}</p>
          <p className="list-table-row-value">{entry?.listDisplay}</p>
        </>
      )}
      {!entry?.listDisplay && (
        <p className="list-table-row-title" style={{ margin: 'auto' }}>
          {entry?.title}
        </p>
      )}
    </div>
  </Tooltip>
);

export default TableRow;
