import React from 'react';

import Tooltip from 'components/tooltip';
import IconComponent from 'components/icon';

const CssRule = ({ handleCopy, properties, selector, idForCopy }) => {
  return (
    <Tooltip content="Clip to copy code to your clipboard!">
      <div
        className="implementation-container"
        onClick={() => handleCopy(`#${idForCopy}`)}
      >
        <section id={idForCopy} className="css-markup">
          <div className="line">
            <span className="selector">{`${selector} {`}</span>
          </div>
          {properties?.map((property, index) => {
            return property?.value ? (
              <div className={`line line-${index}`} key={property.name}>
                <span className="property">{property?.name}</span>
                <span className="property">: </span>
                <span className="property-value">{property?.value}</span>
                <span className="property">{`;`}</span>
              </div>
            ) : (
              <div className={`line line-${index}`}>
                <span className="property">{`${property?.name}`}</span>
              </div>
            );
          })}
          <div className="line">
            <span className="selector">{`}`}</span>
          </div>
        </section>
        <div className="tag-copy">
          <IconComponent iconName="IoClipboardOutline" size="30px" />
        </div>
      </div>
    </Tooltip>
  );
};

export default CssRule;
