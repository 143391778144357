import React from 'react';

const Tab = ({ tab, handleClick, activeTab }) => (
  <div
    onClick={() => handleClick(tab.text)}
    className={activeTab === tab.text ? 'tab-button-active' : 'tab-button'}
  >
    {tab.text}
  </div>
);

export default Tab;
