import React, { useContext } from 'react';

import GlobalContext from 'GlobalContext';

import CssRule from './CssRule';

import './index.scss';

const CssImplementation = ({ selectorName, properties, idForCopy }) => {
  const {
    setShowNotification,
    setNotificationText,
    setNotificationStatus,
  } = useContext(GlobalContext);

  const copyToClipboard = async (element) => {
    const copyText = document?.querySelector(element)?.innerText;
    await navigator.clipboard.writeText(copyText);
    setNotificationText('Code copied to clipboard!');
    setNotificationStatus('success');
    setShowNotification(true);
  };

  return (
    <CssRule
      idForCopy={idForCopy}
      selector={selectorName}
      properties={properties}
      handleCopy={copyToClipboard}
    />
  );
};

export default CssImplementation;
