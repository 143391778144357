import React from 'react';

import HeaderText from 'components/header-text';

import FileNaming from './FileNaming';
import RuleExample from './RuleExample';
import RuleStructure from './RuleStructure';
import WorkspaceSetup from './WorkspaceSetup';

import '../../index.scss';

const LearnTab = () => {
  return (
    <div className="tab-view">
      <HeaderText
        fontSize="30px"
        text="Learn CSS"
        fontWeight="bold"
        margin="0 0 40px 0"
      />
      <div className="inner-tab-content">
        <WorkspaceSetup />
        <FileNaming />
        <RuleStructure />
        <RuleExample />
      </div>
    </div>
  );
};

export default LearnTab;
