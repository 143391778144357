import React from 'react';

import HeaderText from 'components/header-text';

import FileNaming from './FileNaming';
import TagExample from './TagExample';
import TagStructure from './TagStructure';
import WorkSpaceSetup from './WorkSpaceSetup';
import SelfClosingTagExample from './SelfClosingTagExample';

import '../index.scss';

const LearnTab = () => (
  <div className="tab-view">
    <HeaderText
      fontSize="30px"
      fontWeight="bold"
      text="Learn HTML"
      margin="0 0 40px 0"
    />
    <div className="inner-tab-content">
      <WorkSpaceSetup />
      <FileNaming />
      <TagStructure />
      <TagExample />
      <SelfClosingTagExample />
    </div>
  </div>
);

export default LearnTab;
