import React from 'react';

import Text from 'components/text';
import IconComponent from 'components/icon';

const SearchAndReleaseNotes = ({ handleClick }) => (
  <div className="release-notes-button" onClick={() => handleClick()}>
    <IconComponent iconName="IoAlertCircleOutline" color="#ffc107" />
    <Text
      color="#fff"
      fontSize="12px"
      margin="0 10px"
      text="Release info"
    />
  </div>
);

export default SearchAndReleaseNotes;
