export const aboutCss = {
  section: 'About',
  items: [
    {
      title: 'CSS',
      value:
        'CSS, which stands for Cascading Style Sheets, is a styling language used to control the visual presentation of HTML and XML documents. It provides a set of rules that define how elements on a webpage should be displayed.',
      link: '/infobook/css',
      linkHelper: 'About',
    },
    {
      title: 'Cascading Style Sheets',
      value:
        'Cascading refers to the format that the files take during usage. Style sheets refers to the fact that styles can be in a separate file or files than the structure code. ',
      link: '/infobook/css',
      linkHelper: 'About',
    },
    {
      title: 'CSS Rules',
      value:
        'Rules are what you use to define CSS changes and consist of selectors and property declarations enclosed in curly braces. Multiple rules can be combined in a CSS file to target and style different elements.',
      link: '/infobook/css',
      linkHelper: 'About',
    },
    {
      title: 'Selectors',
      value:
        'Selectors target specific elements to which you want to apply styles. Selectors can target elements by their tag name, class, ID, attributes, and more.',
      link: '/infobook/css',
      linkHelper: 'About',
    },
    {
      title: 'Properties',
      value:
        'CSS properties define the visual characteristics of elements. Examples include color, font size, margin, padding, width, height, and background color.',
      link: '/infobook/css',
      linkHelper: 'About',
    },
    {
      title: 'Property Values',
      value:
        'Properties are assigned values to determine their specific behavior. Values can be fixed values like pixels (px), percentages (%), or relative values like relative element (em) and root element (rem). There are also keywords, such as "auto" or "none," and various color notations like hex codes (#), RGB, or named colors.',
      link: '/infobook/css',
      linkHelper: 'About',
    },
  ],
};
