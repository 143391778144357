import React from 'react';

import { learnCss } from 'data/css/learn';

import DescriptionEntry from 'components/description-entry';

const WorkspaceSetup = () => {
  const item = learnCss?.items[0];

  return <DescriptionEntry entry={item} />;
};

export default WorkspaceSetup;
