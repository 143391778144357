import React from 'react';

import Text from 'components/text';
import Container from 'components/container';
import TagImplementation from 'components/tag-implementation';

const AttributeBasicExample = ({ attribute }) => {
  return (
    <Container>
      <Text
        width="100%"
        fontSize="18px"
        textAlign="left"
        fontWeight="bold"
        text="Basic Example"
        margin="0"
      />
      <Text
        width="100%"
        textAlign="left"
        margin="20px 0 20px 0"
        text="Below is a basic example of the attribute."
      />
      <TagImplementation
        tagText="Some content"
        attributes={[attribute]}
        tagName={attribute?.tags?.[0]?.name}
      />
    </Container>
  );
};

export default AttributeBasicExample;
