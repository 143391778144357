import React from 'react';
import { useNavigate } from 'react-router-dom';

import IconComponent from 'components/icon';

import './index.scss';

const BackIcon = () => {
  const navigate = useNavigate();

  return (
    <div className="back-arrow" onClick={() => navigate('/infobook')}>
      <IconComponent iconName="IoReturnUpBackOutline" color="#fff" />
      <p>go back</p>
    </div>
  );
};

export default BackIcon;
