import React from 'react';

import Text from 'components/text';
import Container from 'components/container';
import TagImplementation from 'components/tag-implementation';

const TagBasicExample = ({ tag }) => {
  return (
    <Container>
      <Text
        margin="0"
        width="100%"
        fontSize="18px"
        textAlign="left"
        fontWeight="bold"
        text="Basic Example"
      />
      <Text
        width="100%"
        textAlign="left"
        margin="20px 0"
        text="Below is a basic example of the tag (required attributes only)."
      />
      <TagImplementation
        tagName={tag?.name}
        tagType={tag?.type}
        tagText={tag?.title}
        attributes={tag?.requiredAttributes}
      />
    </Container>
  );
};

export default TagBasicExample;
