export const cssProperties = [
  {
    name: '-webkit-line-clamp',
    title: 'Webkit Line Clamp',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `-webkit-line-clamp`,
    description:
      'The -webkit-line-clamp CSS property allows limiting of the contents of a block to the specified number of lines. It only works in combination with the display property set to -webkit-box or -webkit-inline-box.',
    values: [
      {
        name: 'none',
        description:
          "This value specifies that the content won't be clamped.",
      },
      {
        name: 'integer-value',
        description:
          'This value specifies the number of lines after which the content will be clamped. It must be greater than 0.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [{ name: '-webkit-line-clamp', value: '20' }],
    },
  },
  {
    name: '-webkit-text-fill-color',
    title: 'Webkit Text Fill Color',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `-webkit-text-fill-color`,
    description:
      'The -webkit-text-fill-color CSS property specifies the fill color of characters of text. If this property is not set, the value of the color property is used.',
    values: [
      {
        name: 'color-value',
        description:
          "The foreground fill color of the element's text content.",
      },
    ],
    tagHelper: {
      selector: 'p',
      properties: [{ name: '-webkit-text-fill-color', value: 'red' }],
    },
  },
  {
    name: '-webkit-text-stroke',
    title: 'Webkit Text Stroke',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `-webkit-text-stroke`,
    description:
      'The -webkit-text-stroke CSS property specifies the width and color of strokes for text characters. This is a shorthand property for the longhand properties -webkit-text-stroke-width and -webkit-text-stroke-color.',
    values: [
      {
        name: 'length-value',
        description: 'The width of the stroke.',
      },
      {
        name: 'color-value',
        description: 'The color of the stroke.',
      },
    ],
    tagHelper: {
      selector: 'h1',
      properties: [{ name: '-webkit-text-stroke', value: '10px blue' }],
    },
  },
  {
    name: '-webkit-text-color',
    title: 'Webkit Text Color',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `-webkit-text-stroke`,
    description:
      'The -webkit-text-color CSS property specifies the color of the text characters.',
    values: [
      {
        name: 'color-value',
        description: 'The color of the stroke.',
      },
    ],
    tagHelper: {
      selector: 'h1',
      properties: [{ name: '-webkit-text-color', value: '#333' }],
    },
  },
  {
    name: '-webkit-text-width',
    title: 'Webkit Text Width',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `-webkit-text-stroke`,
    description:
      'The -webkit-text-width CSS property specifies the width of the text characters.',
    values: [
      {
        name: 'length-value',
        description: 'The width of the stroke.',
      },
    ],
    tagHelper: {
      selector: 'h2',
      properties: [{ name: '-webkit-text-width', value: '3px' }],
    },
  },
  {
    name: 'accent-color',
    title: 'Accent Color',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `accent-color`,
    description:
      'The accent-color CSS property sets the accent color for user-interface controls generated by some elements.',
    values: [
      {
        name: 'auto',
        description:
          'Represents a UA-chosen color, which should match the accent color of the platform, if any.',
      },
      {
        name: 'color-value',
        description: 'Specifies the color to be used as the accent color.',
      },
    ],
    tagHelper: {
      selector: 'input',
      properties: [{ name: 'accent-color', value: 'blue' }],
    },
  },
  {
    name: 'align-content',
    title: 'Align Content',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `align-content`,
    description:
      "The CSS align-content property sets the distribution of space between and around content items along a flexbox's cross-axis or a grid's block axis.",
    values: [
      {
        name: 'start',
        description:
          'The items are packed flush to each other against the start edge of the alignment container in the cross axis.',
      },
      {
        name: 'end',
        description:
          'The items are packed flush to each other against the end edge of the alignment container in the cross axis.',
      },
      {
        name: 'flex-start',
        description:
          "The items are packed flush to each other against the edge of the alignment container depending on the flex container's cross-start side. This only applies to flex layout items. For items that are not children of a flex container, this value is treated like start.",
      },
      {
        name: 'flex-end',
        description:
          "The items are packed flush to each other against the edge of the alignment container depending on the flex container's cross-end side. This only applies to flex layout items. For items that are not children of a flex container, this value is treated like end.",
      },
      {
        name: 'center',
        description:
          'The items are packed flush to each other in the center of the alignment container along the cross axis.',
      },
      {
        name: 'normal',
        description:
          'The items are packed in their default position as if no align-content value was set.',
      },
      {
        name: 'baseline',
        description:
          "Specifies participation in first- or last-baseline alignment: aligns the alignment baseline of the box's first or last baseline set with the corresponding baseline in the shared first or last baseline set of all the boxes in its baseline-sharing group. The fallback alignment for first baseline is start, the one for last baseline is end.",
      },
      {
        name: 'space-between',
        description:
          'The items are evenly distributed within the alignment container along the cross axis. The spacing between each pair of adjacent items is the same. The first item is flush with the start edge of the alignment container in the cross axis, and the last item is flush with the end edge of the alignment container in the cross axis.',
      },
      {
        name: 'space-around',
        description:
          'The items are evenly distributed within the alignment container along the cross axis. The spacing between each pair of adjacent items is the same. The empty space before the first and after the last item equals half of the space between each pair of adjacent items.',
      },
      {
        name: 'space-evenly',
        description:
          'The items are evenly distributed within the alignment container along the cross axis. The spacing between each pair of adjacent items, the start edge and the first item, and the end edge and the last item, are all exactly the same.',
      },
      {
        name: 'stretch',
        description:
          'If the combined size of the items along the cross axis is less than the size of the alignment container, any auto-sized items have their size increased equally (not proportionally), while still respecting the constraints imposed by max-height/max-width (or equivalent functionality), so that the combined size exactly fills the alignment container along the cross axis.',
      },
      {
        name: 'safe',
        description:
          'Used alongside an alignment keyword. If the chosen keyword means that the item overflows the alignment container causing data loss, the item is instead aligned as if the alignment mode were start.',
      },
      {
        name: 'unsafe',
        description:
          'Used alongside an alignment keyword. Regardless of the relative sizes of the item and alignment container and whether overflow which causes data loss might happen, the given alignment value is honored.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        { name: 'display', value: 'flex' },
        { name: 'align-content', value: 'center' },
      ],
    },
  },
  {
    name: 'align-items',
    title: 'Align Items',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `align-items`,
    description:
      'The CSS align-items property sets the align-self value on all direct children as a group. In Flexbox, it controls the alignment of items on the Cross Axis. In Grid Layout, it controls the alignment of items on the Block Axis within their grid area.',
    values: [
      {
        name: 'start',
        description:
          'The items are packed flush to each other toward the start edge of the alignment container in the appropriate axis.',
      },
      {
        name: 'end',
        description:
          'The items are packed flush to each other toward the end edge of the alignment container in the appropriate axis.',
      },
      {
        name: 'flex-start',
        description:
          'The cross-start margin edges of the flex items are flushed with the cross-start edge of the line.',
      },
      {
        name: 'flex-end',
        description:
          'The cross-end margin edges of the flex items are flushed with the cross-end edge of the line.',
      },
      {
        name: 'center',
        description:
          "The flex items' margin boxes are centered within the line on the cross-axis. If the cross-size of an item is larger than the flex container, it will overflow equally in both directions.",
      },
      {
        name: 'normal',
        description:
          "The effect of this keyword is dependent of the layout mode we are in. In absolutely-positioned layouts, the keyword behaves like start on replaced absolutely-positioned boxes, and as stretch on all other absolutely-positioned boxes. In static position of absolutely-positioned layouts, the keyword behaves as stretch. For flex items, the keyword behaves as stretch. For grid items, this keyword leads to a behavior similar to the one of stretch, except for boxes with an aspect ratio or an intrinsic sizes where it behaves like start. The property doesn't apply to block-level boxes, and to table cells.",
      },
      {
        name: 'baseline',
        description:
          'All flex items are aligned such that their flex container baselines align. The item with the largest distance between its cross-start margin edge and its baseline is flushed with the cross-start edge of the line.',
      },
      {
        name: 'stretch',
        description:
          "Flex items are stretched such that the cross-size of the item's margin box is the same as the line while respecting width and height constraints.",
      },
      {
        name: 'safe',
        description:
          'Used alongside an alignment keyword. If the chosen keyword means that the item overflows the alignment container causing data loss, the item is instead aligned as if the alignment mode were start.',
      },
      {
        name: 'unsafe',
        description:
          'Used alongside an alignment keyword. Regardless of the relative sizes of the item and alignment container and whether overflow which causes data loss might happen, the given alignment value is honored.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [{ name: 'align-items', value: 'center' }],
    },
  },
  {
    name: 'align-self',
    title: 'Align Self',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `align-self`,
    description:
      "The align-self CSS property overrides a grid or flex item's align-items value. In Grid, it aligns the item inside the grid area. In Flexbox, it aligns the item on the cross axis.",
    values: [
      {
        name: 'auto',
        description: "Computes to the parent's align-items value.",
      },
      {
        name: 'start',
        description:
          'The items are packed flush to each other toward the start edge of the alignment container in the appropriate axis.',
      },
      {
        name: 'end',
        description:
          'The items are packed flush to each other toward the end edge of the alignment container in the appropriate axis.',
      },
      {
        name: 'flex-start',
        description:
          'The cross-start margin edges of the flex items are flushed with the cross-start edge of the line.',
      },
      {
        name: 'flex-end',
        description:
          'The cross-end margin edges of the flex items are flushed with the cross-end edge of the line.',
      },
      {
        name: 'center',
        description:
          "The flex item's margin box is centered within the line on the cross-axis. If the cross-size of the item is larger than the flex container, it will overflow equally in both directions.",
      },
      {
        name: 'normal',
        description:
          "The effect of this keyword is dependent of the layout mode we are in. In absolutely-positioned layouts, the keyword behaves like start on replaced absolutely-positioned boxes, and as stretch on all other absolutely-positioned boxes. In static position of absolutely-positioned layouts, the keyword behaves as stretch. For flex items, the keyword behaves as stretch. For grid items, this keyword leads to a behavior similar to the one of stretch, except for boxes with an aspect ratio or an intrinsic sizes where it behaves like start. The property doesn't apply to block-level boxes, and to table cells.",
      },
      {
        name: 'baseline',
        description:
          'All flex items are aligned such that their flex container baselines align. The item with the largest distance between its cross-start margin edge and its baseline is flushed with the cross-start edge of the line.',
      },
      {
        name: 'stretch',
        description:
          'If the combined size of the items along the cross axis is less than the size of the alignment container and the item is auto-sized, its size is increased equally (not proportionally), while still respecting the constraints imposed by max-height/max-width (or equivalent functionality), so that the combined size of all auto-sized items exactly fills the alignment container along the cross axis.',
      },
      {
        name: 'safe',
        description:
          'Used alongside an alignment keyword. If the chosen keyword means that the item overflows the alignment container causing data loss, the item is instead aligned as if the alignment mode were start.',
      },
      {
        name: 'unsafe',
        description:
          'Used alongside an alignment keyword. Regardless of the relative sizes of the item and alignment container and whether overflow which causes data loss might happen, the given alignment value is honored.',
      },
    ],
    tagHelper: {
      selector: 'section',
      properties: [{ name: 'align-self', value: 'center' }],
    },
  },
  {
    name: 'all',
    title: 'All',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `all`,
    description:
      "The all shorthand CSS property resets all of an element's properties except unicode-bidi, direction, and CSS Custom Properties. It can set properties to their initial or inherited values, or to the values specified in another cascade layer or stylesheet origin.",
    values: [
      {
        name: 'initial',
        description:
          "Specifies that all the element's properties should be changed to their initial values.",
      },
      {
        name: 'inherit',
        description:
          "Specifies that all the element's properties should be changed to their inherited values.",
      },
      {
        name: 'unset',
        description:
          "Specifies that all the element's properties should be changed to their inherited values if they inherit by default, or to their initial values if not.",
      },
      {
        name: 'revert',
        description:
          'Specifies behavior that depends on the stylesheet origin to which the declaration belongs: If the rule belongs to the author origin, the revert value rolls back the cascade to the user level, so that the specified values are calculated as if no author-level rules were specified for the element. For purposes of revert, the author origin includes the Override and Animation origins. If the rule belongs to the user origin, the revert value rolls back the cascade to the user-agent level, so that the specified values are calculated as if no author-level or user-level rules were specified for the element(s). If the rule belongs to the user-agent origin, the revert value acts like unset.',
      },
      {
        name: 'revert-layer',
        description:
          "Specifies that all the element's properties should roll back the cascade to a previous cascade layer, if one exists. If no other cascade layer exists, the element's properties will roll back to the matching rule, if one exists, in the current layer or to a previous style origin.",
      },
    ],
    tagHelper: {
      selector: 'body',
      properties: [
        {
          name: 'all',
          value: 'initial',
        },
      ],
    },
  },
  {
    name: 'animation',
    title: 'Animation',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `animation`,
    description:
      'The animation shorthand CSS property applies an animation between styles. It is a shorthand for animation-name, animation-duration, animation-timing-function, animation-delay, animation-iteration-count, animation-direction, animation-fill-mode, animation-play-state, and animation-timeline',
    values: [
      {
        name: 'single-easing-function ',
        description:
          'Determines the type of transition. The value must be one of those available in <easing-function>.',
      },
      {
        name: 'single-animation-iteration-count',
        description:
          'The number of times the animation is played. The value must be one of those available in animation-iteration-count.',
      },
      {
        name: 'single-animation-direction',
        description:
          'The direction in which the animation is played. The value must be one of those available in animation-direction.',
      },
      {
        name: 'single-animation-fill-mode',
        description:
          "Determines how styles should be applied to the animation's target before and after its execution. The value must be one of those available in animation-fill-mode.",
      },
      {
        name: 'single-animation-play-state',
        description:
          'Determines whether the animation is playing or not. The value must be one of those available in animation-play-state.',
      },
    ],
    tagHelper: {
      selector: 'svg',
      properties: [
        {
          name: 'animation',
          value: '3s linear 1s slidein',
        },
      ],
    },
  },
  {
    name: 'animation-composition',
    title: 'Animation Composition',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `animation-composition`,
    description:
      'The animation-composition CSS property specifies the composite operation to use when multiple animations affect the same property simultaneously.',
    values: [
      {
        name: 'replace',
        description:
          'The effect value overrides the underlying value of the property. This is the default value.',
      },
      {
        name: 'add',
        description:
          'The effect value builds on the underlying value of the property. This operation produces an additive effect. For animation types where the addition operation is not commutative, the order of the operands is the underlying value followed by the effect value.',
      },
      {
        name: 'accumulate',
        description:
          'The effect and underlying values are combined. For animation types where the addition operation is not commutative, the order of the operands is the underlying value followed by the effect value.',
      },
    ],
    tagHelper: {
      selector: 'svg',
      properties: [
        {
          name: 'animation-composition',
          value: 'add',
        },
      ],
    },
  },
  {
    name: 'animation-delay',
    title: 'Animation Delay',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `animation-delay`,
    description:
      'The animation-delay CSS property specifies the amount of time to wait from applying the animation to an element before beginning to perform the animation. The animation can start later, immediately from its beginning, or immediately and partway through the animation.',
    values: [
      {
        name: 'time',
        description:
          "The time offset, from the moment at which the animation is applied to the element, at which the animation should begin. This may be specified in either seconds (s) or milliseconds (ms). The unit is required. A positive value indicates that the animation should begin after the specified amount of time has elapsed. A value of 0s, which is the default, indicates that the animation should begin as soon as it's applied. A negative value causes the animation to begin immediately, but partway through its cycle. For example, if you specify -1s as the animation delay time, the animation will begin immediately but will start 1 second into the animation sequence. If you specify a negative value for the animation delay, but the starting value is implicit, the starting value is taken from the moment the animation is applied to the element.",
      },
    ],
    tagHelper: {
      selector: 'svg',
      properties: [
        {
          name: 'animation-delay',
          value: '1000ms',
        },
      ],
    },
  },
  {
    name: 'animation-direction',
    title: 'Animation Direction',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `animation-direction`,
    description:
      'The animation-direction CSS property sets whether an animation should play forward, backward, or alternate back and forth between playing the sequence forward and backward.',
    values: [
      {
        name: 'normal',
        description:
          'The animation plays forwards each cycle. In other words, each time the animation cycles, the animation will reset to the beginning state and start over again. This is the default value.',
      },
      {
        name: 'reverse',
        description:
          'The animation plays backwards each cycle. In other words, each time the animation cycles, the animation will reset to the end state and start over again. Animation steps are performed backwards, and easing functions are also reversed. For example, an ease-in easing function becomes ease-out.',
      },
      {
        name: 'alternate',
        description:
          'The animation reverses direction each cycle, with the first iteration being played forwards. The count to determine if a cycle is even or odd starts at one.',
      },
      {
        name: 'alternate-reverse',
        description:
          'The animation reverses direction each cycle, with the first iteration being played backwards. The count to determine if a cycle is even or odd starts at one.',
      },
    ],
    tagHelper: {
      selector: 'svg',
      properties: [
        {
          name: 'animation-direction',
          value: 'reverse',
        },
      ],
    },
  },
  {
    name: 'animation-duration',
    title: 'Animation Duration',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `animation-duration`,
    description:
      'The animation-duration CSS property sets the length of time that an animation takes to complete one cycle.',
    values: [
      {
        name: 'auto',
        description:
          'For time-based animations, auto is equivalent to a value of 0s (see below). For CSS scroll-driven animations, auto fills the entire timeline with the animation.',
      },
      {
        name: 'time-value',
        description:
          'The time that an animation takes to complete one cycle. This may be specified in either seconds (s) or milliseconds (ms). The value must be positive or zero and the unit is required. If no value is provided, the default value of 0s is used, in which case the animation still executes (the animationStart and animationEnd events are fired). Whether or not the animation will be visible when the duration is 0s will depend on the value of animation-fill-mode, as explained: If animation-fill-mode is set to backwards or both, the first frame of the animation as defined by animation-direction will be displayed during animation-delay countdown. If animation-fill-mode is set to forwards or both, the last frame of the animation will be displayed, as defined by animation-direction, after the animation-delay expires. If animation-fill-mode is set to none, the animation will have no visible effect.',
      },
    ],
    tagHelper: {
      selector: 'svg',
      properties: [
        {
          name: 'animation-duration',
          value: '1000ms',
        },
      ],
    },
  },
  {
    name: 'animation-fill-mode',
    title: 'Animation Fill Mode',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `animation-fill-mode`,
    description:
      'The animation-duration CSS property sets the length of time that an animation takes to complete one cycle.',
    values: [
      {
        name: 'none',
        description:
          "The animation will not apply any styles to the target when it's not executing. The element will instead be displayed using any other CSS rules applied to it. This is the default value.",
      },
      {
        name: 'forwards',
        description: `The target will retain the computed values set by the last keyframe encountered during execution. The last keyframe depends on the value of animation-direction and animation-iteration-count: In the realm of CSS animations, the property "animation-direction" has various values and corresponding behaviors. When set to "normal", regardless of whether the "animation-iteration-count" is even or odd, the last keyframe encountered is at "100%" or "to". In contrast, when set to "reverse", it again doesn't matter if the iteration count is even or odd—the last keyframe is at "0%" or "from". The "alternate" value, however, is conditional: for even iteration counts, the last keyframe hits "0%" or "from", while for odd counts, it's "100%" or "to". The "alternate-reverse" value is just the opposite of "alternate"; for even iteration counts, the animation finishes at "100%" or "to", and for odd counts, it concludes at "0%" or "from".`,
      },
      {
        name: 'backwards',
        description: `The animation will apply the values defined in the first relevant keyframe as soon as it is applied to the target, and retain this during the animation-delay period. The first relevant keyframe depends on the value of animation-direction: In CSS animations, the "animation-direction" property determines which keyframe is initially relevant. For the values "normal" or "alternate", the first relevant keyframe is "0%" or "from". Conversely, when set to "reverse" or "alternate-reverse", the animation starts with the "100%" or "to" keyframe as the primary reference.`,
      },
      {
        name: 'both',
        description: `The animation will follow the rules for both forwards and backwards, thus extending the animation properties in both directions.`,
      },
    ],
    tagHelper: {
      selector: 'svg',
      properties: [
        {
          name: 'animation-fill-mode',
          value: 'none',
        },
      ],
    },
  },
  {
    name: 'animation-iteration-count',
    title: 'Animation Iteration Count',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `animation-iteration-count`,
    description:
      'The animation-iteration-count CSS property sets the number of times an animation sequence should be played before stopping.',
    values: [
      {
        name: 'infinite',
        description: 'The animation will repeat forever.',
      },
      {
        name: 'number-value',
        description: `The number of times the animation will repeat; this is 1 by default. You may specify non-integer values to play part of an animation cycle: for example, 0.5 will play half of the animation cycle. Negative values are invalid.`,
      },
    ],
    tagHelper: {
      selector: 'svg',
      properties: [
        {
          name: 'animation-iteration-count',
          value: '2',
        },
      ],
    },
  },
  {
    name: 'animation-name',
    title: 'Animation Name',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `animation-name`,
    description:
      'The animation-name CSS property specifies the names of one or more @keyframes at-rules that describe the animation to apply to an element. Multiple @keyframe at-rules are specified as a comma-separated list of names. If the specified name does not match any @keyframe at-rule, no properties are animated.',
    values: [
      {
        name: 'none',
        description:
          'A special keyword denoting no keyframes. It can be used to deactivate an animation without changing the ordering of the other identifiers, or to deactivate animations coming from the cascade.',
      },
      {
        name: 'key-value',
        description: `A name identifying the animation. This identifier is composed of a combination of case-sensitive letters a to z, numbers 0 to 9, underscores (_), and/or dashes (-). The first non-dash character must be a letter. Also, two dashes are forbidden at the beginning of the identifier. Furthermore, the identifier can't be none, unset, initial, or inherit.`,
      },
    ],
    tagHelper: {
      selector: 'svg',
      properties: [
        {
          name: 'animation-name',
          value: 'rotate',
        },
      ],
    },
  },
  {
    name: 'animation-play-state',
    title: 'Animation Play State',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `animation-play-state`,
    description:
      'The animation-play-state CSS property sets whether an animation is running or paused.',
    values: [
      {
        name: 'running',
        description: 'The animation is currently playing.',
      },
      {
        name: 'paused',
        description: `The animation is currently paused.`,
      },
    ],
    tagHelper: {
      selector: 'svg',
      properties: [
        {
          name: 'animation-play-state',
          value: 'running',
        },
      ],
    },
  },
  {
    name: 'animation-timing-function',
    title: 'Animation Timing Function',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `animation-timing-function`,
    description:
      'The animation-timing-function CSS property sets how an animation progresses through the duration of each cycle.',
    values: [
      {
        name: 'easing-function',
        description:
          'The easing function that corresponds to a given animation, as determined by animation-name. The non-step keyword values (ease, linear, ease-in-out, etc.) each represent cubic Bézier curve with fixed four point values, with the cubic-bezier() function value allowing for a non-predefined value. The step easing functions divides the input time into a specified number of intervals that are equal in length. It is defined by a number of steps and a step position.',
      },
      {
        name: 'ease',
        description:
          'Equal to cubic-bezier(0.25, 0.1, 0.25, 1.0), the default value, increases in velocity towards the middle of the animation, slowing back down at the end.',
      },
      {
        name: 'linear',
        description:
          'Equal to cubic-bezier(0.0, 0.0, 1.0, 1.0), animates at an even speed.',
      },
      {
        name: 'ease-in',
        description:
          'Equal to cubic-bezier(0.42, 0, 1.0, 1.0), starts off slowly, with the speed of the transition of the animating property increasing until complete.',
      },
      {
        name: 'ease-out',
        description:
          'Equal to cubic-bezier(0, 0, 0.58, 1.0), starts quickly, slowing down the animation continues.',
      },
      {
        name: 'ease-in-out',
        description:
          'Equal to cubic-bezier(0.42, 0, 0.58, 1.0), with the animating properties slowly transitioning, speeding up, and then slowing down again.',
      },
      {
        name: 'cubic-bezier(p1, p2, p3, p4)',
        description:
          'An author defined cubic-bezier curve, where the p1 and p3 values must be in the range of 0 to 1.',
      },
      {
        name: 'steps(n, jumpterm)',
        description:
          'Displays an animation iteration along n stops along the transition, displaying each stop for equal lengths of time. For example, if n is 5, there are 5 steps. Whether the animation holds temporarily at 0%, 20%, 40%, 60% and 80%, on the 20%, 40%, 60%, 80% and 100%, or makes 5 stops between the 0% and 100% along the animation, or makes 5 stops including the 0% and 100% marks (on the 0%, 25%, 50%, 75%, and 100%) depends on which of the following jump terms is used: jump-start, start - Denotes a left-continuous function, so that the first jump happens when the animation begins. jump-end, end - Denotes a right-continuous function, so that the last jump happens when the animation ends. jump-none - There is no jump on either end. Instead, holding at both the 0% mark and the 100% mark, each for 1/n of the duration. jump-both Includes pauses at both the 0% and 100% marks, effectively adding a step during the animation iteration.',
      },
      {
        name: 'step-start',
        description: 'Equal to steps(1, jump-start).',
      },
      {
        name: 'step-end',
        description: 'Equal to steps(1, jump-end).',
      },
    ],
    tagHelper: {
      selector: 'svg',
      properties: [
        {
          name: 'animation-timing-function',
          value: 'linear',
        },
      ],
    },
  },
  {
    name: 'aspect-ratio',
    title: 'Aspect Ratio',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `aspect-ratio`,
    description:
      'The aspect-ratio CSS property sets a preferred aspect ratio for the box, which will be used in the calculation of auto sizes and some other layout functions.',
    values: [
      {
        name: 'auto',
        description:
          'Replaced elements with an intrinsic aspect ratio use that aspect ratio, otherwise the box has no preferred aspect ratio. Size calculations involving intrinsic aspect ratio always work with the content box dimensions.',
      },
      {
        name: 'ratio',
        description:
          "The box's preferred aspect ratio is the specified ratio of width / height. If height and the preceding slash character are omitted, height defaults to 1. Size calculations involving preferred aspect ratio work with the dimensions of the box specified by box-sizing.",
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'animation',
          value: 'bounceIn 2s',
        },
      ],
    },
  },
  {
    name: 'backdrop-filter',
    title: 'Backdrop Filter',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `backdrop-filter`,
    description:
      'The backdrop-filter CSS property lets you apply graphical effects such as blurring or color shifting to the area behind an element. Because it applies to everything behind the element, to see the effect you must make the element or its background at least partially transparent.',
    values: [
      {
        name: 'auto',
        description:
          'Replaced elements with an intrinsic aspect ratio use that aspect ratio, otherwise the box has no preferred aspect ratio. Size calculations involving intrinsic aspect ratio always work with the content box dimensions.',
      },
      {
        name: 'ratio',
        description:
          "The box's preferred aspect ratio is the specified ratio of width / height. If height and the preceding slash character are omitted, height defaults to 1. Size calculations involving preferred aspect ratio work with the dimensions of the box specified by box-sizing.",
      },
    ],
    tagHelper: {
      selector: 'section',
      properties: [
        {
          name: 'backdrop-filter',
          value: 'blur(20px) ',
        },
      ],
    },
  },
  {
    name: 'backface-visibility',
    title: 'Backface Visibility',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `backface-visibility`,
    description:
      'The backface-visibility CSS property sets whether the back face of an element is visible when turned towards the user.',
    values: [
      {
        name: 'hidden',
        description:
          'The back face is hidden, effectively making the element invisible when turned away from the user.',
      },
      {
        name: 'visible',
        description:
          'The back face is visible when turned towards the user.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'backface-visibility',
          value: 'hidden',
        },
      ],
    },
  },
  {
    name: 'background',
    title: 'Background',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `background`,
    description: `The background shorthand CSS property sets all background style properties at once, such as color, image, origin and size, or repeat method. Component properties not set in the background shorthand property value declaration are set to their default values. The background property is specified as one or more background layers, separated by commas. The background property is specified as one or more background layers, separated by commas. Each layer may include zero or one occurrences of any of the following values: <attachment>, <bg-image>, <position>, <bg-size>, <repeat-style>. The <bg-size> value may only be included immediately after <position>, separated with the '/' character, like this: "center/80%". The <box> value may be included zero, one, or two times. If included once, it sets both background-origin and background-clip. If it is included twice, the first occurrence sets background-origin, and the second sets background-clip. The <background-color> value may only be included in the last layer specified.`,
    values: [
      {
        name: 'attachment',
        description:
          "The background-attachment CSS property sets whether a background image's position is fixed within the viewport, or scrolls with its containing block.",
      },
      {
        name: 'clip',
        description:
          "The background-clip CSS property sets whether an element's background extends underneath its border box, padding box, or content box.",
      },
      {
        name: 'color',
        description:
          'The background-color CSS property sets the background color of an element.',
      },
      {
        name: 'image',
        description:
          'The background-image CSS property sets one or more background images on an element.',
      },
      {
        name: 'origin',
        description:
          "The background-origin CSS property sets the background's origin: from the border start, inside the border, or inside the padding.",
      },
      {
        name: 'position',
        description:
          'The background-position CSS property sets the initial position for each background image. The position is relative to the position layer set by background-origin.',
      },
      {
        name: 'size',
        description:
          'The background-repeat CSS property sets how background images are repeated. A background image can be repeated along the horizontal and vertical axes, or not repeated at all.',
      },
    ],
    tagHelper: {
      selector: 'section',
      properties: [
        {
          name: 'background',
          value: 'hidden',
        },
      ],
    },
  },
  {
    name: 'background-attachment',
    title: 'Background Attachment',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `background-attachment`,
    description:
      "The background-attachment CSS property sets whether a background image's position is fixed within the viewport, or scrolls with its containing block.",
    values: [
      {
        name: 'fixed',
        description:
          "The background is fixed relative to the viewport. Even if an element has a scrolling mechanism, the background doesn't move with the element. (This is not compatible with background-clip: text.)",
      },
      {
        name: 'local',
        description:
          "The background is fixed relative to the element's contents. If the element has a scrolling mechanism, the background scrolls with the element's contents, and the background painting area and background positioning area are relative to the scrollable area of the element rather than to the border framing them.",
      },
      {
        name: 'scroll',
        description:
          "The background is fixed relative to the element itself and does not scroll with its contents. (It is effectively attached to the element's border.)",
      },
    ],
    tagHelper: {
      selector: 'body',
      properties: [
        {
          name: 'background-attachment',
          value: 'fixed',
        },
      ],
    },
  },
  {
    name: 'background-blend-mode',
    title: 'Background Blend Mode',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `background-blend-mode`,
    description:
      "The background-attachment CSS property sets whether a background image's position is fixed within the viewport, or scrolls with its containing block.",
    values: [
      {
        name: 'normal',
        description:
          'This is the default value. It applies no blending, so the element appears as it normally would. ',
      },
      {
        name: 'multiply',
        description:
          'Pixels of the top layer are multiplied with corresponding pixels of the bottom layer, making the result darker.',
      },
      {
        name: 'screen',
        description:
          "This is the opposite of multiply. It lightens the colors by screening the top layer's colors with the bottom, using the formula 1 - (1 - top) * (1 - bottom).",
      },
      {
        name: 'overlay',
        description:
          "This combines multiply and screen. If the underlying color is lighter than 0.5, the screen effect is used. If it's darker, the multiply effect is used.",
      },
      {
        name: 'darken',
        description:
          'Compares each pixel of the top layer with the corresponding pixel of the bottom layer and chooses the darker of the two.',
      },
      {
        name: 'lighten',
        description:
          'Compares each pixel of the top layer with the corresponding pixel of the bottom layer and chooses the lighter of the two.',
      },
      {
        name: 'color-dodge',
        description:
          "Brightens the bottom layer based on the top layer's color, creating a more contrasted result.",
      },
      {
        name: 'color-burn',
        description:
          "Darkens the bottom layer based on the top layer's color, also increasing contrast.",
      },
      {
        name: 'hard-light',
        description:
          "Similar to overlay, but with the layers' roles reversed.",
      },
      {
        name: 'soft-light',
        description: 'Similar to hard-light but with softer contrast.',
      },
      {
        name: 'difference',
        description: 'Subtracts the darker color from the lighter color.',
      },
      {
        name: 'exclusion',
        description: 'Similar to difference, but with lower contrast.',
      },
      {
        name: 'hue',
        description:
          'Uses the hue of the top layer, with the saturation and luminosity of the bottom layer.',
      },
      {
        name: 'saturation',
        description:
          'Uses the saturation of the top layer, with the hue and luminosity of the bottom layer.',
      },
      {
        name: 'color',
        description:
          'Uses the hue and saturation of the top layer, with the luminosity of the bottom layer.',
      },
      {
        name: 'luminosity',
        description:
          'Uses the luminosity of the top layer, with the hue and saturation of the bottom layer.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'background-blend-mode',
          value: 'screen',
        },
      ],
    },
  },
  {
    name: 'background-clip',
    title: 'Background Clip',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `background-clip`,
    description:
      "The background-clip CSS property sets whether an element's background extends underneath its border box, padding box, or content box. If the element has no background-image or background-color, this property will only have a visual effect when the border has transparent regions or partially opaque regions (due to border-style or border-image); otherwise, the border masks the difference.",
    values: [
      {
        name: 'border-box',
        description:
          'The background extends to the outside edge of the border (but underneath the border in z-ordering).',
      },
      {
        name: 'multiply',
        description:
          'The background extends to the outside edge of the padding. No background is drawn beneath the border.',
      },
      {
        name: 'content-box',
        description:
          'The background is painted within (clipped to) the content box.',
      },
      {
        name: 'text',
        description:
          'The background is painted within (clipped to) the foreground text.',
      },
    ],
    tagHelper: {
      selector: 'section',
      properties: [
        {
          name: 'background-clip',
          value: 'multiply',
        },
      ],
    },
  },
  {
    name: 'background-color',
    title: 'Background Color',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `background-color`,
    description:
      'The background-color CSS property sets the background color of an element.',
    values: [
      {
        name: 'color-value',
        description:
          'The uniform color of the background. It is rendered behind any background-image that is specified, although the color will still be visible through any transparency in the image.',
      },
    ],
    tagHelper: {
      selector: 'section',
      properties: [
        {
          name: 'background-color',
          value: 'white',
        },
      ],
    },
  },
  {
    name: 'background-image',
    title: 'Background Image',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `background-image`,
    description:
      'The background-image CSS property sets one or more background images on an element.',
    values: [
      {
        name: 'none',
        description: 'Is a keyword denoting the absence of images.',
      },
      {
        name: 'image-value',
        description:
          'Is an <image> denoting the image to display. There can be several of them, separated by commas, as multiple backgrounds are supported.',
      },
    ],
    tagHelper: {
      selector: 'section',
      properties: [
        {
          name: 'background-image',
          value: '"./somePic.jpeg"',
        },
      ],
    },
  },
  {
    name: 'background-origin',
    title: 'Background Origin',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `background-origin`,
    description:
      "The background-origin CSS property sets the background's origin: from the border start, inside the border, or inside the padding.",
    values: [
      {
        name: 'border-box',
        description:
          'The background is positioned relative to the border box.',
      },
      {
        name: 'padding-box',
        description:
          'The background is positioned relative to the padding box.',
      },
      {
        name: 'content-box',
        description:
          'The background is positioned relative to the content box.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'background-origin',
          value: 'border-box',
        },
      ],
    },
  },
  {
    name: 'background-position',
    title: 'Background Position',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `background-position`,
    description:
      'The background-position CSS property sets the initial position for each background image. The position is relative to the position layer set by background-origin.',
    values: [
      {
        name: 'position-value(s)',
        description:
          "A <position>. A position defines an x/y coordinate, to place an item relative to the edges of an element's box. It can be defined using one to four values. If two non-keyword values are used, the first value represents the horizontal position and the second represents the vertical position. If only one value is specified, the second value is assumed to be center. If three or four values are used, the length-percentage values are offsets for the preceding keyword value(s).",
      },
      {
        name: 'one-value',
        description:
          'If provided one value, it may be the following: The keyword value center, which centers the image. One of the keyword values top, left, bottom, right. This specifies an edge against which to place the item. The other dimension is then set to 50%, so the item is placed in the middle of the edge specified. A <length> or <percentage>. This specifies the X coordinate relative to the left edge, with the Y coordinate set to 50%',
      },
      {
        name: 'two-values',
        description:
          'If provided two values, one value defines X and the other defines Y. Each value may be: One of the keyword values top, left, bottom, right. If left or right are given here, then this defines X and the other given value defines Y. If top or bottom are given, then this defines Y and the other value defines X. A <length> or <percentage>. If the other value is left or right, then this value defines Y, relative to the top edge. If the other value is top or bottom, then this value defines X, relative to the left edge. If both values are <length> or <percentage> values, then the first defines X and the second Y. Note that: If one value is top or bottom, then the other value may not be top or bottom. If one value is left or right, then the other value may not be left or right. This means, e.g., that top top and left right are not valid. Order: when pairing keywords, placement is not important as the browser can reorder it, the value top left or left top will yield the same result. With pairing <length> or <percentage> with a keyword the placement is important, the value defining X should come first followed by Y, the value right 20px is not the same as 20px right the former is valid but the latter is invalid. The value left 20% or 20% bottom is valid as X and Y values are clearly defined and the placement is right. The default value is left top or 0% 0%.',
      },
      {
        name: 'three-values',
        description:
          'If provided three values, two values are keyword values, and the third is the offset for the preceding value: The first value is one of the keyword values top, left, bottom, right, or center. If left or right are given here, then this defines X. If top or bottom are given, then this defines Y and the other keyword value defines X. The <length> or <percentage> value, if it is the second value, is the offset for the first value. If it is the third value, it is the offset for the second value. The single length or percentage value is an offset for the keyword value that precedes it. The combination of one keyword with two <length> or <percentage> values is not valid.',
      },
      {
        name: 'four-values',
        description:
          'If provided four values, the first and third values are keyword value defining X and Y. The second and fourth values are offsets for the preceding X and Y keyword values: The first value and third values one of the keyword values top, left, bottom, right. If left or right are given here, then this defines X. If top or bottom are given, then this defines Y and the other keyword value defines X. The second and fourth values are <length> or <percentage> values. The second value is the offset for the first keyword. The fourth value is the offset for the second keyword.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'background-position',
          value: 'center',
        },
      ],
    },
  },
  {
    name: 'background-position-x',
    title: 'Background Position X',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `background-position-x`,
    description:
      'The background-position-x CSS property sets the initial horizontal position for each background image. The position is relative to the position layer set by background-origin.',
    values: [
      {
        name: 'left',
        description:
          'Aligns the left edge of the background image with the left edge of the background position layer.',
      },
      {
        name: 'center',
        description:
          'Aligns the center of the background image with the center of the background position layer.',
      },
      {
        name: 'right',
        description:
          'Aligns the right edge of the background image with the right edge of the background position layer.',
      },
      {
        name: 'length-value',
        description:
          "The offset of the given background image's left vertical edge from the background position layer's left vertical edge. (Some browsers allow assigning the right edge for offset).",
      },
      {
        name: 'percentage-value',
        description:
          "The offset of the given background image's horizontal position relative to the container. A value of 0% means that the left edge of the background image is aligned with the left edge of the container, and a value of 100% means that the right edge of the background image is aligned with the right edge of the container, thus a value of 50% horizontally centers the background image.",
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'background-position-x',
          value: 'right',
        },
      ],
    },
  },
  {
    name: 'background-position-y',
    title: 'Background Position Y',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `background-position-y`,
    description:
      'The background-position-y CSS property sets the initial vertical position for each background image. The position is relative to the position layer set by background-origin.',
    values: [
      {
        name: 'top',
        description:
          'Aligns the top edge of the background image with the top edge of the background position layer.',
      },
      {
        name: 'center',
        description:
          'Aligns the vertical center of the background image with the vertical center of the background position layer.',
      },
      {
        name: 'bottom',
        description:
          'Aligns the bottom edge of the background image with the bottom edge of the background position layer.',
      },
      {
        name: 'length-value',
        description:
          "The offset of the given background image's horizontal edge from the corresponding background position layer's top horizontal edge. (Some browsers allow assigning the bottom edge for offset).",
      },
      {
        name: 'percentage-value',
        description:
          "The offset of the given background image's vertical position relative to the container. A value of 0% means that the top edge of the background image is aligned with the top edge of the container, and a value of 100% means that the bottom edge of the background image is aligned with the bottom edge of the container, thus a value of 50% vertically centers the background image.",
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'background-position-y',
          value: '10px',
        },
      ],
    },
  },
  {
    name: 'background-repeat',
    title: 'Background Repeat',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `background-repeat`,
    description:
      'The background-repeat CSS property sets how background images are repeated. A background image can be repeated along the horizontal and vertical axes, or not repeated at all.',
    values: [
      {
        name: 'repeat',
        description:
          "The image is repeated as much as needed to cover the whole background image painting area. The last image will be clipped if it doesn't fit.",
      },
      {
        name: 'space',
        description:
          "The image is repeated as much as possible without clipping. The first and last images are pinned to either side of the element, and whitespace is distributed evenly between the images. The background-position property is ignored unless only one image can be displayed without clipping. The only case where clipping happens using space is when there isn't enough room to display one image.",
      },
      {
        name: 'round',
        description:
          'As the allowed space increases in size, the repeated images will stretch (leaving no gaps) until there is room (space left >= half of the image width) for another one to be added. When the next image is added, all of the current ones compress to allow room. Example: An image with an original width of 260px, repeated three times, might stretch until each repetition is 300px wide, and then another image will be added. They will then compress to 225px.',
      },
      {
        name: 'no-repeat',
        description:
          'The image is not repeated (and hence the background image painting area will not necessarily be entirely covered). The position of the non-repeated background image is defined by the background-position CSS property.',
      },
      {
        name: 'repeat-x',
        description: 'This is equivalent to (repeat no-repeat).',
      },
      {
        name: 'repeat-y',
        description: 'This is equivalent to (no-repeat repeat).',
      },
      {
        name: 'repeat',
        description: 'This is equivalent to (repeat repeat).',
      },
      {
        name: 'space',
        description: 'This is equivalent to (space space).',
      },
      {
        name: 'round',
        description: 'This is equivalent to (round round).',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'background-repeat',
          value: 'no-repeat',
        },
      ],
    },
  },
  {
    name: 'background-size',
    title: 'Background Size',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `background-size`,
    description:
      "The background-size CSS property sets the size of the element's background image. The image can be left to its natural size, stretched, or constrained to fit the available space.",
    values: [
      {
        name: 'contain',
        description:
          'Scales the image as large as possible within its container without cropping or stretching the image. If the container is larger than the image, this will result in image tiling, unless the background-repeat property is set to no-repeat.',
      },
      {
        name: 'cover',
        description:
          'Scales the image (while preserving its ratio) to the smallest possible size to fill the container (that is: both its height and width completely cover the container), leaving no empty space. If the proportions of the background differ from the element, the image is cropped either vertically or horizontally.',
      },
      {
        name: 'auto',
        description:
          'Scales the background image in the corresponding direction such that its intrinsic proportions are maintained.',
      },
      {
        name: 'length-value',
        description:
          'Stretches the image in the corresponding dimension to the specified length. Negative values are not allowed.',
      },
      {
        name: 'percentage-value',
        description:
          "Stretches the image in the corresponding dimension to the specified percentage of the background positioning area. The background positioning area is determined by the value of background-origin (by default, the padding box). However, if the background's background-attachment value is fixed, the positioning area is instead the entire viewport. Negative values are not allowed.",
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'background-size',
          value: 'cover',
        },
      ],
    },
  },
  {
    name: 'border',
    title: 'Border',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border`,
    description:
      'This property is a shorthand for the following CSS properties: border-color, border-style, border-color. The border property may be specified using one, two, or three of the values listed below. The order of the values does not matter.',
    values: [
      {
        name: 'width-value',
        description:
          'Sets the thickness of the border. Defaults to medium if absent. See border-width.',
      },
      {
        name: 'style-value',
        description:
          'Sets the style of the border. Defaults to none if absent. See border-style.',
      },
      {
        name: 'color-value',
        description:
          'Sets the color of the border. Defaults to currentcolor if absent. See border-color.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border',
          value: 'solid 1px red',
        },
      ],
    },
  },
  {
    name: 'border-block',
    title: 'Border Block',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-block`,
    description:
      "The border-block CSS property is a shorthand property for setting the individual logical block border property values in a single place in the style sheet. border-block can be used to set the values for one or more of border-block-width, border-block-style, and border-block-color setting both the start and end in the block dimension at once. The physical borders to which it maps depends on the element's writing mode, directionality, and text orientation. It corresponds to the border-top and border-bottom or border-right, and border-left properties depending on the values defined for writing-mode, direction, and text-orientation.",
    values: [
      {
        name: 'width-value',
        description: 'The width of the border. See border-width.',
      },
      {
        name: 'style-value',
        description: 'The style of the border. See border-style.',
      },
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-block',
          value: 'dashed 2px blue',
        },
      ],
    },
  },
  {
    name: 'border-block-color',
    title: 'Border Block Color',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-block-color`,
    description:
      "The border-block-color CSS property defines the color of the logical block borders of an element, which maps to a physical border color depending on the element's writing mode, directionality, and text orientation. It corresponds to the border-top-color and border-bottom-color, or border-right-color and border-left-color property depending on the values defined for writing-mode, direction, and text-orientation.",
    values: [
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-block-color',
          value: 'red',
        },
      ],
    },
  },
  {
    name: 'border-block-end',
    title: 'Border Block End',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-block-end`,
    description:
      'The border-block-end CSS property is a shorthand property for setting the individual logical block-end border property values in a single place in the style sheet. This property is a shorthand for the following CSS properties: border-block-end-color, border-block-end-style, border-block-end-width.',
    values: [
      {
        name: 'width-value',
        description: 'The width of the border. See border-width.',
      },
      {
        name: 'style-value',
        description: 'The style of the border. See border-style.',
      },
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-block-end',
          value: 'solid 1px black',
        },
      ],
    },
  },
  {
    name: 'border-block-end-color',
    title: 'Border Block End Color',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-block-end-color`,
    description:
      "The border-block-end-color CSS property defines the color of the logical block-end border of an element, which maps to a physical border color depending on the element's writing mode, directionality, and text orientation. It corresponds to the border-top-color, border-right-color, border-bottom-color, or border-left-color property depending on the values defined for writing-mode, direction, and text-orientation.",
    values: [
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-block-end-color',
          value: 'blue',
        },
      ],
    },
  },
  {
    name: 'border-block-end-style',
    title: 'Border Block End Style',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-block-end-style`,
    description:
      "The border-block-end-style CSS property defines the style of the logical block-end border of an element, which maps to a physical border style depending on the element's writing mode, directionality, and text orientation. It corresponds to the border-top-style, border-right-style, border-bottom-style, or border-left-style property depending on the values defined for writing-mode, direction, and text-orientation.",
    values: [
      {
        name: 'style-value',
        description: 'The style of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-block-end-style',
          value: 'solid',
        },
      ],
    },
  },
  {
    name: 'border-block-end-width',
    title: 'Border Block End Width',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-block-end-width`,
    description:
      "The border-block-end-width CSS property defines the width of the logical block-end border of an element, which maps to a physical border width depending on the element's writing mode, directionality, and text orientation. It corresponds to the border-top-width, border-right-width, border-bottom-width, or border-left-width property depending on the values defined for writing-mode, direction, and text-orientation.",
    values: [
      {
        name: 'width-value',
        description: 'The width of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-block-end-width',
          value: '2px',
        },
      ],
    },
  },
  {
    name: 'border-block-start',
    title: 'Border Block Start',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-block-start`,
    description:
      'The border-block-start CSS property is a shorthand property for setting the individual logical block-start border property values in a single place in the style sheet. This property is a shorthand for the following CSS properties: border-block-start-color, border-block-style, border-block-start-width.',
    values: [
      {
        name: 'width-value',
        description: 'The width of the border.',
      },
      {
        name: 'style-value',
        description: 'The style of the border.',
      },
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-block-start',
          value: 'solid red 1px',
        },
      ],
    },
  },
  {
    name: 'border-block-start-color',
    title: 'Border Block Start Color',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-block-start-color`,
    description:
      "The border-block-start-color CSS property defines the color of the logical block-start border of an element, which maps to a physical border color depending on the element's writing mode, directionality, and text orientation. It corresponds to the border-top-color, border-right-color, border-bottom-color, or border-left-color property depending on the values defined for writing-mode, direction, and text-orientation.",
    values: [
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-block-start-color',
          value: 'green',
        },
      ],
    },
  },
  {
    name: 'border-block-start-style',
    title: 'Border Block Start Style',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-block-start-style`,
    description:
      "The border-block-start-style CSS property defines the style of the logical block start border of an element, which maps to a physical border style depending on the element's writing mode, directionality, and text orientation. It corresponds to the border-top-style, border-right-style, border-bottom-style, or border-left-style property depending on the values defined for writing-mode, direction, and text-orientation.",
    values: [
      {
        name: 'style-value',
        description: 'The style of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-block-start-style',
          value: 'dashed',
        },
      ],
    },
  },
  {
    name: 'border-block-start-width',
    title: 'Border Block Start Width',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-block-start-width`,
    description:
      "The border-block-start-width CSS property defines the width of the logical block-start border of an element, which maps to a physical border width depending on the element's writing mode, directionality, and text orientation. It corresponds to the border-top-width, border-right-width, border-bottom-width, or border-left-width property depending on the values defined for writing-mode, direction, and text-orientation.",
    values: [
      {
        name: 'width-value',
        description: 'The width of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-block-start-width',
          value: '1px',
        },
      ],
    },
  },
  {
    name: 'border-block-style',
    title: 'Border Block Style',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-block-style`,
    description:
      "The border-block-style CSS property defines the style of the logical block borders of an element, which maps to a physical border style depending on the element's writing mode, directionality, and text orientation. It corresponds to the border-top-style and border-bottom-style, or border-left-style and border-right-style properties depending on the values defined for writing-mode, direction, and text-orientation.",
    values: [
      {
        name: 'style-value',
        description: 'The style of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-block-style',
          value: 'solid',
        },
      ],
    },
  },
  {
    name: 'border-block-width',
    title: 'Border Block Width',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-block-width`,
    description:
      "The border-block-style CSS property defines the style of the logical block borders of an element, which maps to a physical border style depending on the element's writing mode, directionality, and text orientation. It corresponds to the border-top-style and border-bottom-style, or border-left-style and border-right-style properties depending on the values defined for writing-mode, direction, and text-orientation.",
    values: [
      {
        name: 'width-value',
        description: 'The width of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-block-width',
          value: '2px',
        },
      ],
    },
  },
  {
    name: 'border-bottom',
    title: 'Border Bottom',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-bottom`,
    description:
      "The border-bottom shorthand CSS property sets an element's bottom border. It sets the values of border-bottom-width, border-bottom-style and border-bottom-color. As with all shorthand properties, border-bottom always sets the values of all of the properties that it can set, even if they are not specified. It sets those that are not specified to their default values.",
    values: [
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
      {
        name: 'style-value',
        description: 'The style of the border.',
      },
      {
        name: 'width-value',
        description: 'The width of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-bottom',
          value: 'solid 1px red',
        },
      ],
    },
  },
  {
    name: 'border-bottom-color',
    title: 'Border Bottom Color',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-bottom-color`,
    description:
      "The border-bottom-color CSS property sets the color of an element's bottom border. It can also be set with the shorthand CSS properties border-color or border-bottom.",
    values: [
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-bottom-color',
          value: 'blue',
        },
      ],
    },
  },
  {
    name: 'border-bottom-style',
    title: 'Border Bottom Style',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-bottom-style`,
    description:
      "The border-bottom-color CSS property sets the color of an element's bottom border. It can also be set with the shorthand CSS properties border-color or border-bottom.",
    values: [
      {
        name: 'style-value',
        description: 'The style of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-bottom-style',
          value: 'solid',
        },
      ],
    },
  },
  {
    name: 'border-bottom-width',
    title: 'Border Bottom Width',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-bottom-width`,
    description:
      'The border-bottom-width CSS property sets the width of the bottom border of an element.',
    values: [
      {
        name: 'width-value',
        description: 'The width of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-bottom-width',
          value: '1px',
        },
      ],
    },
  },
  {
    name: 'border-bottom-left-radius',
    title: 'Border Bottom Left Radius',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-bottom-left-radius`,
    description:
      'The border-bottom-left-radius CSS property rounds the bottom-left corner of an element by specifying the radius (or the radius of the semi-major and semi-minor axes) of the ellipse defining the curvature of the corner.',
    values: [
      {
        name: 'one-width-value',
        description:
          'The value is a <length> or a <percentage> denoting the radius of the circle to use for the border in that corner.',
      },
      {
        name: 'two-width-values',
        description:
          'The first value is a <length> or a <percentage> denoting the horizontal semi-major axis of the ellipse to use for the border in that corner. The second value is a <length> or a <percentage> denoting the vertical semi-major axis of the ellipse to use for the border in that corner.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-bottom-left-radius',
          value: '5px',
        },
      ],
    },
  },
  {
    name: 'border-bottom-right-radius',
    title: 'Border Bottom Right Radius',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-bottom-right-radius`,
    description:
      'The border-bottom-right-radius CSS property rounds the bottom-right corner of an element by specifying the radius (or the radius of the semi-major and semi-minor axes) of the ellipse defining the curvature of the corner.',
    values: [
      {
        name: 'one-width-value',
        description:
          'The value is a <length> or a <percentage> denoting the radius of the circle to use for the border in that corner.',
      },
      {
        name: 'two-width-values',
        description:
          'The first value is a <length> or a <percentage> denoting the horizontal semi-major axis of the ellipse to use for the border in that corner. The second value is a <length> or a <percentage> denoting the vertical semi-major axis of the ellipse to use for the border in that corner.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-bottom-right-radius',
          value: '5px',
        },
      ],
    },
  },
  {
    name: 'border-collapse',
    title: 'Border Collapse',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-collapse`,
    description:
      'The border-collapse CSS property sets whether cells inside a <table> have shared or separate borders.',
    values: [
      {
        name: 'collapse',
        description:
          'Adjacent cells have shared borders (the collapsed-border table rendering model).',
      },
      {
        name: 'separate',
        description:
          'Adjacent cells have distinct borders (the separated-border table rendering model).',
      },
    ],
    tagHelper: {
      selector: 'table',
      properties: [
        {
          name: 'border-collapse',
          value: 'collapse',
        },
      ],
    },
    tags: ['table'],
  },
  {
    name: 'border-color',
    title: 'Border Color',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-color`,
    description:
      "The border-color shorthand CSS property sets the color of an element's border. This property is a shorthand for the following CSS properties: border-bottom-color, border-left-color, border-right-color, border-top-color.",
    values: [
      {
        name: 'collapse',
        description:
          'Adjacent cells have shared borders (the collapsed-border table rendering model).',
      },
      {
        name: 'separate',
        description:
          'Adjacent cells have distinct borders (the separated-border table rendering model).',
      },
    ],
    tagHelper: {
      selector: 'table',
      properties: [
        {
          name: 'border-collapse',
          value: 'collapse',
        },
      ],
    },
    tags: ['table'],
  },
  {
    name: 'border-end-end-radius',
    title: 'Border End End Radius',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-end-end-radius`,
    description:
      "The border-end-end-radius CSS property defines a logical border radius on an element, which maps to a physical border radius that depends on the element's writing-mode, direction, and text-orientation. This is useful when building styles to work regardless of the text orientation and writing mode.",
    values: [
      {
        name: 'percentage-value',
        description:
          'Denotes the size of the circle radius or the semi-major and semi-minor axes of the ellipse. As absolute length it can be expressed in any unit allowed by the CSS <length> data type. Percentages for the horizontal axis refer to the width of the box, percentages for the vertical axis refer to the height of the box. Negative values are invalid.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-end-right-radius',
          value: '5px',
        },
      ],
    },
  },
  {
    name: 'border-end-start-radius',
    title: 'Border End Start Radius',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-end-start-radius`,
    description:
      "The border-end-start-radius CSS property defines a logical border radius on an element, which maps to a physical border radius that depends on the element's writing-mode, direction, and text-orientation. This is useful when building styles to work regardless of the text orientation and writing mode.",
    values: [
      {
        name: 'percentage-value',
        description:
          'Denotes the size of the circle radius or the semi-major and semi-minor axes of the ellipse. As absolute length it can be expressed in any unit allowed by the CSS <length> data type. Percentages for the horizontal axis refer to the width of the box, percentages for the vertical axis refer to the height of the box. Negative values are invalid.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-end-right-radius',
          value: '5px',
        },
      ],
    },
  },
  {
    name: 'border-image',
    title: 'Border Image',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-image`,
    description:
      "The border-image CSS property draws an image around a given element. It replaces the element's regular border. This property is a shorthand for the following CSS properties: border-image-outset, border-image-repeat, border-image-slice, border-image-source, border-image-width",
    values: [
      {
        name: 'image-value',
        description: 'The source image.',
      },
      {
        name: 'image-slice-value',
        description:
          'The dimensions for slicing the source image into regions. Up to four values may be specified. See border-image-slice.',
      },
      {
        name: 'image-outset-value',
        description:
          "The distance of the border image from the element's outside edge. Up to four values may be specified. See border-image-outset.",
      },
      {
        name: 'image-repeat-value',
        description:
          'Defines how the edge regions of the source image are adjusted to fit the dimensions of the border image. Up to two values may be specified. See border-image-repeat.',
      },
      {
        name: 'image-width-value',
        description:
          'The width of the border image. Up to four values may be specified. See <border-image-width className=""></border-image-width>',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-image',
          value: 'linear-gradient(white, blue) 3',
        },
      ],
    },
  },
  {
    name: 'border-image-outset',
    title: 'Border Image Outset',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-image-outset`,
    description:
      "The border-image-outset CSS property sets the distance by which an element's border image is set out from its border box. The parts of the border image that are rendered outside the element's border box with border-image-outset do not trigger overflow scrollbars and don't capture mouse events. If one value is specified, it applies to all four sides. If two values are specified, the first applies to the top and bottom and the second to the left and right. If three values are specified, the first applies to the top, the second to the left and right, and the third to the bottom. If four values are specified, they apply to the top, right, bottom, and left in that order (clockwise).",
    values: [
      {
        name: 'length-value',
        description:
          'The size of the border-image outset as a dimension — a number with a unit.',
      },
      {
        name: 'number-value',
        description:
          "The size of the border-image outset as a multiple of the element's corresponding border-widths. For example, if an element has border-width: 1em 2px 0 1.5rem, and border-image-outset: 2, the final border-image-outset would be calculated as 2em 4px 0 3rem.",
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-image-outset',
          value: '20px',
        },
      ],
    },
  },
  {
    name: 'border-image-repeat',
    title: 'Border Image Repeat',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-image-repeat`,
    description: `The border-image-repeat CSS property defines how the edge regions and middle region of a source image are adjusted to fit the dimensions of an element's border image. The middle region can be displayed by using the keyword "fill" in the border-image-slice property. When one value is specified, it applies the same behavior on all four sides. When two values are specified, the first applies to the top, middle, and bottom, the second to the left and right.`,
    values: [
      {
        name: 'stretch',
        description:
          "The source image's edge regions are stretched to fill the gap between each border.",
      },
      {
        name: 'repeat',
        description:
          "The source image's edge regions are tiled (repeated) to fill the gap between each border. Tiles may be clipped to achieve the proper fit.",
      },
      {
        name: 'round',
        description:
          "The source image's edge regions are tiled (repeated) to fill the gap between each border. Tiles may be stretched to achieve the proper fit.",
      },
      {
        name: 'space',
        description:
          "The source image's edge regions are tiled (repeated) to fill the gap between each border. Extra space will be distributed in between tiles to achieve the proper fit.",
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-image-outset',
          value: '20px',
        },
      ],
    },
  },
  {
    name: 'border-image-slice',
    title: 'Border Image Slice',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-image-slice`,
    description: `The border-image-slice CSS property divides the image specified by border-image-source into regions. These regions form the components of an element's border image. The slicing process creates nine regions in total: four corners, four edges, and a middle region. Four slice lines, set a given distance from their respective sides, control the size of the regions. When one position is specified, it creates all four slices at the same distance from their respective sides. When two positions are specified, the first value creates slices measured from the top and bottom, the second creates slices measured from the left and right. When three positions are specified, the first value creates a slice measured from the top, the second creates slices measured from the left and right, the third creates a slice measured from the bottom. When four positions are specified, they create slices measured from the top, right, bottom, and left in that order (clockwise).`,
    values: [
      {
        name: 'number-value',
        description:
          "Represents an edge offset in pixels for raster images and coordinates for vector images. For vector images, the number is relative to the element's size, not the size of the source image, so percentages are generally preferable in these cases.",
      },
      {
        name: 'percentage-value',
        description:
          "Represents an edge offset as a percentage of the source image's size: the width of the image for horizontal offsets, the height for vertical offsets.",
      },
      {
        name: 'fill',
        description:
          'Preserves the middle image region and displays it like a background image, but stacked above the actual background. Its width and height are sized to match the top and left image regions, respectively.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-image-slice',
          value: '10%',
        },
      ],
    },
  },
  {
    name: 'border-image-source',
    title: 'Border Image Source',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-image-source`,
    description: `The border-image-source CSS property sets the source image used to create an element's border image.`,
    values: [
      {
        name: 'none',
        description:
          'No border image is used. The appearance defined by border-style is displayed instead.',
      },
      {
        name: 'image-value',
        description: 'Image reference to use for the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-image-source',
          value: 'url(./somePic.jpeg)',
        },
      ],
    },
  },
  {
    name: 'border-image-width',
    title: 'Border Image Width',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-image-width`,
    description: `The border-image-width CSS property sets the width of an element's border image. When one value is specified, it applies the same width to all four sides. When two values are specified, the first width applies to the top and bottom, the second to the left and right. When three values are specified, the first width applies to the top, the second to the left and right, the third to the bottom. When four values are specified, the widths apply to the top, right, bottom, and left in that order (clockwise).`,
    values: [
      {
        name: 'length-value',
        description:
          'The width of the border, specified as a <length> or a <percentage>. Percentages are relative to the width of the border image area for horizontal offsets and the height of the border image area for vertical offsets. Must not be negative.',
      },
      {
        name: 'number-value',
        description:
          'The width of the border, specified as a multiple of the corresponding border-width. Must not be negative.',
      },
      {
        name: 'auto',
        description:
          'The width of the border is made equal to the intrinsic width or height (whichever is applicable) of the corresponding border-image-slice. If the image does not have the required intrinsic dimension, the corresponding border-width is used instead.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-image-width',
          value: '10px',
        },
      ],
    },
  },
  {
    name: 'border-inline',
    title: 'Border Inline',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-inline`,
    description: `The border-inline CSS property is a shorthand property for setting the individual logical inline border property values in a single place in the style sheet. The physical borders to which border-inline maps depends on the element's writing mode, directionality, and text orientation. It corresponds to the border-top and border-bottom or border-right, and border-left properties, depending on the values defined for writing-mode, direction, and text-orientation.`,
    values: [
      {
        name: 'width-value',
        description: 'The width of the border. See border-width.',
      },
      {
        name: 'style-value',
        description: 'The line style of the border. See border-style.',
      },
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'border-inline',
          value: '1px solid red',
        },
      ],
    },
  },
  {
    name: 'border-inline-end ',
    title: 'Border Inline End',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-inline-end`,
    description: `The border-inline-end CSS property is a shorthand property for setting the individual logical inline-end border property values in a single place in the style sheet. This property is a shorthand for the following CSS properties: border-inline-end-color, border-inline-end-style, and border-inline-end-width.`,
    values: [
      {
        name: 'width-value',
        description: 'The width of the border. See border-width.',
      },
      {
        name: 'style-value',
        description: 'The line style of the border. See border-style.',
      },
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'border-inline-end',
          value: '1px dashed blue',
        },
      ],
    },
  },
  {
    name: 'border-inline-end ',
    title: 'Border Inline End',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-inline-end`,
    description: `The border-inline-end CSS property is a shorthand property for setting the individual logical inline-end border property values in a single place in the style sheet. This property is a shorthand for the following CSS properties: border-inline-end-color, border-inline-end-style, and border-inline-end-width.`,
    values: [
      {
        name: 'width-value',
        description: 'The width of the border. See border-width.',
      },
      {
        name: 'style-value',
        description: 'The line style of the border. See border-style.',
      },
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'border-inline-end',
          value: '1px dashed blue',
        },
      ],
    },
  },
  {
    name: 'border-inline-end-color',
    title: 'Border Inline End Color',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-inline-end-color`,
    description: `The border-inline-end-color CSS property defines the color of the logical inline-end border of an element, which maps to a physical border color depending on the element's writing mode, directionality, and text orientation. It corresponds to the border-top-color, border-right-color, border-bottom-color, or border-left-color property depending on the values defined for writing-mode, direction, and text-orientation.`,
    values: [
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'border-inline-end-color',
          value: 'blue',
        },
      ],
    },
  },
  {
    name: 'border-inline-end-style',
    title: 'Border Inline End Style',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-inline-end-style`,
    description: `The border-inline-end-style CSS property defines the style of the logical inline end border of an element, which maps to a physical border style depending on the element's writing mode, directionality, and text orientation. It corresponds to the border-top-style, border-right-style, border-bottom-style, or border-left-style property depending on the values defined for writing-mode, direction, and text-orientation.`,
    values: [
      {
        name: 'style-value',
        description: 'The style of the border.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'border-inline-end-style',
          value: 'solid',
        },
      ],
    },
  },
  {
    name: 'border-inline-end-width',
    title: 'Border Inline End width',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-inline-end-width`,
    description: `The border-inline-end-width CSS property defines the width of the logical inline-end border of an element, which maps to a physical border width depending on the element's writing mode, directionality, and text orientation. It corresponds to the border-top-width, border-right-width, border-bottom-width, or border-left-width property depending on the values defined for writing-mode, direction, and text-orientation.`,
    values: [
      {
        name: 'width-value',
        description: 'The width of the border.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'border-inline-end-width',
          value: '1px',
        },
      ],
    },
  },
  {
    name: 'border-inline-start',
    title: 'Border Inline Start',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-inline-start`,
    description: `The border-inline-start CSS property is a shorthand property for setting the individual logical inline-start border property values in a single place in the style sheet. This property is a shorthand for the following CSS properties: border-inline-start-color, border-inline-start-style, and border-inline-start-width`,
    values: [
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
      {
        name: 'style-value',
        description: 'The style of the border.',
      },
      {
        name: 'width-value',
        description: 'The width of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-inline-start',
          value: 'solid red 1px',
        },
      ],
    },
  },
  {
    name: 'border-inline-start-color',
    title: 'Border Inline Start Color',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-inline-start-color`,
    description: `The border-inline-start-color CSS property defines the color of the logical inline start border of an element, which maps to a physical border color depending on the element's writing mode, directionality, and text orientation. It corresponds to the border-top-color, border-right-color, border-bottom-color, or border-left-color property depending on the values defined for writing-mode, direction, and text-orientation.`,
    values: [
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-inline-start-color',
          value: 'green',
        },
      ],
    },
  },
  {
    name: 'border-inline-start-style',
    title: 'Border Inline Start Style',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-inline-start-style`,
    description: `The border-inline-start-style CSS property defines the style of the logical inline start border of an element, which maps to a physical border style depending on the element's writing mode, directionality, and text orientation. It corresponds to the border-top-style, border-right-style, border-bottom-style, or border-left-style property depending on the values defined for writing-mode, direction, and text-orientation.`,
    values: [
      {
        name: 'style-value',
        description: 'The style of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-inline-start-style',
          value: 'dashed',
        },
      ],
    },
  },
  {
    name: 'border-inline-start-width',
    title: 'Border Inline Start Width',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-inline-start-width`,
    description: `The border-inline-start-width CSS property defines the width of the logical inline-start border of an element, which maps to a physical border width depending on the element's writing mode, directionality, and text orientation. It corresponds to the border-top-width, border-right-width, border-bottom-width, or border-left-width property depending on the values defined for writing-mode, direction, and text-orientation.`,
    values: [
      {
        name: 'width-value',
        description: 'The width of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-inline-start-width',
          value: '1px',
        },
      ],
    },
  },
  {
    name: 'border-inline-style',
    title: 'Border Inline Style',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-inline-style`,
    description: `The border-inline-style CSS property defines the style of the logical inline borders of an element, which maps to a physical border style depending on the element's writing mode, directionality, and text orientation. It corresponds to the border-top-style and border-bottom-style, or border-left-style and border-right-style properties depending on the values defined for writing-mode, direction, and text-orientation.`,
    values: [
      {
        name: 'style-value',
        description: 'The style of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-inline-style',
          value: 'solid',
        },
      ],
    },
  },
  {
    name: 'border-inline-width',
    title: 'Border Inline Width',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-inline-width`,
    description: `The border-inline-width CSS property defines the width of the logical inline borders of an element, which maps to a physical border width depending on the element's writing mode, directionality, and text orientation. It corresponds to the border-top-width and border-bottom-width, or border-left-width, and border-right-width property depending on the values defined for writing-mode, direction, and text-orientation.`,
    values: [
      {
        name: 'width-value',
        description: 'The width of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-inline-width',
          value: '5px',
        },
      ],
    },
  },
  {
    name: 'border-left',
    title: 'Border Left',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-left`,
    description: `The border-left shorthand CSS property sets all the properties of an element's left border. This property is a shorthand for the following CSS properties: border-left-color, border-left-style, and border-left-width.`,
    values: [
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
      {
        name: 'style-value',
        description: 'The style of the border.',
      },
      {
        name: 'width-value',
        description: 'The width of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-left',
          value: 'double red 5px',
        },
      ],
    },
  },
  {
    name: 'border-left-color',
    title: 'Border Left Color',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-left-color`,
    description: `The border-left-color CSS property sets the color of an element's left border. It can also be set with the shorthand CSS properties border-color or border-left.`,
    values: [
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-left',
          value: 'blue',
        },
      ],
    },
  },
  {
    name: 'border-left-style',
    title: 'Border Left Style',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-left-style`,
    description: `The border-left-style CSS property sets the line style of an element's left border.`,
    values: [
      {
        name: 'style-value',
        description: 'The style of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-left-style',
          value: 'solid',
        },
      ],
    },
  },
  {
    name: 'border-left-width',
    title: 'Border Left Width',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-left-width`,
    description: `The border-left-width CSS property sets the width of the left border of an element.`,
    values: [
      {
        name: 'style-value',
        description: 'The style of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-left-style',
          value: 'solid',
        },
      ],
    },
  },
  {
    name: 'border-radius',
    title: 'Border Radius',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-radius`,
    description: `The border-radius CSS property rounds the corners of an element's outer border edge. You can set a single radius to make circular corners, or two radii to make elliptical corners. This property is a shorthand for the following CSS properties: border-top-left-radius, border-top-right-radius, border-bottom-left-radius, and border-bottom-right-radius.`,
    values: [
      {
        name: 'length-value',
        description:
          'Denotes the size of the circle radius, or the semi-major and semi-minor axes of the ellipse, using length values. Negative values are invalid.',
      },
      {
        name: 'percentage-value',
        description:
          'Denotes the size of the circle radius, or the semi-major and semi-minor axes of the ellipse, using percentage values. Percentages for the horizontal axis refer to the width of the box; percentages for the vertical axis refer to the height of the box. Negative values are invalid.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-radius',
          value: '5px',
        },
      ],
    },
  },
  {
    name: 'border-right',
    title: 'Border Right',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-right`,
    description: `The border-right shorthand CSS property sets all the properties of an element's right border. This property is a shorthand for the following CSS properties: border-right-color, border-right-style, and border-right-width.`,
    values: [
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
      {
        name: 'style-value',
        description: 'The style of the border.',
      },
      {
        name: 'width-value',
        description: 'The width of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-right',
          value: 'solid 1px green',
        },
      ],
    },
  },
  {
    name: 'border-right-color',
    title: 'Border Right Color',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-right-color`,
    description: `The border-right-color CSS property sets the color of an element's right border. It can also be set with the shorthand CSS properties border-color or border-right.`,
    values: [
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'border-right-color',
          value: 'orange',
        },
      ],
    },
  },
  {
    name: 'border-right-style',
    title: 'Border Right Style',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-right-style`,
    description: `The border-right-style CSS property sets the line style of an element's right border.`,
    values: [
      {
        name: 'style-value',
        description: 'The style of the border.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'border-right-style',
          value: 'dashed',
        },
      ],
    },
  },
  {
    name: 'border-right-width',
    title: 'Border Right Width',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-right-width`,
    description: `The border-right-width CSS property sets the line width of an element's right border.`,
    values: [
      {
        name: 'width-value',
        description: 'The width of the border.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'border-right-width',
          value: '3px',
        },
      ],
    },
  },
  {
    name: 'border-spacing',
    title: 'Border Spacing',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-spacing`,
    description: `The border-spacing CSS property sets the distance between the borders of adjacent cells in a <table>. This property applies only when border-collapse is separate.`,
    values: [
      {
        name: 'length-value',
        description: 'The size of the spacing as a fixed value.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'border-spacing',
          value: '3px',
        },
      ],
    },
  },
  {
    name: 'border-start-end-radius',
    title: 'Border Start End Radius',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-start-end-radius`,
    description: `The border-start-end-radius CSS property defines a logical border radius on an element, which maps to a physical border radius depending on the element's writing-mode, direction, and text-orientation. This is useful when building styles to work regardless of the text orientation and writing mode.`,
    values: [
      {
        name: 'length-value',
        description:
          'Denotes the size of the circle radius or the semi-major and semi-minor axes of the ellipse. As absolute length it can be expressed in any unit allowed by the CSS <length> data type. Percentages for the horizontal axis refer to the width of the box, percentages for the vertical axis refer to the height of the box. Negative values are invalid.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'border-start-end-radius',
          value: '3px',
        },
      ],
    },
  },
  {
    name: 'border-start-start-radius',
    title: 'Border Start Start Radius',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-start-start-radius`,
    description: `The border-start-start-radius CSS property defines a logical border radius on an element, which maps to a physical border radius that depends on the element's writing-mode, direction, and text-orientation. This is useful when building styles to work regardless of the text orientation and writing mode.`,
    values: [
      {
        name: 'length-value',
        description:
          'Denotes the size of the circle radius or the semi-major and semi-minor axes of the ellipse. As absolute length it can be expressed in any unit allowed by the CSS <length> data type. Percentages for the horizontal axis refer to the width of the box, percentages for the vertical axis refer to the height of the box. Negative values are invalid.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'border-start-start-radius',
          value: '1px',
        },
      ],
    },
  },
  {
    name: 'border-style',
    title: 'Border Style',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-style`,
    description: `The border-style shorthand CSS property sets the line style for all four sides of an element's border. This property is a shorthand for the following CSS properties: border-top-style, border-right-style, border-bottom-style, and border-left-style. The border-style property may be specified using one, two, three, or four values. When one value is specified, it applies the same style to all four sides. When two values are specified, the first style applies to the top and bottom, the second to the left and right. When three values are specified, the first style applies to the top, the second to the left and right, the third to the bottom. When four values are specified, the styles apply to the top, right, bottom, and left in that order (clockwise).`,
    values: [
      {
        name: 'length-value',
        description:
          'Denotes the size of the circle radius or the semi-major and semi-minor axes of the ellipse. As absolute length it can be expressed in any unit allowed by the CSS <length> data type. Percentages for the horizontal axis refer to the width of the box, percentages for the vertical axis refer to the height of the box. Negative values are invalid.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'border-style',
          value: 'solid',
        },
      ],
    },
  },
  {
    name: 'border-top',
    title: 'Border Top',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-top`,
    description: `The border-top shorthand CSS property sets all the properties of an element's top border. This property is a shorthand for the following CSS properties: border-top-color, border-top-style, and border-top-width.`,
    values: [
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
      {
        name: 'style-value',
        description: 'The style of the border.',
      },
      {
        name: 'width-value',
        description: 'The width of the border.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'border-top',
          value: 'solid 1px red',
        },
      ],
    },
  },
  {
    name: 'border-top-color',
    title: 'Border Top Color',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-top-color`,
    description: `The border-top-color CSS property sets the color of an element's top border. It can also be set with the shorthand CSS properties border-color or border-top.`,
    values: [
      {
        name: 'color-value',
        description: 'The color of the border.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'border-top-color',
          value: 'blue',
        },
      ],
    },
  },
  {
    name: 'border-top-style',
    title: 'Border Top Style',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-top-style`,
    description: `The border-top-style CSS property sets the line style of an element's top border.`,
    values: [
      {
        name: 'style-value',
        description: 'The style of the border.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'border-top-style',
          value: 'double',
        },
      ],
    },
  },
  {
    name: 'border-top-width',
    title: 'Border Top Width',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-top-width`,
    description: `The border-top-width CSS property sets the line width of an element's top border.`,
    values: [
      {
        name: 'width-value',
        description: 'The width of the border.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'border-top-width',
          value: '2px',
        },
      ],
    },
  },
  {
    name: 'border-top-left-radius',
    title: 'Border Top Left Radius',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-top-left-radius`,
    description: `The border-top-left-radius CSS property rounds the top-left corner of an element by specifying the radius (or the radius of the semi-major and semi-minor axes) of the ellipse defining the curvature of the corner. With one value: the value is a <length> or a <percentage> denoting the radius of the circle to use for the border in that corner. With two values: the first value is a <length> or a <percentage> denoting the horizontal semi-major axis of the ellipse to use for the border in that corner, the second value is a <length> or a <percentage> denoting the vertical semi-major axis of the ellipse to use for the border in that corner.`,
    values: [
      {
        name: 'length-value',
        description:
          'Denotes the size of the circle radius or the semi-major and semi-minor axes of the ellipse. As absolute length it can be expressed in any unit allowed by the CSS <length> data type. Percentages for the horizontal axis refer to the width of the box, percentages for the vertical axis refer to the height of the box. Negative values are invalid.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'border-top-left-radius',
          value: '3px',
        },
      ],
    },
  },
  {
    name: 'border-top-right-radius',
    title: 'Border Top Right Radius',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-top-right-radius`,
    description: `The border-top-right-radius CSS property rounds the top-right corner of an element by specifying the radius (or the radius of the semi-major and semi-minor axes) of the ellipse defining the curvature of the corner.`,
    values: [
      {
        name: 'length-value',
        description:
          'Denotes the size of the circle radius or the semi-major and semi-minor axes of the ellipse. As absolute length it can be expressed in any unit allowed by the CSS <length> data type. Percentages for the horizontal axis refer to the width of the box, percentages for the vertical axis refer to the height of the box. Negative values are invalid.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'border-top-right-radius',
          value: '2px',
        },
      ],
    },
  },
  {
    name: 'border-width',
    title: 'Border Width',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `border-width`,
    description: `The border-width shorthand CSS property sets the width of an element's border. This property is a shorthand for the following CSS properties: border-top-width, border-right-width, border-bottom-width, and border-left-width. When one value is specified, it applies the same width to all four sides. When two values are specified, the first width applies to the top and bottom, the second to the left and right. When three values are specified, the first width applies to the top, the second to the left and right, the third to the bottom. When four values are specified, the widths apply to the top, right, bottom, and left in that order (clockwise).`,
    values: [
      {
        name: 'length-value',
        description:
          "Defines the width of the border, either as an explicit nonnegative <length> or a keyword. If it's a keyword, it must be one of the following values: thin, medium, or thick.",
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'border-top-right-radius',
          value: '2px',
        },
      ],
    },
  },
  {
    name: 'block-size',
    title: 'Block Size',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `block-size`,
    description:
      "The block-size CSS property defines the horizontal or vertical size of an element's block, depending on its writing mode. It corresponds to either the width or the height property, depending on the value of writing-mode. If the writing mode is vertically oriented, the value of block-size relates to the width of the element; otherwise, it relates to the height of the element. A related property is inline-size, which defines the other dimension of the element.",
    values: [
      {
        name: 'width-value',
        description: 'Specifies the width of the block element.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'backface-visibility',
          value: 'hidden',
        },
      ],
    },
  },
  {
    name: 'bottom',
    title: 'Bottom',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `bottom`,
    description:
      "The bottom CSS property participates in setting the vertical position of a positioned element. It has no effect on non-positioned elements. The effect of bottom depends on how the element is positioned (i.e., the value of the position property): When position is set to absolute or fixed, the bottom property specifies the distance between the element's outer margin of bottom edge and the inner border of the bottom edge of its containing block. When position is set to relative, the bottom property specifies the distance the element's bottom edge is moved above its normal position. When position is set to sticky, the bottom property is used to compute the sticky-constraint rectangle. When position is set to static, the bottom property has no effect. When both top and bottom are specified, position is set to absolute or fixed, and height is unspecified (either auto or 100%) both the top and bottom distances are respected. In all other situations, if height is constrained in any way or position is set to relative, the top property takes precedence and the bottom property is ignored.",
    values: [
      {
        name: 'length-value',
        description:
          'Specifies the length of the element bottom. For absolutely positioned elements, the distance to the bottom edge of the containing block. For relatively positioned elements, the distance that the element is moved above its normal position.',
      },
      {
        name: 'percentage-value',
        description: 'Specifies the length of the element bottom.',
      },
      {
        name: 'auto',
        description:
          'For absolutely positioned elements, the position of the element is based on the top property, while height: auto is treated as a height based on the content; or if top is also auto, the element is positioned where it should vertically be positioned if it were a static element. for relatively positioned elements, the distance of the element from its normal position is based on the top property; or if top is also auto, the element is not moved vertically at all.',
      },
      {
        name: 'inherit',
        description:
          'Specifies that the value is the same as the computed value from its parent element (which might not be its containing block). This computed value is then handled as if it were a <length>, <percentage>, or the auto keyword.',
      },
    ],
    tagHelper: {
      selector: 'a',
      properties: [
        {
          name: 'bottom',
          value: '0',
        },
      ],
    },
  },
  {
    name: 'box-decoration-break',
    title: 'box-decoration-break',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `box-decoration-break`,
    description:
      "The box-decoration-break CSS property specifies how an element's fragments should be rendered when broken across multiple lines, columns, or pages.",
    values: [
      {
        name: 'slice',
        description:
          'The element is initially rendered as if its box were not fragmented, after which the rendering for this hypothetical box is sliced into pieces for each line/column/page. Note that the hypothetical box can be different for each fragment since it uses its own height if the break occurs in the inline direction, and its own width if the break occurs in the block direction. See the CSS specification for details.',
      },
      {
        name: 'clone',
        description:
          'Each box fragment is rendered independently with the specified border, padding, and margin wrapping each fragment. The border-radius, border-image, and box-shadow are applied to each fragment independently. The background is also drawn independently for each fragment, which means that a background image with background-repeat: no-repeat may nevertheless repeat multiple times.',
      },
    ],
    tagHelper: {
      selector: 'a',
      properties: [
        {
          name: 'bottom',
          value: '0',
        },
      ],
    },
  },
  {
    name: 'box-shadow',
    title: 'Box Shadow',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `box-shadow`,
    description:
      "The box-shadow CSS property adds shadow effects around an element's frame. You can set multiple effects separated by commas. A box shadow is described by X and Y offsets relative to the element, blur and spread radius, and color. If only two values are given, they are interpreted as <offset-x> and <offset-y> values. If a third value is given, it is interpreted as a <blur-radius>. If a fourth value is given, it is interpreted as a <spread-radius>. xz. Optionally, the inset keyword. Optionally, a <color> value.",
    values: [
      {
        name: 'inset',
        description:
          'If not specified (default), the shadow is assumed to be a drop shadow (as if the box were raised above the content). The presence of the inset keyword changes the shadow to one inside the frame (as if the content was debossed inside the box). Inset shadows are drawn inside the border (even transparent ones), above the background, but below content.',
      },
      {
        name: 'offset-x',
        description:
          'The <length> value specifies the horizontal distance. Negative values place the shadow to the left of the element.',
      },
      {
        name: 'offset-y',
        description:
          'The <length> values specifies the vertical distance. Negative values place the shadow above the element. If both <offset-x> and <offset-y> are set to 0, the shadow is placed behind the element (and may generate a blur effect if <blur-radius> and/or <spread-radius> is set).',
      },
      {
        name: 'blur-radius',
        description:
          "This is a third <length> value. The larger this value, the bigger the blur, so the shadow becomes bigger and lighter. Negative values are not allowed. If not specified, it will be 0 (the shadow's edge is sharp).",
      },
      {
        name: 'spread-radius',
        description:
          'This is a fourth <length> value. Positive values will cause the shadow to expand and grow bigger, negative values will cause the shadow to shrink. If not specified, it will be 0 (the shadow will be the same size as the element).',
      },
      {
        name: 'color-value',
        description:
          'See <color> values for possible keywords and notations. If not specified, it defaults to currentcolor.',
      },
    ],
    tagHelper: {
      selector: 'a',
      properties: [
        {
          name: 'box-shadow',
          value: '3px 3px black,',
        },
      ],
    },
  },
  {
    name: 'box-sizing',
    title: 'Box Sizing',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `box-sizing`,
    description:
      'The box-sizing CSS property sets how the total width and height of an element is calculated.',
    values: [
      {
        name: 'content-box',
        description:
          'This is the initial and default value as specified by the CSS standard. The width and height properties include the content, but does not include the padding, border, or margin. For example, .box {width: 350px; border: 10px solid black;} renders a box that is 370px wide.',
      },
      {
        name: 'border-box',
        description:
          "The width and height properties include the content, padding, and border, but do not include the margin. Note that padding and border will be inside of the box. For example, .box {width: 350px; border: 10px solid black;} renders a box that is 350px wide, with the area for content being 330px wide. The content box can't be negative and is floored to 0, making it impossible to use border-box to make the element disappear.",
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'box-sizing',
          value: 'border-box',
        },
      ],
    },
  },
  {
    name: 'break-after',
    title: 'Break After',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `break-after`,
    description:
      'The break-after CSS property sets how page, column, or region breaks should behave after a generated box. If there is no generated box, the property is ignored. If any of the three concerned values is a forced break value (always, left, right, page, column, or region), it has precedence. If more than one of them are such a break, the one of the element that appears the latest in the flow is taken (i.e., the break-before value has precedence over the break-after value, which itself has precedence over the break-inside value). If any of the three concerned values is an avoid break value (avoid, avoid-page, avoid-region, or avoid-column), no such break will be applied at that point.',
    values: [
      {
        name: 'auto',
        description:
          'Allows, but does not force, any break (page, column, or region) to be inserted right after the principal box.',
      },
      {
        name: 'avoid',
        description:
          'Avoids any break (page, column, or region) from being inserted right after the principal box.',
      },
      {
        name: 'avoid-page',
        description:
          'Avoids any page break right after the principal box.',
      },
      {
        name: 'page',
        description: 'Forces a page break right after the principal box.',
      },
      {
        name: 'left',
        description:
          "Forces one or two page breaks right after the principal box, whichever will make the next page into a left page. It's the page placed on the left side of the spine of the book or the back side of the page in duplex printing.",
      },
      {
        name: 'right',
        description:
          "Forces one or two page breaks right after the principal box, whichever will make the next page into a right page. It's the page placed on the right side of the spine of the book or the front side of the page in duplex printing.",
      },
      {
        name: 'avoid-column',
        description:
          'Avoids any column break right after the principal box.',
      },
      {
        name: 'column',
        description:
          'Forces a column break right after the principal box.',
      },
    ],
    tagHelper: {
      selector: 'p',
      properties: [
        {
          name: 'break-after',
          value: 'avoid',
        },
      ],
    },
  },
  {
    name: 'break-before',
    title: 'Break Before',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `break-before`,
    description:
      'The break-before CSS property sets how page, column, or region breaks should behave before a generated box. If there is no generated box, the property is ignored. If any of the three concerned values is a forced break value (always, left, right, page, column, or region), it has precedence. If more than one of them are such a break, the one of the element that appears the latest in the flow is taken (i.e., the break-before value has precedence over the break-after value, which itself has precedence over the break-inside value). If any of the three concerned values is an avoid break value (avoid, avoid-page, avoid-region, or avoid-column), no such break will be applied at that point.',
    values: [
      {
        name: 'auto',
        description:
          'Allows, but does not force, any break (page, column, or region) to be inserted right before the principal box.',
      },
      {
        name: 'avoid',
        description:
          'Avoids any break (page, column, or region) from being inserted right before the principal box.',
      },
      {
        name: 'avoid-page',
        description:
          'Avoids any page break right before the principal box.',
      },
      {
        name: 'page',
        description: 'Forces a page break right before the principal box.',
      },
      {
        name: 'left',
        description:
          "Forces one or two page breaks right before the principal box, whichever will make the next page into a left page. It's the page placed on the left side of the spine of the book or the back side of the page in duplex printing.",
      },
      {
        name: 'right',
        description:
          "Forces one or two page breaks right before the principal box, whichever will make the next page into a right page. It's the page placed on the right side of the spine of the book or the front side of the page in duplex printing.",
      },
      {
        name: 'avoid-column',
        description:
          'Avoids any column break right before the principal box.',
      },
      {
        name: 'column',
        description:
          'Forces a column break right before the principal box.',
      },
    ],
    tagHelper: {
      selector: 'p',
      properties: [
        {
          name: 'break-before',
          value: 'avoid',
        },
      ],
    },
  },
  {
    name: 'break-inside',
    title: 'Break Inside',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `break-inside`,
    description:
      'The break-inside CSS property sets how page, column, or region breaks should behave inside a generated box. If there is no generated box, the property is ignored. If any of the three concerned values is a forced break value (always, left, right, page, column, or region), it has precedence. If more than one of them are such a break, the value of the element that appears the latest in the flow is used. Thus, the break-before value has precedence over the break-after value, which in turn has precedence over the break-inside value. If any of the three concerned values is an avoid break value (avoid, avoid-page, avoid-region, or avoid-column), no such break will be applied at that point.',
    values: [
      {
        name: 'auto',
        description:
          'Allows, but does not force, any break (page, column, or region) to be inserted within the principal box.',
      },
      {
        name: 'avoid',
        description:
          'Avoids any break (page, column, or region) from being inserted within the principal box.',
      },
      {
        name: 'avoid-page',
        description: 'Avoids any page break within the principal box.',
      },
      {
        name: 'avoid-column',
        description: 'Avoids any column break within the principal box.',
      },
    ],
    tagHelper: {
      selector: 'p',
      properties: [
        {
          name: 'break-before',
          value: 'avoid',
        },
      ],
    },
  },
  {
    name: 'caption-side',
    title: 'Caption Side',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `caption-side`,
    description:
      "The caption-side CSS property puts the content of a table's <caption> on the specified side. The values are relative to the writing-mode of the table.",
    values: [
      {
        name: 'top',
        description:
          'The caption box should be positioned above the table.',
      },
      {
        name: 'bottom',
        description:
          'The caption box should be positioned above the table.',
      },
      {
        name: 'block-start',
        description:
          'The caption box should be positioned at the block start edge of the table.',
      },
      {
        name: 'block-end',
        description:
          'The caption box should be positioned at the block end edge of the table.',
      },
      {
        name: 'inline-start',
        description:
          'The caption box should be positioned at the inline start edge of the table.',
      },
      {
        name: 'inline-end',
        description:
          'The caption box should be positioned at the inline end edge of the table.',
      },
    ],
    tagHelper: {
      selector: 'table',
      properties: [
        {
          name: 'caption-side',
          value: 'top',
        },
      ],
    },
  },
  {
    name: 'carrot-color',
    title: 'Carrot Color',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `carrot-color`,
    description:
      'The caret-color CSS property sets the color of the insertion caret, the visible marker where the next character typed will be inserted. This is sometimes referred to as the text input cursor. The caret appears in elements such as <input> or those with the contenteditable attribute. The caret is typically a thin vertical line that flashes to help make it more noticeable. By default, it is black, but its color can be altered with this property.',
    values: [
      {
        name: 'auto',
        description:
          'The user agent selects an appropriate color for the caret. This is generally currentcolor, but the user agent may choose a different color to ensure good visibility and contrast with the surrounding content, taking into account the value of currentcolor, the background, shadows, and other factors.',
      },
      {
        name: 'color-value',
        description: 'Any color value.',
      },
    ],
    tagHelper: {
      selector: 'input',
      properties: [
        {
          name: 'carrot-color',
          value: 'green',
        },
      ],
    },
  },
  {
    name: 'clear',
    title: 'Clear',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `clear`,
    description:
      "The clear CSS property sets whether an element must be moved below (cleared) floating elements that precede it. The clear property applies to floating and non-floating elements. When applied to non-floating blocks, it moves the border edge of the element down until it is below the margin edge of all relevant floats. The non-floated block's top margin collapses. Vertical margins between two floated elements on the other hand will not collapse. When applied to floating elements, the margin edge of the bottom element is moved below the margin edge of all relevant floats. This affects the position of later floats, since later floats cannot be positioned higher than earlier ones.",
    values: [
      {
        name: 'none',
        description:
          'The user agent selects an appropriate color for the caret. This is generally currentcolor, but the user agent may choose a different color to ensure good visibility and contrast with the surrounding content, taking into account the value of currentcolor, the background, shadows, and other factors.',
      },
      {
        name: 'left',
        description: 'Any color value.',
      },
      {
        name: 'right',
        description: 'Any color value.',
      },
      {
        name: 'both',
        description: 'Any color value.',
      },
      {
        name: 'inline-start',
        description: 'Any color value.',
      },
      {
        name: 'inline-end',
        description: 'Any color value.',
      },
    ],
    tagHelper: {
      selector: 'p',
      properties: [
        {
          name: 'clear',
          value: 'left',
        },
      ],
    },
  },
  {
    name: 'clip-path',
    title: 'Clip Path',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `clip-path`,
    description:
      'The clip-path CSS property creates a clipping region that sets what part of an element should be shown. Parts that are inside the region are shown, while those outside are hidden.',
    values: [
      {
        name: 'url-value',
        description: 'A url() referencing an SVG <clipPath> element.',
      },
      {
        name: 'basic-shape',
        description:
          'A shape whose size and position is defined by the <geometry-box> value. If no geometry box is specified, the border-box will be used as the reference box. One of the following. inset(): Defines an inset rectangle. circle(): Defines a circle using a radius and a position. eclipse(): Defines an ellipse using two radii and a position. polygon(): Defines a polygon using an SVG filling rule and a set of vertices. Defines a shape using an optional SVG filling rule and an SVG path definition.',
      },
      {
        name: 'geometry-box',
        description:
          'If specified in combination with a basic-shape, this value defines the reference box for the basic shape. If specified by itself, it causes the edges of the specified box, including any corner shaping (such as a border-radius), to be the clipping path. The geometry box can be one of the following values. margin-box: Uses the margin box as the reference box. border-box: Uses the border box as the reference box. padding-box: Uses the padding box as the reference box. content-box: Uses the content box as the reference box. fill-box: Uses the object bounding box as the reference box. stroke-box: Uses the stroke bounding box as the reference box. view-box: Uses the nearest SVG viewport as the reference box. If a viewBox attribute is specified for the element creating the SVG viewport, the reference box is positioned at the origin of the coordinate system established by the viewBox attribute and the dimension of the size of the reference box is set to the width and height values of the viewBox attribute.',
      },
      {
        name: 'none',
        description: 'No clipping path is created.',
      },
    ],
    tagHelper: {
      selector: 'p',
      properties: [
        {
          name: 'clear',
          value: 'left',
        },
      ],
    },
  },
  {
    name: 'color',
    title: 'Color',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `color`,
    description:
      "The color CSS property sets the foreground color value of an element's text and text decorations, and sets the currentcolor value. currentcolor may be used as an indirect value on other properties and is the default for other color properties, such as border-color.",
    values: [
      {
        name: 'color-value',
        description:
          'Sets the color of the textual and decorative parts of the element.',
      },
      {
        name: 'current-color',
        description:
          "Sets the color to the element's color property value. However, if set as the value of color, currentcolor is treated as inherit.",
      },
    ],
    tagHelper: {
      selector: 'h1',
      properties: [
        {
          name: 'color',
          value: 'red',
        },
      ],
    },
  },
  {
    name: 'column-count',
    title: 'Column Count',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `column-count`,
    description:
      "The column-count CSS property breaks an element's content into the specified number of columns.",
    values: [
      {
        name: 'auto',
        description:
          'The number of columns is determined by other CSS properties, such as column-width.',
      },
      {
        name: 'integer-value',
        description:
          'Is a strictly positive <integer> describing the ideal number of columns into which the content of the element will be flowed. If the column-width is also set to a non-auto value, it merely indicates the maximum allowed number of columns.',
      },
    ],
    tagHelper: {
      selector: 'table',
      properties: [
        {
          name: 'column-count',
          value: '3',
        },
      ],
    },
  },
  {
    name: 'column-fill',
    title: 'Column Fill',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `column-fill`,
    description:
      "The column-fill CSS property controls how an element's contents are balanced when broken into columns.",
    values: [
      {
        name: 'auto',
        description:
          'Columns are filled sequentially. Content takes up only the room it needs, possibly resulting in some columns remaining empty.',
      },
      {
        name: 'balance',
        description:
          'Content is equally divided between columns. In fragmented contexts, such as paged media, only the last fragment is balanced. Therefore in paged media, only the last page would be balanced.',
      },
    ],
    tagHelper: {
      selector: 'table',
      properties: [
        {
          name: 'column-fill',
          value: 'balance',
        },
      ],
    },
  },
  {
    name: 'column-gap',
    title: 'Column Gap',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `column-gap`,
    description:
      "The column-gap CSS property sets the size of the gap (gutter) between an element's columns.",
    values: [
      {
        name: 'normal',
        description:
          "The browser's default spacing is used between columns. For multi-column layout this is specified as 1em. For all other layout types it is 0.",
      },
      {
        name: 'length-value',
        description:
          "The size of the gap between columns, defined as a <length>. The <length> property's value must be non-negative.",
      },
      {
        name: 'percent-value',
        description:
          "The size of the gap between columns, defined as a <percentage>. The <percentage> property's value must be non-negative.",
      },
    ],
    tagHelper: {
      selector: 'table',
      properties: [
        {
          name: 'column-gap',
          value: '10px',
        },
      ],
    },
  },
  {
    name: 'column-rule',
    title: 'Column Rule',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `column-rule`,
    description:
      'The column-rule shorthand CSS property sets the width, style, and color of the line drawn between columns in a multi-column layout.',
    values: [
      {
        name: 'normal',
        description:
          "The browser's default spacing is used between columns. For multi-column layout this is specified as 1em. For all other layout types it is 0.",
      },
      {
        name: 'length-value',
        description:
          "The size of the gap between columns, defined as a <length>. The <length> property's value must be non-negative.",
      },
      {
        name: 'percent-value',
        description:
          "The size of the gap between columns, defined as a <percentage>. The <percentage> property's value must be non-negative.",
      },
    ],
    tagHelper: {
      selector: 'table',
      properties: [
        {
          name: 'column-gap',
          value: '10px',
        },
      ],
    },
  },
  {
    name: 'color-scheme',
    title: 'Color Scheme',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `color-scheme`,
    description:
      'The color-scheme CSS property allows an element to indicate which color schemes it can comfortably be rendered in.',
    values: [
      {
        name: 'normal',
        description:
          "Indicates that the element isn't aware of any color schemes, and so should be rendered using the browser's default color scheme.",
      },
      {
        name: 'light',
        description:
          'Indicates that the element can be rendered using the operating system light color scheme.',
      },
      {
        name: 'dark',
        description:
          'Indicates that the element can be rendered using the operating system dark color scheme.',
      },
      {
        name: 'only',
        description:
          'Forbids the user agent from overriding the color scheme for the element.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'color-scheme',
          value: 'dark',
        },
      ],
    },
  },
  {
    name: 'content',
    title: 'Content',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `content`,
    description:
      'The content CSS property replaces an element with a generated value. Objects inserted using the content property are anonymous replaced elements.',
    values: [
      {
        name: 'none',
        description:
          'When applied to a pseudo-element, the pseudo-element is not generated. If applied to an element, the value has no effect.',
      },
      {
        name: 'normal',
        description:
          'Computes to none for the ::before and ::after pseudo-elements.',
      },
      {
        name: 'string-value',
        description: `Specifies the "alt text" for the element. This value can be any number of text characters. Non-Latin characters must be encoded using their Unicode escape sequences.`,
      },
      {
        name: 'content-list',
        description:
          'A list of anonymous inline boxes that will replace the content of the selected element (in the specified order). This list can include strings, images, counters, and so on.',
      },
      {
        name: 'image-value',
        description:
          'An <image>, denoted by the url() or image-set() or <gradient> data type, or part of the webpage, defined by the element() function, denoting the content to display.',
      },
      {
        name: 'counter()',
        description:
          "The value of a CSS counter, generally a number produced by computations defined by <counter-reset> and <counter-increment> properties. It can be displayed using either the counter() or counters() function. The counter() function has two forms: 'counter(name)' or 'counter(name, style)'. The generated text is the value of the innermost counter of the given name in scope at the given pseudo-element. It is formatted in the specified <list-style-type> (decimal by default). The counters() function also has two forms: 'counters(name, string)' or 'counters(name, string, style)'. The generated text is the value of all counters with the given name in scope at the given pseudo-element, from outermost to innermost, separated by the specified string. The counters are rendered in the indicated <list-style-type> (decimal by default).",
      },
      {
        name: 'image-value',
        description:
          'An <image>, denoted by the url() or image-set() or <gradient> data type, or part of the webpage, defined by the element() function, denoting the content to display.',
      },
    ],
    tagHelper: {
      selector: 'section',
      properties: [
        {
          name: 'content',
          value: "url('./someFile.txt')",
        },
      ],
    },
  },
  {
    name: 'cursor',
    title: 'Cursor',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `cursor`,
    description:
      'The cursor CSS property sets the mouse cursor, if any, to show when the mouse pointer is over an element. The cursor setting should inform users of the mouse operations that can be performed at the current location, including: text selection, activating help or context menus, copying content, resizing tables, and so on. You can specify either the type of cursor using a keyword, or load a specific icon to use (with optional fallback images and mandatory keyword as a final fallback).',
    values: [
      {
        name: 'url-value(s)',
        description:
          'A url() or a comma separated list url(), url(), …, pointing to an image file. More than one url() may be provided as fallbacks, in case some cursor image types are not supported. A non-URL fallback (one or more of the keyword values) must be at the end of the fallback list.',
      },
      {
        name: 'x-y',
        description:
          'Optional x- and y-coordinates indicating the cursor hotspot; the precise position within the cursor that is being pointed to. The numbers are in units of image pixels. They are relative to the top left corner of the image, which corresponds to "0 0", and are clamped within the boundaries of the cursor image. If these values are not specified, they may be read from the file itself, and will otherwise default to the top-left corner of the image.',
      },
      {
        name: 'auto',
        description: `The UA will determine the cursor to display based on the current context. E.g., equivalent to text when hovering text.`,
      },
      {
        name: 'default',
        description:
          'The platform-dependent default cursor. Typically an arrow.',
      },
      {
        name: 'none',
        description: 'No cursor is rendered.',
      },
      {
        name: 'context-menu',
        description: 'A context menu is available.',
      },
      {
        name: 'help',
        description: 'Help information is available.',
      },
      {
        name: 'pointer',
        description:
          'The cursor is a pointer that indicates a link. Typically an image of a pointing hand',
      },
      {
        name: 'progress',
        description:
          'The program is busy in the background, but the user can still interact with the interface (in contrast to wait).',
      },
      {
        name: 'wait',
        description:
          "The program is busy, and the user can't interact with the interface (in contrast to progress). Sometimes an image of an hourglass or a watch.",
      },
      {
        name: 'crosshair',
        description:
          'Cross cursor, often used to indicate selection in a bitmap.',
      },
      {
        name: 'text',
        description:
          'The text can be selected. Typically the shape of an I-beam. cursor, often used to indicate selection in a bitmap.',
      },
      {
        name: 'vertical-text',
        description:
          'The vertical text can be selected. Typically the shape of a sideways I-beam.',
      },
      {
        name: 'alias',
        description: 'An alias or shortcut is to be created.',
      },
      {
        name: 'copy',
        description: 'Something is to be copied.',
      },
      {
        name: 'move',
        description: 'Something is to be moved.',
      },
      {
        name: 'not-allowed',
        description: 'The requested action will not be carried out.',
      },
      {
        name: 'grab',
        description: 'Something can be grabbed (dragged to be moved).',
      },
      {
        name: 'grabbing',
        description: 'Something is being grabbed (dragged to be moved).',
      },
      {
        name: 'col-resize',
        description:
          'The item/column can be resized horizontally. Often rendered as arrows pointing left and right with a vertical bar separating them.',
      },
      {
        name: 'row-resize',
        description:
          'The item/row can be resized vertically. Often rendered as arrows pointing up and down with a horizontal bar separating them.',
      },
      {
        name: 'ew-resize',
        description: 'Bidirectional resize cursor.',
      },
      {
        name: 'ns-resize',
        description: 'Bidirectional resize cursor.',
      },
      {
        name: 'nesw-resize',
        description: 'Bidirectional resize cursor.',
      },
      {
        name: 'nwse-resize',
        description: 'Bidirectional resize cursor.',
      },
      {
        name: 'zoom-in',
        description: 'Something can be zoomed (magnified) in or out.',
      },
      {
        name: 'zoom-out',
        description: 'Something can be zoomed (magnified) in or out.',
      },
    ],
    tagHelper: {
      selector: 'a',
      properties: [
        {
          name: 'cursor',
          value: 'pointer',
        },
      ],
    },
  },
  {
    name: 'display',
    title: 'Display',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `display`,
    description:
      "The display CSS property sets whether an element is treated as a block or inline box and the layout used for its children, such as flow layout, grid or flex. Formally, the display property sets an element's inner and outer display types. The outer type sets an element's participation in flow layout; the inner type sets the layout of children.",
    values: [
      {
        name: 'block',
        description:
          'The element generates a block box, generating line breaks both before and after the element when in the normal flow.',
      },
      {
        name: 'inline',
        description:
          'The element generates one or more inline boxes that do not generate line breaks before or after themselves. In normal flow, the next element will be on the same line if there is space.',
      },
      {
        name: 'flow-root',
        description: `The element generates a block box that establishes a new block formatting context, defining where the formatting root lies.`,
      },
      {
        name: 'table',
        description:
          'These elements behave like HTML <table> elements. It defines a block-level box.',
      },
      {
        name: 'flex',
        description:
          'The element behaves like a block-level element and lays out its content according to the flexbox model.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'display',
          value: 'flex',
        },
      ],
    },
  },
  {
    name: 'empty-cells',
    title: 'Empty Cells',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `empty-cells`,
    description:
      'The empty-cells CSS property sets whether borders and backgrounds appear around <table> cells that have no visible content.',
    values: [
      {
        name: 'hidden',
        description: 'No borders or backgrounds are drawn.',
      },
      {
        name: 'show',
        description:
          'Borders and backgrounds are drawn like in normal cells.',
      },
    ],
    tagHelper: {
      selector: 'table',
      properties: [
        {
          name: 'empty-cells',
          value: 'show',
        },
      ],
    },
  },
  {
    name: 'filter',
    title: 'Filter',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `filter`,
    description:
      'The filter CSS property applies one or more graphical effects like blur or color shift to an element. Filters are commonly used to adjust the rendering of images, backgrounds, and borders.',
    values: [
      {
        name: 'blur()',
        description: 'Applies a Gaussian blur to the input image.',
      },
      {
        name: 'brightness()',
        description:
          'Applies a linear multiplier to the input image, making it appear more or less bright. Values are linear multipliers on the effect, with 0% creating a completely black image, 100% having no effect, and values over 100% brightening the image.',
      },
      {
        name: 'contrast()',
        description:
          'Adjusts the contrast of the input image. A value of 0% makes the image grey, 100% has no effect, and values over 100% create a contrast.',
      },
      {
        name: 'drop-shadow()',
        description:
          'Applies the parameter <shadow> as a drop shadow, following the contours of the image. The shadow syntax is similar to <box-shadow> (defined in the CSS backgrounds and borders module), with the exception that the inset keyword and spread parameter are not allowed. As with all filter property values, any filters after the drop-shadow() are applied to the shadow.',
      },
      {
        name: 'grayscale()',
        description:
          'Converts the image to grayscale. A value of 100% is completely grayscale. The initial value of 0% leaves the input unchanged. Values between 0% and 100% produce linear multipliers on the effect.',
      },
      {
        name: 'hue-rotate()',
        description:
          'Applies a hue rotation. The <angle> value defines the number of degrees around the hue color circle at which the input samples will be adjusted. A value of 0deg leaves the input unchanged.',
      },
      {
        name: 'invert()',
        description:
          'Inverts the samples in the input image. A value of 100% completely inverts the image. A value of 0% leaves the input unchanged. Values between 0% and 100% have linear multipliers on the effect.',
      },
      {
        name: 'opacity()',
        description:
          'Applies transparency. 0% makes the image completely transparent and 100% leaves the image unchanged.',
      },
      {
        name: 'saturate()',
        description:
          'Saturates the image, with 0% being completely unsaturated, 100% leaving the image unchanged, and values of over 100% increasing saturation.',
      },
      {
        name: 'sepia()',
        description:
          'Converts the image to sepia, with a value of 100% making the image completely sepia and 0% making no change.',
      },
    ],
    tagHelper: {
      selector: 'img',
      properties: [
        {
          name: 'filter',
          value: 'blur(20%)',
        },
      ],
    },
  },
  {
    name: 'float',
    title: 'Float',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `float`,
    description:
      'The float CSS property places an element on the left or right side of its container, allowing text and inline elements to wrap around it. The element is removed from the normal flow of the page, though still remaining a part of the flow (in contrast to absolute positioning).',
    values: [
      {
        name: 'left',
        description:
          'The element must float on the left side of its containing block.',
      },
      {
        name: 'right',
        description:
          'The element must float on the right side of its containing block.',
      },
      {
        name: 'none',
        description: 'The element must not float.',
      },
      {
        name: 'inline-start',
        description:
          'The element must float on the start side of its containing block. That is the left side with ltr scripts, and the right side with rtl scripts.',
      },
      {
        name: 'inline-end',
        description:
          'The element must float on the end side of its containing block. That is the right side with ltr scripts, and the left side with rtl scripts.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'float',
          value: 'right',
        },
      ],
    },
  },
  {
    name: 'forced-color-adjust',
    title: 'Forced Color Adjust',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `forced-color-adjust`,
    description:
      'The forced-color-adjust CSS property allows authors to opt certain elements out of forced colors mode. This then restores the control of those values to CSS.',
    values: [
      {
        name: 'left',
        description:
          'The element must float on the left side of its containing block.',
      },
      {
        name: 'right',
        description:
          'The element must float on the right side of its containing block.',
      },
      {
        name: 'none',
        description: 'The element must not float.',
      },
    ],
    tagHelper: {
      selector: 'p',
      properties: [
        {
          name: 'forced-color-adjust',
          value: 'auto',
        },
      ],
    },
  },
  {
    name: 'gap',
    title: 'Gap',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `gap`,
    description:
      'The gap CSS property sets the gaps (gutters) between rows and columns. It is a shorthand for row-gap and column-gap.',
    values: [
      {
        name: 'length-value',
        description:
          'Is the width of the gutter separating the grid lines.',
      },
      {
        name: 'percentage-value',
        description:
          'Is the width of the gutter separating the grid lines, relative to the dimension of the element.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'gap',
          value: '10px',
        },
      ],
    },
  },
  {
    name: 'hanging-punctuation',
    title: 'Hanging Punctuation',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `hanging-punctuation`,
    description:
      'The hanging-punctuation CSS property specifies whether a punctuation mark should hang at the start or end of a line of text. Hanging punctuation may be placed outside the line box.',
    values: [
      {
        name: 'none',
        description: 'No character hangs.',
      },
      {
        name: 'first',
        description: `An opening bracket or quote at the start of the first formatted line of an element hangs. This applies to all characters in the Unicode categories Ps, Pf, Pi, the quote marks U+0027 APOSTROPHE (') and U+0022 QUOTATION MARK (").`,
      },
      {
        name: 'force-end',
        description: `A stop or comma at the end of a line hangs.`,
      },
      {
        name: 'allow-end',
        description:
          'A stop or comma at the end of a line hangs if it does not otherwise fit prior to justification.',
      },
    ],
    tagHelper: {
      selector: 'h1',
      properties: [
        {
          name: 'hanging-punctuation',
          value: 'first',
        },
      ],
    },
  },
  {
    name: 'height',
    title: 'Height',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `height`,
    description:
      'The height CSS property specifies the height of an element. By default, the property defines the height of the content area. If box-sizing is set to border-box, however, it instead determines the height of the border area.',
    values: [
      {
        name: 'length-value',
        description: 'Defines the height as a distance value.',
      },
      {
        name: 'percentage-value',
        description: `Defines the height as a percentage of the containing block's height.`,
      },
      {
        name: 'auto',
        description:
          'The browser will calculate and select a height for the specified element.',
      },
      {
        name: 'max-content',
        description: 'The intrinsic preferred height.',
      },
      {
        name: 'min-content',
        description: 'The intrinsic minimum height.',
      },
      {
        name: 'fit-content',
        description:
          'Box will use the available space, but never more than max-content.',
      },
      {
        name: 'clamp()',
        description:
          'Enables selecting a middle value within a range of values between a defined minimum and maximum.',
      },
    ],
    tagHelper: {
      selector: 'table',
      properties: [
        {
          name: 'height',
          value: '100px',
        },
      ],
    },
  },
  {
    name: 'hyphenate-character',
    title: 'Hyphenate Character',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `hyphenate-character`,
    description:
      'The hyphenate-character CSS property sets the character (or string) used at the end of a line before a hyphenation break.',
    values: [
      {
        name: 'auto',
        description:
          "The user-agent selects an appropriate string based on the content language's typographic conventions. This is the default property value, and only needs to be explicitly set in order to override a different inherited value.",
      },
      {
        name: 'string-value',
        description:
          'The <string> to use at the end of the line before a hyphenation break. The user agent may truncate this value if too many characters are used.',
      },
    ],
    tagHelper: {
      selector: 'p',
      properties: [
        {
          name: 'hyphenate-character',
          value: 'auto',
        },
      ],
    },
  },
  {
    name: 'hyphenate-limit-chars',
    title: 'Hyphenate Limit Characters',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `hyphenate-limit-chars`,
    description:
      'The hyphenate-limit-chars property specifies the minimum word length to allow hyphenation of words as well as the minimum number of characters before and after the hyphen. If auto is set for any of the values, the user agent will choose an appropriate value for the current layout. Unless the user agent can calculate a better value, the following default values will be used: Minimum word length to allow hyphenation: 5. Minimum number of characters before the hyphen: 2. Minimum number of characters after the hyphen: 2 ',
    values: [
      {
        name: 'x y z',
        description:
          'The first value is the minimum word length before words should be hyphenated. The second value is the minimum number of characters before the hyphen. The third value is the minimum number of characters after the hyphen.',
      },
      {
        name: 'x y',
        description:
          'The first value is the minimum word length before words should be hyphenated. The second value is the minimum number of characters before the hyphen. The minimum number of characters after the hyphen will be set equal to the second value.',
      },
      {
        name: 'x',
        description:
          'The value is the minimum word length before words should be hyphenated. The minimum number of characters before and after the hyphen will be set to auto.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'hyphenate-limit-chars',
          value: '10',
        },
      ],
    },
  },
  {
    name: 'hyphens',
    title: 'Hyphens',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `hyphens`,
    description:
      'The hyphens CSS property specifies how words should be hyphenated when text wraps across multiple lines. It can prevent hyphenation entirely, hyphenate at manually-specified points within the text, or let the browser automatically insert hyphens where appropriate.',
    values: [
      {
        name: 'none',
        description:
          'Words are not broken at line breaks, even if characters inside the words suggest line break points. Lines will only wrap at whitespace.',
      },
      {
        name: 'manual',
        description:
          'Default value. Words are broken for line-wrapping only where characters inside the word suggest line break opportunities. See Suggesting line break opportunities below for details.',
      },
      {
        name: 'auto',
        description:
          'The browser is free to automatically break words at appropriate hyphenation points, following whatever rules it chooses. However, suggested line break opportunities (see Suggesting line break opportunities below) will override automatic break point selection when present',
      },
    ],
    tagHelper: {
      selector: 'h1',
      properties: [
        {
          name: 'hyphens',
          value: '10',
        },
      ],
    },
  },
  {
    name: 'inline-size',
    title: 'Inline Size',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `inline-size`,
    description:
      "The inline-size CSS property defines the horizontal or vertical size of an element's block, depending on its writing mode. It corresponds to either the width or the height property, depending on the value of writing-mode.",
    values: [
      {
        name: 'string-value',
        description:
          'The inline-size property takes the same values as the width and height properties.',
      },
    ],
    tagHelper: {
      selector: 'b',
      properties: [
        {
          name: 'inline-size',
          value: '20px',
        },
      ],
    },
  },
  {
    name: 'isolation',
    title: 'Isolation',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `isolation`,
    description:
      'This property is especially helpful when used in conjunction with mix-blend-mode and z-index.',
    values: [
      {
        name: 'auto',
        description:
          'A new stacking context is created only if one of the properties applied to the element requires it.',
      },
      {
        name: 'isolate',
        description: 'A new stacking context must be created.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'isolation',
          value: 'isolate',
        },
      ],
    },
  },
  {
    name: 'left',
    title: 'Left',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `left`,
    description:
      'The left CSS property participates in specifying the horizontal position of a positioned element. It has no effect on non-positioned elements.',
    values: [
      {
        name: 'auto',
        description:
          'Specifies that: for absolutely positioned elements, the position of the element is based on the right property, while width: auto is treated as a width based on the content; or if right is also auto, the element is positioned where it should horizontally be positioned if it were a static element. - for relatively positioned elements, the distance of the element from its normal position is based on the right property; or if right is also auto, the element is not moved horizontally at all.',
      },
      {
        name: 'inherit',
        description:
          'Specifies that the value is the same as the computed value from its parent element (which might not be its containing block). This computed value is then handled as if it were a <length>, <percentage>, or the auto keyword.',
      },
      {
        name: 'percentage-value',
        description: "A <percentage> of the containing block's width.",
      },
      {
        name: 'length-value',
        description:
          'A negative, null, or positive <length> that represents: for absolutely positioned elements, the distance to the left edge of the containing block - for relatively positioned elements, the distance that the element is moved to the right of its normal position.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'left',
          value: '200px',
        },
      ],
    },
  },
  {
    name: 'letter-spacing',
    title: 'Letter Spacing',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `letter-spacing`,
    description:
      'The left CSS property participates in specifying the horizontal position of a positioned element. It has no effect on non-positioned elements.',
    values: [
      {
        name: 'normal',
        description:
          'The normal letter spacing for the current font. Unlike a value of 0, this keyword allows the user agent to alter the space between characters in order to justify text.',
      },
      {
        name: 'length-value',
        description:
          'Specifies extra inter-character space in addition to the default space between characters. Values may be negative, but there may be implementation-specific limits. User agents may not further increase or decrease the inter-character space in order to justify text.',
      },
    ],
    tagHelper: {
      selector: 'p',
      properties: [
        {
          name: 'letter-spacing',
          value: '2px',
        },
      ],
    },
  },
  {
    name: 'mix-blend-mode',
    title: 'Mix Blend Mode',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `mix-blend-mode`,
    description:
      "The mix-blend-mode CSS property sets how an element's content should blend with the content of the element's parent and the element's background.",
    values: [
      {
        name: 'normal',
        description:
          'This is the default value. It applies no blending, so the element appears as it normally would. ',
      },
      {
        name: 'multiply',
        description:
          'Pixels of the top layer are multiplied with corresponding pixels of the bottom layer, making the result darker.',
      },
      {
        name: 'screen',
        description:
          "This is the opposite of multiply. It lightens the colors by screening the top layer's colors with the bottom, using the formula 1 - (1 - top) * (1 - bottom).",
      },
      {
        name: 'overlay',
        description:
          "This combines multiply and screen. If the underlying color is lighter than 0.5, the screen effect is used. If it's darker, the multiply effect is used.",
      },
      {
        name: 'darken',
        description:
          'Compares each pixel of the top layer with the corresponding pixel of the bottom layer and chooses the darker of the two.',
      },
      {
        name: 'lighten',
        description:
          'Compares each pixel of the top layer with the corresponding pixel of the bottom layer and chooses the lighter of the two.',
      },
      {
        name: 'color-dodge',
        description:
          "Brightens the bottom layer based on the top layer's color, creating a more contrasted result.",
      },
      {
        name: 'color-burn',
        description:
          "Darkens the bottom layer based on the top layer's color, also increasing contrast.",
      },
      {
        name: 'hard-light',
        description:
          "Similar to overlay, but with the layers' roles reversed.",
      },
      {
        name: 'soft-light',
        description: 'Similar to hard-light but with softer contrast.',
      },
      {
        name: 'difference',
        description: 'Subtracts the darker color from the lighter color.',
      },
      {
        name: 'exclusion',
        description: 'Similar to difference, but with lower contrast.',
      },
      {
        name: 'hue',
        description:
          'Uses the hue of the top layer, with the saturation and luminosity of the bottom layer.',
      },
      {
        name: 'saturation',
        description:
          'Uses the saturation of the top layer, with the hue and luminosity of the bottom layer.',
      },
      {
        name: 'color',
        description:
          'Uses the hue and saturation of the top layer, with the luminosity of the bottom layer.',
      },
      {
        name: 'luminosity',
        description:
          'Uses the luminosity of the top layer, with the hue and saturation of the bottom layer.',
      },
    ],
    tagHelper: {
      selector: 'img',
      properties: [
        {
          name: 'mix-blend-mode',
          value: 'soft-light',
        },
      ],
    },
  },
  {
    name: 'object-fit',
    title: 'Object Fit',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `object-fit`,
    description:
      "The object-fit CSS property sets how the content of a replaced element, such as an <img> or <video>, should be resized to fit its container. You can alter the alignment of the replaced element's content object within the element's box using the object-position property.",
    values: [
      {
        name: 'contain',
        description: `The replaced content is scaled to maintain its aspect ratio while fitting within the element's content box. The entire object is made to fill the box, while preserving its aspect ratio, so the object will be "letterboxed" if its aspect ratio does not match the aspect ratio of the box.`,
      },
      {
        name: 'cover',
        description:
          "The replaced content is sized to maintain its aspect ratio while filling the element's entire content box. If the object's aspect ratio does not match the aspect ratio of its box, then the object will be clipped to fit.",
      },
      {
        name: 'fill',
        description:
          "The replaced content is sized to fill the element's content box. The entire object will completely fill the box. If the object's aspect ratio does not match the aspect ratio of its box, then the object will be stretched to fit.",
      },
      {
        name: 'none',
        description: 'The replaced content is not resized.',
      },
      {
        name: 'scale-down',
        description:
          'The content is sized as if none or contain were specified, whichever would result in a smaller concrete object size.',
      },
    ],
    tagHelper: {
      selector: 'img',
      properties: [
        {
          name: 'object-fit',
          value: 'none',
        },
      ],
    },
  },
  {
    name: 'object-position',
    title: 'Object Position',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `object-position`,
    description:
      "The object-position CSS property specifies the alignment of the selected replaced element's contents within the element's box. Areas of the box which aren't covered by the replaced element's object will show the element's background.",
    values: [
      {
        name: 'position-value',
        description: `From one to four values that define the 2D position of the element. Relative or absolute offsets can be used.`,
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'object-position',
          value: '20px 0',
        },
      ],
    },
  },
  {
    name: 'opacity',
    title: 'Opacity',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `opacity`,
    description:
      'The opacity CSS property sets the opacity of an element. Opacity is the degree to which content behind an element is hidden, and is the opposite of transparency.',
    values: [
      {
        name: 'alpha-value',
        description:
          'A <number> in the range 0.0 to 1.0, inclusive, or a <percentage> in the range 0% to 100%, inclusive, representing the opacity of the channel (that is, the value of its alpha channel). Any value outside the interval, though valid, is clamped to the nearest limit in the range.',
      },
    ],
    tagHelper: {
      selector: 'section',
      properties: [
        {
          name: 'opacity',
          value: '.50',
        },
      ],
    },
  },
  {
    name: 'order',
    title: 'Order',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `order`,
    description:
      'The order CSS property sets the order to lay out an item in a flex or grid container. Items in a container are sorted by ascending order value and then by their source code order.',
    values: [
      {
        name: 'integer-value',
        description:
          'Represents the ordinal group to be used by the item.',
      },
    ],
    tagHelper: {
      selector: 'article',
      properties: [
        {
          name: 'order',
          value: '3',
        },
      ],
    },
  },
  {
    name: 'orphans',
    title: 'Orphans',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `orphans`,
    description:
      'The orphans CSS property sets the minimum number of lines in a block container that must be shown at the bottom of a page, region, or column. In typography, an orphan is the first line of a paragraph that appears alone at the bottom of a page. (The paragraph continues on a following page.)',
    values: [
      {
        name: 'integer-value',
        description:
          'The minimum number of lines that can stay by themselves at the bottom of a fragment before a fragmentation break. The value must be positive.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'orphans',
          value: '2',
        },
      ],
    },
  },
  {
    name: 'paint-order',
    title: 'Paint Order',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `paint-order`,
    description:
      'The paint-order CSS property lets you control the order in which the fill and stroke (and painting markers) of text content and shapes are drawn.',
    values: [
      {
        name: 'normal',
        description: 'Paint the different items in normal paint order.',
      },
      {
        name: 'stroke, fill, marker',
        description:
          'Specify some or all of these values in the order you want them to be painted in.',
      },
    ],
    tagHelper: {
      selector: 'svg',
      properties: [
        {
          name: 'paint-order',
          value: 'fill',
        },
      ],
    },
  },
  {
    name: 'perspective',
    title: 'Perspective',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `perspective`,
    description:
      'The perspective CSS property determines the distance between the z=0 plane and the user in order to give a 3D-positioned element some perspective. The vanishing point is by default placed at the center of the element, but its position can be changed using the perspective-origin property.',
    values: [
      {
        name: 'none',
        description:
          'Indicates that no perspective transform is to be applied.',
      },
      {
        name: 'length-value',
        description:
          'A <length> giving the distance from the user to the z=0 plane. It is used to apply a perspective transform to the children of the element. Negative values are syntax errors. If the value is smaller than 1px, it is clamped to 1px.',
      },
    ],
    tagHelper: {
      selector: 'img',
      properties: [
        {
          name: 'perspective',
          value: '500px',
        },
      ],
    },
  },
  {
    name: 'perspective-origin',
    title: 'Perspective Origin',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `perspective-origin`,
    description:
      'The perspective-origin CSS property determines the position at which the viewer is looking. It is used as the vanishing point by the perspective property. The perspective-origin and perspective properties are attached to the parent of a child transformed in 3-dimensional space, unlike the perspective() transform function which is placed on the element being transformed.',
    values: [
      {
        name: 'none',
        description:
          'Indicates that no perspective transform is to be applied.',
      },
      {
        name: 'length-value',
        description:
          'A <length> giving the distance from the user to the z=0 plane. It is used to apply a perspective transform to the children of the element. Negative values are syntax errors. If the value is smaller than 1px, it is clamped to 1px.',
      },
    ],
    tagHelper: {
      selector: 'img',
      properties: [
        {
          name: 'perspective',
          value: '500px',
        },
      ],
    },
  },
  {
    name: 'pointer-events',
    title: 'Pointer Events',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `pointer-events`,
    description:
      'The pointer-events CSS property sets under what circumstances (if any) a particular graphic element can become the target of pointer events.',
    values: [
      {
        name: 'auto',
        description:
          'The element behaves as it would if the pointer-events property were not specified. In SVG content, this value and the value visiblePainted have the same effect.',
      },
      {
        name: 'none',
        description:
          'The element is never the target of pointer events; however, pointer events may target its descendant elements if those descendants have pointer-events set to some other value. In these circumstances, pointer events will trigger event listeners on this parent element as appropriate on their way to/from the descendant during the event capture/bubble phases.',
      },
      {
        name: 'visiblePainted',
        description:
          "SVG only (experimental for HTML). The element can only be the target of a pointer event when the visibility property is set to visible and e.g. when a mouse cursor is over the interior (i.e., 'fill') of the element and the fill property is set to a value other than none, or when a mouse cursor is over the perimeter (i.e., 'stroke') of the element and the stroke property is set to a value other than none.",
      },
      {
        name: 'visibleFill',
        description:
          'SVG only. The element can only be the target of a pointer event when the visibility property is set to visible and when e.g. a mouse cursor is over the interior (i.e., fill) of the element. The value of the fill property does not affect event processing.',
      },
      {
        name: 'visibleStroke',
        description:
          'SVG only. The element can only be the target of a pointer event when the visibility property is set to visible and e.g. when the mouse cursor is over the perimeter (i.e., stroke) of the element. The value of the stroke property does not affect event processing.',
      },
      {
        name: 'visible',
        description:
          'SVG only (experimental for HTML). The element can be the target of a pointer event when the visibility property is set to visible and e.g. the mouse cursor is over either the interior (i.e., fill) or the perimeter (i.e., stroke) of the element. The values of the fill and stroke do not affect event processing.',
      },
      {
        name: 'painted',
        description:
          "SVG only (experimental for HTML). The element can only be the target of a pointer event when e.g. the mouse cursor is over the interior (i.e., 'fill') of the element and the fill property is set to a value other than none, or when the mouse cursor is over the perimeter (i.e., 'stroke') of the element and the stroke property is set to a value other than none. The value of the visibility property does not affect event processing.",
      },
      {
        name: 'fill',
        description:
          'SVG only. The element can only be the target of a pointer event when the pointer is over the interior (i.e., fill) of the element. The values of the fill and visibility properties do not affect event processing.',
      },
      {
        name: 'stroke',
        description:
          'SVG only. The element can only be the target of a pointer event when the pointer is over the perimeter (i.e., stroke) of the element. The values of the stroke and visibility properties do not affect event processing.',
      },
      {
        name: 'all',
        description:
          'SVG only (experimental for HTML). The element can only be the target of a pointer event when the pointer is over the interior (i.e., fill) or the perimeter (i.e., stroke) of the element. The values of the fill, stroke, and visibility properties do not affect event processing.',
      },
    ],
    tagHelper: {
      selector: 'p',
      properties: [
        {
          name: 'pointer-events',
          value: 'none',
        },
      ],
    },
  },
  {
    name: 'position',
    title: 'Position',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `position`,
    description:
      'The position CSS property sets how an element is positioned in a document. The top, right, bottom, and left properties determine the final location of positioned elements.',
    values: [
      {
        name: 'auto',
        description:
          'The element is positioned according to the normal flow of the document. The top, right, bottom, left, and z-index properties have no effect. This is the default value.',
      },
      {
        name: 'none',
        description:
          'The element is positioned according to the normal flow of the document, and then offset relative to itself based on the values of top, right, bottom, and left. The offset does not affect the position of any other elements; thus, the space given for the element in the page layout is the same as if position were static.',
      },
      {
        name: 'absolute',
        description:
          'The element is removed from the normal document flow, and no space is created for the element in the page layout. The element is positioned relative to its closest positioned ancestor (if any) or to the initial containing block. Its final position is determined by the values of top, right, bottom, and left.',
      },
      {
        name: 'fixed',
        description:
          'The element is removed from the normal document flow, and no space is created for the element in the page layout. The element is positioned relative to its initial containing block, which is the viewport in the case of visual media. Its final position is determined by the values of top, right, bottom, and left.',
      },
      {
        name: 'sticky',
        description:
          'The element is positioned according to the normal flow of the document, and then offset relative to its nearest scrolling ancestor and containing block (nearest block-level ancestor), including table-related elements, based on the values of top, right, bottom, and left. The offset does not affect the position of any other elements.',
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'position',
          value: 'absolute',
        },
      ],
    },
  },
  {
    name: 'print-color-adjust',
    title: 'Print Color Adjust',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `print-color-adjust`,
    description:
      "The print-color-adjust CSS property sets what, if anything, the user agent may do to optimize the appearance of the element on the output device. By default, the browser is allowed to make any adjustments to the element's appearance it determines to be necessary and prudent given the type and capabilities of the output device.",
    values: [
      {
        name: 'economy',
        description:
          "The user agent is allowed to make adjustments to the element as it deems appropriate and prudent in order to optimize the output for the device it's being rendered for. For example, when printing, a browser might opt to leave out all background images and to adjust text colors to be sure the contrast is optimized for reading on white paper. This is the default.",
      },
      {
        name: 'exact',
        description:
          "The element's content has been specifically and carefully crafted to use colors, images, and styles in a thoughtful and/or important way, such that being altered by the browser might actually make things worse rather than better. The appearance of the content should not be changed except by the user's request. For example, a page might include a list of information with rows whose background colors alternate between white and a light grey. Removing the background color would decrease the legibility of the content.",
      },
    ],
    tagHelper: {
      selector: 'body',
      properties: [
        {
          name: 'print-color-adjust',
          value: 'exact',
        },
      ],
    },
  },
  {
    name: 'quotes',
    title: 'Quotes',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `quotes`,
    description:
      'The quotes CSS property sets how the browser should render quotation marks that are added using the open-quotes or close-quotes values of the CSS content property.',
    values: [
      {
        name: 'none',
        description:
          'The open-quote and close-quote values of the content property produce no quotation marks.',
      },
      {
        name: 'auto',
        description:
          'Appropriate quote marks will be used for whatever language value is set on the selected elements (i.e. via the lang attribute).',
      },
      {
        name: 'string-values',
        description:
          'One or more pairs of <string> values for open-quote and close-quote. The first pair represents the outer level of quotation, the second pair is for the first nested level, next pair for third level and so on.',
      },
    ],
    tagHelper: {
      selector: 'section',
      properties: [
        {
          name: 'quotes',
          value: 'auto',
        },
      ],
    },
  },
  {
    name: 'resize',
    title: 'Resize',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `resize`,
    description:
      'The resize CSS property sets whether an element is resizable, and if so, in which directions.',
    values: [
      {
        name: 'none',
        description:
          'The element offers no user-controllable method for resizing it.',
      },
      {
        name: 'both',
        description:
          'The element displays a mechanism for allowing the user to resize it, which may be resized both horizontally and vertically.',
      },
      {
        name: 'horizontal',
        description:
          'The element displays a mechanism for allowing the user to resize it in the horizontal direction.',
      },
      {
        name: 'vertical',
        description:
          'The element displays a mechanism for allowing the user to resize it in the vertical direction.',
      },
    ],
    tagHelper: {
      selector: 'textarea',
      properties: [
        {
          name: 'resize',
          value: 'none',
        },
      ],
    },
  },
  {
    name: 'right',
    title: 'Right',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `right`,
    description:
      'The right CSS property participates in specifying the horizontal position of a positioned element. It has no effect on non-positioned elements.',
    values: [
      {
        name: 'auto',
        description:
          'Specifies that: for absolutely positioned elements, the position of the element is based on the left property, while width: auto is treated as a width based on the content; or if left is also auto, the element is positioned where it should horizontally be positioned if it were a static element. - for relatively positioned elements, the distance of the element from its normal position is based on the left property; or if left is also auto, the element is not moved horizontally at all.',
      },
      {
        name: 'inherit',
        description:
          'Specifies that the value is the same as the computed value from its parent element (which might not be its containing block). This computed value is then handled as if it were a <length>, <percentage>, or the auto keyword.',
      },
      {
        name: 'percentage-value',
        description: "A <percentage> of the containing block's width.",
      },
      {
        name: 'length-value',
        description:
          'A negative, null, or positive <length> that represents: for absolutely positioned elements, the distance to the right edge of the containing block - for relatively positioned elements, the distance that the element is moved to the left of its normal position.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'right',
          value: '10%',
        },
      ],
    },
  },
  {
    name: 'rotate',
    title: 'Rotate',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `rotate`,
    description:
      'The rotate CSS property allows you to specify rotation transforms individually and independently of the transform property. This maps better to typical user interface usage, and saves having to remember the exact order of transform functions to specify in the transform property.',
    values: [
      {
        name: 'angle-value',
        description:
          'An <angle> specifying the angle to rotate the affected element through, around the Z axis. Equivalent to a rotate() (2D rotation) function.',
      },
      {
        name: 'axis + angle-value',
        description:
          'The name of the axis you want to rotate the affected element around ("x", "y", or "z"), plus an <angle> specifying the angle to rotate the element through. Equivalent to a rotateX()/rotateY()/rotateZ() (3D rotation) function.',
      },
      {
        name: 'vector + angle-value',
        description:
          'Three <number>s representing an origin-centered vector that defines a line around which you want to rotate the element, plus an <angle> specifying the angle to rotate the element through. Equivalent to a rotate3d() (3D rotation) function.',
      },
      {
        name: 'none',
        description: 'Specifies that no rotation should be applied.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'right',
          value: '10%',
        },
      ],
    },
  },
  {
    name: 'row-gap',
    title: 'Row Gap',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `row-gap`,
    description:
      "The row-gap CSS property sets the size of the gap (gutter) between an element's rows.",
    values: [
      {
        name: 'length-value',
        description:
          'Is the width of the gutter separating the rows. <percentage> values are relative to the dimension of the element.',
      },
    ],
    tagHelper: {
      selector: 'section',
      properties: [
        {
          name: 'row-gap',
          value: '10px',
        },
      ],
    },
  },
  {
    name: 'ruby-position',
    title: 'Ruby Position',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `ruby-position`,
    description:
      'Is the width of the gutter separating the rows. <percentage> values are relative to the dimension of the element.',
    values: [
      {
        name: 'over',
        description:
          'Is a keyword indicating that the ruby has to be placed over the main text for horizontal scripts and right to it for vertical scripts.',
      },
      {
        name: 'under',
        description:
          'Is a keyword indicating that the ruby has to be placed under the main text for horizontal scripts and left to it for vertical scripts.',
      },
    ],
    tagHelper: {
      selector: 'section',
      properties: [
        {
          name: 'ruby',
          value: 'under',
        },
      ],
    },
  },
  {
    name: 'scale',
    title: 'Scale',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `scale`,
    description:
      'The scale CSS property allows you to specify scale transforms individually and independently of the transform property. This maps better to typical user interface usage, and saves having to remember the exact order of transform functions to specify in the transform value.',
    values: [
      {
        name: 'single-value',
        description:
          'A <number> or <percentage> specifying a scale factor to make the affected element scale by the same factor along both the X and Y axes. Equivalent to a scale() (2D scaling) function with a single value specified.',
      },
      {
        name: 'two-values',
        description:
          'Two <number> or <percentage> values that specify the X and Y axis scaling values (respectively) of a 2D scale. Equivalent to a scale() (2D scaling) function with two values specified.',
      },
      {
        name: 'three-values',
        description:
          'Three <number> or <percentage> values that specify the X, Y, and Z axis scaling values (respectively) of a 3D scale. Equivalent to a scale3d() (3D scaling) function.',
      },
    ],
    tagHelper: {
      selector: 'svg',
      properties: [
        {
          name: 'scale',
          value: '10',
        },
      ],
    },
  },
  {
    name: 'tab-size',
    title: 'Tab Size',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `tab-size`,
    description:
      'The tab-size CSS property is used to customize the width of tab characters (U+0009).',
    values: [
      {
        name: 'integer',
        description:
          'A multiple of the advance width of the space character (U+0020) to be used as the width of tabs. Must be nonnegative.',
      },
      {
        name: 'length-value',
        description: 'The width of tabs. Must be nonnegative.',
      },
    ],
    tagHelper: {
      selector: 'p',
      properties: [
        {
          name: 'tab-size',
          value: '4',
        },
      ],
    },
  },
  {
    name: 'table-layout',
    title: 'Table Layout',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `table-layout`,
    description:
      'The table-layout CSS property sets the algorithm used to lay out <table> cells, rows, and columns.',
    values: [
      {
        name: 'auto',
        description:
          'The automatic table layout algorithm is used. The widths of the table and its cells are adjusted to fit the content. Most browsers use this algorithm by default.',
      },
      {
        name: 'fixed',
        description:
          "The fixed table layout algorithm is used. When using this keyword, the table's width needs to be specified explicitly using the width property. If the value of the width property is set to autoor is not specified, the browser uses the automatic table layout algorithm, in which case the fixed value has no effect. In the fixed table layout algorithm, the width of each column is determined as follows: A column element with explicit width sets the width for that column, Otherwise, a cell in the first row with explicit width determines the width for that column, Otherwise, the column gets the width from the shared remaining horizontal space.",
      },
    ],
    tagHelper: {
      selector: 'table',
      properties: [
        {
          name: 'table-layout',
          value: 'auto',
        },
      ],
    },
  },
  {
    name: 'top',
    title: 'Top',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `top`,
    description:
      'The top CSS property participates in specifying the vertical position of a positioned element. It has no effect on non-positioned elements.',
    values: [
      {
        name: 'auto',
        description:
          'Specifies that: for absolutely positioned elements, the position of the element is based on the bottom property, while width: auto is treated as a width based on the content; or if bottom is also auto, the element is positioned where it should horizontally be positioned if it were a static element. - for relatively positioned elements, the distance of the element from its normal position is based on the bottom property; or if bottom is also auto, the element is not moved horizontally at all.',
      },
      {
        name: 'inherit',
        description:
          'Specifies that the value is the same as the computed value from its parent element (which might not be its containing block). This computed value is then handled as if it were a <length>, <percentage>, or the auto keyword.',
      },
      {
        name: 'percentage-value',
        description: "A <percentage> of the containing block's width.",
      },
      {
        name: 'length-value',
        description:
          'A negative, null, or positive <length> that represents: for absolutely positioned elements, the distance to the right edge of the containing block - for relatively positioned elements, the distance that the element is moved to the bottom of its normal position.',
      },
    ],
    tagHelper: {
      selector: 'a',
      properties: [
        {
          name: 'top',
          value: '0',
        },
      ],
    },
  },
  {
    name: 'touch-action',
    title: 'Touch Action',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `touch-action`,
    description:
      "The touch-action CSS property sets how an element's region can be manipulated by a touchscreen user (for example, by zooming features built into the browser). When a gesture is started, the browser intersects the touch-action values of the touched element and its ancestors, up to the one that implements the gesture (in other words, the first containing scrolling element). This means that in practice, touch-action is typically applied only to top-level elements which have some custom behavior, without needing to specify touch-action explicitly on any of that element's descendants.",
    values: [
      {
        name: 'auto',
        description:
          'Enable browser handling of all panning and zooming gestures.',
      },
      {
        name: 'none',
        description:
          'Disable browser handling of all panning and zooming gestures.',
      },
      {
        name: 'pan-x',
        description:
          'Enable single-finger horizontal panning gestures. May be combined with pan-y, pan-up, pan-down and/or pinch-zoom.',
      },
      {
        name: 'pan-y',
        description:
          'Enable single-finger vertical panning gestures. May be combined with pan-x, pan-left, pan-right and/or pinch-zoom.',
      },
      {
        name: 'manipulation',
        description:
          'Enable panning and pinch zoom gestures, but disable additional non-standard gestures such as double-tap to zoom. Disabling double-tap to zoom removes the need for browsers to delay the generation of click events when the user taps the screen. This is an alias for "pan-x pan-y pinch-zoom" (which, for compatibility, is itself still valid).',
      },
      {
        name: 'pinch-zoom',
        description:
          'Enable multi-finger panning and zooming of the page. This may be combined with any of the pan- values.',
      },
    ],
    tagHelper: {
      selector: 'img',
      properties: [
        {
          name: 'touch-action',
          value: 'pinch-zoom',
        },
      ],
    },
  },
  {
    name: 'translate',
    title: 'Translate',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `translate`,
    description:
      'The translate CSS property allows you to specify translation transforms individually and independently of the transform property. This maps better to typical user interface usage, and saves having to remember the exact order of transform functions to specify in the transform value.',
    values: [
      {
        name: 'length-value(s)',
        description:
          'A <length> or <percentage> that specifies a translation along the X-axis. Equivalent to a translate() (2D translation) function with a single value specified. Two <length> or <percentage> that specify the X and Y axis translation values (respectively) of a 2D translation. Equivalent to a translate() (2D translation) function with two values specified. Two <length-percentage> and single <length> values that specify the X, Y, and Z axis translation values (respectively) of a 3D translation. Equivalent to a translate3d() (3D translation) function.',
      },
      {
        name: 'none',
        description: 'Specifies that no translation should be applied.',
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'translate',
          value: '20px',
        },
      ],
    },
  },
  {
    name: 'unicode-bidi',
    title: 'Unicode Bidirectional',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `unicode-bidi`,
    description:
      'The unicode-bidi CSS property, together with the direction property, determines how bidirectional text in a document is handled. For example, if a block of content contains both left-to-right and right-to-left text, the user-agent uses a complex Unicode algorithm to decide how to display the text. The unicode-bidi property overrides this algorithm and allows the developer to control the text embedding.',
    values: [
      {
        name: 'normal',
        description:
          'The element does not offer an additional level of embedding with respect to the bidirectional algorithm. For inline elements, implicit reordering works across element boundaries.',
      },
      {
        name: 'embed',
        description:
          'If the element is inline, this value opens an additional level of embedding with respect to the bidirectional algorithm. The direction of this embedding level is given by the direction property.',
      },
      {
        name: 'bidi-override',
        description:
          'For inline elements this creates an override. For block container elements this creates an override for inline-level descendants not within another block container element. This means that inside the element, reordering is strictly in sequence according to the direction property; the implicit part of the bidirectional algorithm is ignored.',
      },
      {
        name: 'isolate',
        description:
          "This keyword indicates that the element's container directionality should be calculated without considering the content of this element. The element is therefore isolated from its siblings. When applying its bidirectional-resolution algorithm, its container element treats it as one or several U+FFFC Object Replacement Character, i.e. like an image.",
      },
      {
        name: 'isolate-override',
        description:
          'This keyword applies the isolation behavior of the isolate keyword to the surrounding content and the override behavior of the bidi-override keyword to the inner content.',
      },
      {
        name: 'plain-text',
        description:
          'This keyword makes the elements directionality calculated without considering its parent bidirectional state or the value of the direction property. The directionality is calculated using the P2 and P3 rules of the Unicode Bidirectional Algorithm. This value allows the display of data that is already formatted using a tool following the Unicode Bidirectional Algorithm.',
      },
    ],
    tagHelper: {
      selector: 'bdi',
      properties: [
        {
          name: 'unicode-bidi',
          value: 'embed',
        },
      ],
    },
  },
  {
    name: 'user-select',
    title: 'User Select',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `user-select`,
    description:
      'The unicode-bidi CSS property, together with the direction property, determines how bidirectional text in a document is handled. For example, if a block of content contains both left-to-right and right-to-left text, the user-agent uses a complex Unicode algorithm to decide how to display the text. The unicode-bidi property overrides this algorithm and allows the developer to control the text embedding.',
    values: [
      {
        name: 'none',
        description:
          'The text of the element and its sub-elements is not selectable. Note that the Selection object can contain these elements.',
      },
      {
        name: 'auto',
        description:
          'The used value of auto is determined as follows: On the ::before and ::after pseudo elements, the used value is none. If the element is an editable element, the used value is contain. Otherwise, if the used value of user-select on the parent of this element is all, the used value is all. Otherwise, if the used value of user-select on the parent of this element is none, the used value is none. Otherwise, the used value is text.',
      },
      {
        name: 'text',
        description: 'The text can be selected by the user.',
      },
      {
        name: 'all',
        description:
          'The content of the element shall be selected atomically: If a selection would contain part of the element, then the selection must contain the entire element including all its descendants. If a double-click or context-click occurred in sub-elements, the highest ancestor with this value will be selected.',
      },
      {
        name: 'contain',
        description:
          'Enables selection to start within the element; however, the selection will be contained by the bounds of that element.',
      },
    ],
    tagHelper: {
      selector: 'section',
      properties: [
        {
          name: 'user-select',
          value: 'none',
        },
      ],
    },
  },
  {
    name: 'vertical-align',
    title: 'Vertical Align',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `vertical-align`,
    description:
      'The vertical-align CSS property sets vertical alignment of an inline, inline-block or table-cell box. Depending on the element, values can have different outcomes.',
    values: [
      {
        name: 'baseline',
        description:
          'Aligns the baseline of the element with the baseline of its parent. The baseline of some replaced elements, like <textarea>, is not specified by the HTML specification, meaning that their behavior with this keyword may vary between browsers. FOR TABLE CELLS: Aligns the baseline of the cell with the baseline of all other cells in the row that are baseline-aligned.',
      },
      {
        name: 'sub',
        description:
          'Aligns the baseline of the element with the subscript-baseline of its parent. FOR TABLE CELLS: Aligns the baseline of the cell with the baseline of all other cells in the row that are baseline-aligned.',
      },
      {
        name: 'super',
        description:
          'Aligns the baseline of the element with the superscript-baseline of its parent. FOR TABLE CELLS: Aligns the baseline of the cell with the baseline of all other cells in the row that are baseline-aligned.',
      },
      {
        name: 'text-top',
        description:
          "Aligns the top of the element with the top of the parent element's font. FOR TABLE CELLS: Aligns the baseline of the cell with the baseline of all other cells in the row that are baseline-aligned.",
      },
      {
        name: 'text-bottom',
        description:
          "Aligns the bottom of the element with the bottom of the parent element's font. FOR TABLE CELLS: Aligns the baseline of the cell with the baseline of all other cells in the row that are baseline-aligned.",
      },
      {
        name: 'middle',
        description:
          'Aligns the middle of the element with the baseline plus half the x-height of the parent. FOR TABLE CELLS - Centers the padding box of the cell within the row.',
      },
      {
        name: 'length-value',
        description:
          'Aligns the baseline of the element to the given length above the baseline of its parent. A negative value is allowed. FOR TABLE CELLS: Aligns the baseline of the cell with the baseline of all other cells in the row that are baseline-aligned.',
      },
      {
        name: 'percent-value',
        description:
          'Aligns the baseline of the element to the given percentage above the baseline of its parent, with the value being a percentage of the line-height property. A negative value is allowed. FOR TABLE CELLS: Aligns the baseline of the cell with the baseline of all other cells in the row that are baseline-aligned.',
      },
      {
        name: 'top',
        description:
          'Aligns the top of the element and its descendants with the top of the entire line. FOR TABLE CELLS: Aligns the top padding edge of the cell with the top of the row.',
      },
      {
        name: 'bottom',
        description:
          'Aligns the bottom of the element and its descendants with the bottom of the entire line. FOR TABLE CELLS: Aligns the bottom padding edge of the cell with the bottom of the row.',
      },
    ],
    tagHelper: {
      selector: 'td',
      properties: [
        {
          name: 'vertical-align',
          value: 'top',
        },
      ],
    },
  },
  {
    name: 'visibility',
    title: 'Visibility',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `visibility`,
    description:
      'The visibility CSS property shows or hides an element without changing the layout of a document. The property can also hide rows or columns in a <table>.',
    values: [
      {
        name: 'visible',
        description: 'The element box is visible.',
      },
      {
        name: 'hidden',
        description:
          'The element box is invisible (not drawn), but still affects layout as normal. Descendants of the element will be visible if they have visibility set to visible. The element cannot receive focus (such as when navigating through tab indexes).',
      },
      {
        name: 'collapse',
        description:
          'For <table> rows, columns, column groups, and row groups, the row(s) or column(s) are hidden and the space they would have occupied is removed (as if display: none were applied to the column/row of the table). However, the size of other rows and columns is still calculated as though the cells in the collapsed row(s) or column(s) are present. This value allows for the fast removal of a row or column from a table without forcing the recalculation of widths and heights for the entire table. Collapsed flex items and ruby annotations are hidden, and the space they would have occupied is removed. For other elements, collapse is treated the same as hidden.',
      },
    ],
    tagHelper: {
      selector: 'li',
      properties: [
        {
          name: 'visibility',
          value: 'collapse',
        },
      ],
    },
  },
  {
    name: 'white-space',
    title: 'White Space',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `white-space`,
    description:
      'The white-space CSS property sets how white space inside an element is handled.',
    values: [
      {
        name: 'normal',
        description:
          'Sequences of white space are collapsed. Newline characters in the source are handled the same as other white space. Lines are broken as necessary to fill line boxes.',
      },
      {
        name: 'nowrap',
        description:
          'Collapses white space as for normal, but suppresses line breaks (text wrapping) within the source.',
      },
      {
        name: 'pre',
        description:
          'Sequences of white space are preserved. Lines are only broken at newline characters in the source and at <br> elements.',
      },
      {
        name: 'pre-wrap',
        description:
          'Sequences of white space are preserved. Lines are only broken at newline characters in the source and at <br> elements.',
      },
      {
        name: 'pre-line',
        description:
          'Sequences of white space are collapsed. Lines are broken at newline characters, at <br>, and as necessary to fill line boxes.',
      },
      {
        name: 'break-spaces',
        description:
          "The behavior is identical to that of pre-wrap, except that: Any sequence of preserved white space always takes up space, including at the end of the line. A line-breaking opportunity exists after every preserved white space character, including between white space characters. Such preserved spaces take up space and do not hang, thus affecting the box's intrinsic sizes (min-content size and max-content size).",
      },
    ],
    tagHelper: {
      selector: 'p',
      properties: [
        {
          name: 'white-space',
          value: 'break-spaces',
        },
      ],
    },
  },
  {
    name: 'widows',
    title: 'Widows',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `widows`,
    description:
      'The widows CSS property sets the minimum number of lines in a block container that must be shown at the top of a page, region, or column.',
    values: [
      {
        name: 'integer-value',
        description:
          'The minimum number of lines that can stay by themselves at the top of a new fragment after a fragmentation break. The value must be positive.',
      },
    ],
    tagHelper: {
      selector: 'h1',
      properties: [
        {
          name: 'widows',
          value: '3',
        },
      ],
    },
  },
  {
    name: 'width',
    title: 'Width',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `width`,
    description:
      "The width CSS property sets an element's width. By default, it sets the width of the content area, but if box-sizing is set to border-box, it sets the width of the border area.",
    values: [
      {
        name: 'length-value',
        description: 'Defines the width as a distance value.',
      },
      {
        name: 'percentage-value',
        description: `Defines the width as a percentage of the containing block's width.`,
      },
      {
        name: 'auto',
        description:
          'The browser will calculate and select a width for the specified element.',
      },
      {
        name: 'max-content',
        description: 'The intrinsic preferred width.',
      },
      {
        name: 'min-content',
        description: 'The intrinsic minimum width.',
      },
      {
        name: 'fit-content',
        description:
          'Box will use the available space, but never more than max-content.',
      },
    ],
    tagHelper: {
      selector: 'h1',
      properties: [
        {
          name: 'widows',
          value: '3',
        },
      ],
    },
  },
  {
    name: 'word-break',
    title: 'Word Break',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `word-break`,
    description:
      'The word-break CSS property sets whether line breaks appear wherever the text would otherwise overflow its content box.',
    values: [
      {
        name: 'normal',
        description: 'Use the default line break rule.',
      },
      {
        name: 'break-all',
        description: `To prevent overflow, word breaks should be inserted between any two characters (excluding Chinese/Japanese/Korean text).`,
      },
      {
        name: 'keep-all',
        description:
          'Word breaks should not be used for Chinese/Japanese/Korean (CJK) text. Non-CJK text behavior is the same as for normal.',
      },
    ],
    tagHelper: {
      selector: 'p',
      properties: [
        {
          name: 'word-break',
          value: 'normal',
        },
      ],
    },
  },
  {
    name: 'word-spacing',
    title: 'Word Spacing',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `word-spacing`,
    description:
      'The word-spacing CSS property sets the length of space between words and between tags.',
    values: [
      {
        name: 'normal',
        description:
          'The normal inter-word spacing, as defined by the current font and/or the browser.',
      },
      {
        name: 'length-value',
        description: `Specifies extra spacing in addition to the intrinsic inter-word spacing defined by the font.`,
      },
    ],
    tagHelper: {
      selector: 'b',
      properties: [
        {
          name: 'word-spacing',
          value: '2rem',
        },
      ],
    },
  },
  {
    name: 'writing-mode',
    title: 'Writing Mode',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `writing-mode`,
    description:
      'The writing-mode CSS property sets whether lines of text are laid out horizontally or vertically, as well as the direction in which blocks progress. When set for an entire document, it should be set on the root element (html element for HTML documents).',
    values: [
      {
        name: 'horizontal-tb',
        description:
          'For ltr scripts, content flows horizontally from left to right. For rtl scripts, content flows horizontally from right to left. The next horizontal line is positioned below the previous line.',
      },
      {
        name: 'vertical-rl',
        description: `For ltr scripts, content flows vertically from top to bottom, and the next vertical line is positioned to the left of the previous line. For rtl scripts, content flows vertically from bottom to top, and the next vertical line is positioned to the right of the previous line.`,
      },
    ],
    tagHelper: {
      selector: 'span',
      properties: [
        {
          name: 'writing-mode',
          value: 'vertical-lr',
        },
      ],
    },
  },
  {
    name: 'z-index',
    title: 'Z Index',
    link: '/infobook/css',
    linkHelper: 'Properties',
    listDisplay: `z-index`,
    description:
      'The z-index CSS property sets the z-order of a positioned element and its descendants or flex items. Overlapping elements with a larger z-index cover those with a smaller one.',
    values: [
      {
        name: 'auto',
        description:
          'The box does not establish a new local stacking context. The stack level of the generated box in the current stacking context is 0.',
      },
      {
        name: 'integer-value',
        description: `This <integer> is the stack level of the generated box in the current stacking context. The box also establishes a local stacking context. This means that the z-indexes of descendants are not compared to the z-indexes of elements outside this element.`,
      },
    ],
    tagHelper: {
      selector: 'div',
      properties: [
        {
          name: 'z-index',
          value: '1000',
        },
      ],
    },
  },
]?.sort((a, b) => {
  const titleA = a.name.toUpperCase();
  const titleB = b.name.toUpperCase();

  if (titleA < titleB) {
    return -1;
  }

  if (titleA > titleB) {
    return 1;
  }

  return 0;
});
