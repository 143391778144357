import React from 'react';

import Tooltip from 'components/tooltip';
import IconComponent from 'components/icon';

const RegularTag = ({ handleCopy, tagName, tagText }) => (
  <Tooltip content="Clip to copy code to your clipboard!">
    <div
      className="implementation-container"
      onClick={() => handleCopy('#markup')}
    >
      <div id="markup" className={`markup tag ${tagName}-tag`}>
        <div className="line">
          <span className="tag-name">{`<${tagName}>`}</span>
          <span className="tag-text"> {tagText}</span>
          <span className="tag-name">{`</${tagName}>`}</span>
        </div>
      </div>
      <div className="tag-copy">
        <IconComponent iconName="IoClipboardOutline" size="30px" />
      </div>
    </div>
  </Tooltip>
);

export default RegularTag;
