import React from 'react';

import Tooltip from 'components/tooltip';
import IconComponent from 'components/icon';

const SingleTagWithAttribute = ({ handleCopy, attributes, tagName }) => (
  <Tooltip content="Clip to copy code to your clipboard!">
    <div
      className="implementation-container"
      onClick={() => handleCopy('#markup-with-attribute')}
    >
      <section
        id="markup-with-attribute"
        className="markup-with-attributes"
      >
        <div className="line">
          <span className="tag-name">{`<${tagName} `}</span>
          <span className="tag-attribute">{attributes?.[0]?.name}</span>
          {attributes?.[0]?.value && (
            <>
              <span className="tag-attribute">=</span>
              <span className="tag-attribute-value">
                {attributes?.[0]?.value}
              </span>
            </>
          )}
          <span className="tag-name">{`>`}</span>
        </div>
      </section>
      <div className="tag-copy">
        <IconComponent iconName="IoClipboardOutline" size="30px" />
      </div>
    </div>
  </Tooltip>
);

export default SingleTagWithAttribute;
