import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import GlobalContext from 'GlobalContext';

import { getTag } from 'helpers/getTag';

import Text from 'components/text';
import Button from 'components/button';

import PropertyValues from './PropertyValues';
import PropertyDescription from './PropertyDescription';
import PropertyBasicExample from './PropertyBasicExample';

import './PropertyDetailModalView.scss';

const PropertyDetailModalView = ({ property }) => {
  const navigate = useNavigate();

  const { setShowDetailModal, setCurrentTag } = useContext(GlobalContext);

  const handleTagButtonClick = (tag) => {
    const fullTag = getTag(tag);

    setCurrentTag(fullTag);
    localStorage.setItem('htmlTab', fullTag?.linkHelper);

    setShowDetailModal(false);
    navigate(fullTag?.link);
    setShowDetailModal(true);
  };

  return (
    <section className="property-modal">
      <PropertyDescription property={property} />
      <PropertyBasicExample property={property} />
      {property?.tags?.length && (
        <section>
          <Text
            text="Tags"
            fontSize="18px"
            textAlign="left"
            fontWeight="bold"
          />
          <div className="tag-button-container">
            {property?.tags?.map((tag) => (
              <div key={tag} className="tag-button">
                <Button
                  buttonText={tag}
                  handleClick={() => handleTagButtonClick(tag, property)}
                />
              </div>
            ))}
          </div>
        </section>
      )}
      {property?.values && <PropertyValues values={property?.values} />}
      {property?.note && (
        <div>
          <Text
            text="Note"
            fontSize="18px"
            margin="40px 0 20px 0"
            textAlign="left"
            fontWeight="bold"
          />
          <Text
            margin="0"
            lineHeight={2}
            textAlign="left"
            text={property?.note}
          />
        </div>
      )}
    </section>
  );
};

export default PropertyDetailModalView;
