import React, { useState, useEffect } from 'react';

import Container from 'components/container';
import Search from 'components/search';

import TableRow from './TableRow';

import './index.scss';

const ListTable = ({
  width,
  dataList,
  itemDescriptor,
  handleRowClick,
  searchPlaceholder,
}) => {
  const [searchString, setSearchString] = useState('');
  const [filteredDataList, setFilteredDataList] = useState([]);

  const displayedList = searchString?.length ? filteredDataList : dataList;

  useEffect(() => {
    if (searchString?.length) {
      const formattedSearchString = searchString.toLowerCase();

      const newFilteredData = dataList?.filter(
        (entry) =>
          entry?.title?.toLowerCase()?.includes(formattedSearchString) ||
          entry?.name?.toLowerCase()?.includes(formattedSearchString) ||
          entry?.title?.toLowerCase() === formattedSearchString ||
          entry?.name?.toLowerCase() === formattedSearchString
      );

      return setFilteredDataList(newFilteredData);
    }

    if (searchString === '') {
      return setFilteredDataList(dataList);
    }
  }, [searchString]);

  return (
    <Container>
      <Search
        searchString={searchString}
        placeholder={searchPlaceholder}
        setSearchString={setSearchString}
      />
      <div className="list-table">
        {displayedList?.map((item) => (
          <TableRow
            entry={item}
            itemDescriptor={itemDescriptor}
            handleRowClick={handleRowClick}
            key={item?.title || item?.name}
          />
        ))}
      </div>
    </Container>
  );
};
export default ListTable;
