import React, { useState, useEffect } from 'react';

import { aboutCss } from 'data/css/about.js';
import { learnCss } from 'data/css/learn.js';

import { aboutData } from 'data/html/about';
import { learnData } from 'data/html/learn';
import { updatedTags } from 'data/html/tags';

import Card from 'components/card';
import Modal from 'components/modal';
import Search from 'components/search';
import Container from 'components/container';

import InfobookIconContainer from './components/icons';
import InfobookSearchResults from './components/search';
import InfobookReleaseNotes from './components/release-notes';
import ReleaseNotesButton from './components/release-notes/ReleaseNotesButton';

const defaultData = [
  ...updatedTags,
  ...learnData?.items,
  ...aboutData?.items,
  ...aboutCss?.items,
  ...learnCss?.items,
];

const InfobookView = () => {
  const [showNotes, setShowNotes] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchString, setSearchString] = useState('');

  const toggleShowNotes = () => setShowNotes(!showNotes);

  useEffect(() => {
    if (searchString?.length) {
      setShowNotes(false);

      const formattedSearchString = searchString.toLowerCase();

      const newFilteredData = defaultData?.filter(
        (entry) =>
          entry?.title?.toLowerCase()?.includes(formattedSearchString) &&
          !entry?.title?.toLowerCase().includes('tag example')
      );

      return setFilteredData(newFilteredData);
    }

    return setFilteredData(defaultData);
  }, [searchString]);

  return (
    <Container>
      <Card
        headerText="Infobook"
        icon={<ReleaseNotesButton handleClick={toggleShowNotes} />}
      >
        <InfobookIconContainer />
        <Search
          searchString={searchString}
          setSearchString={setSearchString}
          placeholder="Search a web development topic"
        />
      </Card>
      {searchString && (
        <Card headerText="Search">
          <InfobookSearchResults
            results={filteredData}
            searchString={searchString}
          />
        </Card>
      )}
      <Modal
        showModal={showNotes}
        closeModal={setShowNotes}
        headerText="Release Info"
      >
        <InfobookReleaseNotes />
      </Modal>
    </Container>
  );
};

export default InfobookView;
