import React from 'react';
import {
  IoCopy,
  IoPrism,
  IoSearch,
  IoAlbums,
  IoLogoCss3,
  IoLogoHtml5,
  IoClipboard,
  IoLogoJavascript,
  IoClipboardOutline,
  IoCloseOutline,
  IoAlertCircleOutline,
  IoReturnUpBackOutline,
} from 'react-icons/io5';

const icons = {
  IoCopy,
  IoPrism,
  IoSearch,
  IoAlbums,
  IoLogoCss3,
  IoLogoHtml5,
  IoClipboard,
  IoCloseOutline,
  IoLogoJavascript,
  IoClipboardOutline,
  IoAlertCircleOutline,
  IoReturnUpBackOutline,
};

const IconComponent = ({
  iconName,
  restOfProps,
  size = '30px',
  color = '#26e07f',
}) => {
  const Icon = icons[iconName];

  if (Icon) {
    return <Icon color={color} size={size} {...restOfProps} />;
  }
};

export default IconComponent;
