import React from 'react';

import Text from 'components/text';
import Container from 'components/container';
import CssImplementation from 'components/css-implementation';

const PropertyBasicExample = ({ property }) => {
  let displayedProperty = [
    { name: property?.name, value: property?.tagHelper?.propertyValue },
  ];

  return (
    <Container>
      <Text
        margin="0"
        width="100%"
        fontSize="18px"
        textAlign="left"
        fontWeight="bold"
        text="Basic Example"
      />
      <Text
        width="100%"
        textAlign="left"
        margin="20px 0"
        text="Below is a basic example of the property."
      />
      <CssImplementation
        idForCopy="css-rule-example"
        properties={property?.tagHelper?.properties}
        selectorName={property?.tagHelper?.selector}
      />
    </Container>
  );
};

export default PropertyBasicExample;
