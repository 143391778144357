export const aboutData = {
  section: 'About',
  items: [
    {
      title: 'Hyper Text Markup Language',
      value:
        'HTML (Hypertext Markup Language) is a standard markup language used for creating the structure and presentation of web pages. It forms the backbone of almost every webpage on the Internet. ',
      link: '/infobook/html',
      linkHelper: 'About',
    },
    {
      title: 'HyperText',
      value:
        'HyperText refers to links that connect web pages, either within a single website or between multiple sites.',
      link: '/infobook/html',
      linkHelper: 'About',
    },
    {
      title: 'Markup',
      value:
        'Markup is used to create elements for text, images, and other content for display.',
      link: '/infobook/html',
      linkHelper: 'About',
    },
    {
      title: 'Elements',
      value:
        'Elements are differentiated from regular text in a file with what is referred to as Tags.',
      link: '/infobook/html',
      linkHelper: 'About',
    },
    {
      title: 'Tags',
      value:
        "Tags are case insensitive and consist of the specific element's name enclosed by carrot brackets.",
      link: '/infobook/html',
      linkHelper: 'About',
    },
    {
      title: 'Attributes',
      value:
        'Tags have attributes that can be changed to affect the functionality or appearance of the element.',
      link: '/infobook/html',
      linkHelper: 'About',
    },
  ],
};
