import React from 'react';

import './index.scss';

const Container = ({
  flex,
  width,
  height,
  margin,
  children,
  handleClick,
  restOfProps,
  backgroundColor
}) => {
  return (
    <div
      onClick={handleClick}
      className={flex ? 'flex-container' : 'container'}
      style={{
        width,
        height,
        margin,
        backgroundColor
      }}
      {...restOfProps}
    >
      {children}
    </div>
  );
};

export default Container;
