import React, { useContext } from 'react';

import { updatedTags } from 'data/html/tags';

import GlobalContext from 'GlobalContext';

import Modal from 'components/modal';
import Container from 'components/container';
import ListTable from 'components/list-table';
import HeaderText from 'components/header-text';

import TagDetailModalContent from './TagDetailModalView';

const TagsTab = () => {
  const {
    currentTag,
    setCurrentTag,
    showDetailModal,
    setShowDetailModal,
  } = useContext(GlobalContext);

  const handleRowClick = (tag) => {
    setCurrentTag(tag);
    setShowDetailModal(true);
  };

  return (
    <Container>
      <HeaderText
        fontSize="30px"
        text="HTML Tags"
        fontWeight="bold"
        margin="0 auto 40px auto"
      />
      <ListTable
        itemDescriptor="tag"
        dataList={updatedTags}
        handleRowClick={handleRowClick}
        searchPlaceholder="Search HTML tags"
      />
      <Modal
        showModal={showDetailModal}
        closeModal={setShowDetailModal}
        headerText={`${currentTag?.title} Tag`}
      >
        <TagDetailModalContent tag={currentTag} />
      </Modal>
    </Container>
  );
};

export default TagsTab;
