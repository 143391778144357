import React, { useContext } from 'react';

import { getAttribute } from 'data/html/attributes';

import GlobalContext from 'GlobalContext';

import Text from 'components/text';

import TagAttributes from './TagAttributes';
import TagDescription from './TagDescription';
import TagBasicExample from './TagBasicExample';

import './TagDetailModalView.scss';

const TagDetailModal = ({ tag }) => {
  const { setShowDetailModal, setHtmlViewTab, setCurrentAttribute } =
    useContext(GlobalContext);

  const handleAttributeClick = (attribute) => {
    const fullAttribute = getAttribute(attribute);

    setShowDetailModal(false);
    setHtmlViewTab('Attributes');
    setCurrentAttribute(fullAttribute);
    setShowDetailModal(true);
  };

  return (
    <section className="tag-modal">
      <TagDescription tag={tag} />
      <TagBasicExample tag={tag} />
      <TagAttributes
        tag={tag}
        handleAttributeClick={handleAttributeClick}
      />
      {tag?.note && (
        <div>
          <Text
            text="Note"
            fontSize="18px"
            textAlign="left"
            fontWeight="bold"
            margin="40px 0 10px 0"
          />
          <Text
            margin="0"
            lineHeight={2}
            textAlign="left"
            text={tag?.note}
          />
        </div>
      )}
    </section>
  );
};

export default TagDetailModal;
