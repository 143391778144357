import React from 'react';

import Text from 'components/text';
import Container from 'components/container';
import TagImplementation from 'components/tag-implementation';

const SelfClosingTagExample = () => (
  <Container width="80%" margin="0 auto">
    <Text
      width="100%"
      lineHeight={2}
      margin="0 auto"
      textAlign="left"
      fontWeight="bold"
      text="Self Closing Tag Example"
    />
    <Text
      width="100%"
      lineHeight={2}
      textAlign="left"
      text="The Break tag represents a line break in the page."
      margin="0"
    />
    <TagImplementation tagName="br" tagType="self" />
  </Container>
);

export default SelfClosingTagExample;
