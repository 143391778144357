import React from 'react';
import { NavLink } from 'react-router-dom';

import HeaderLogo from './HeaderLogo';

const LogoLink = () => (
  <NavLink to="/" className="header-logo">
    <HeaderLogo />
  </NavLink>
);

export default LogoLink;
