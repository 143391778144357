import React, { useContext } from 'react';

import { cssProperties } from 'data/css/properties';

import GlobalContext from 'GlobalContext';

import Modal from 'components/modal';
import Container from 'components/container';
import ListTable from 'components/list-table';
import HeaderText from 'components/header-text';

import PropertyDetailModalView from './PropertyDetailModalView';

const PropertiesTab = () => {
  const {
    showDetailModal,
    currentProperty,
    setShowDetailModal,
    setCurrentProperty,
  } = useContext(GlobalContext);

  const handleRowClick = (property) => {
    setCurrentProperty(property);
    setShowDetailModal(true);
  };

  return (
    <Container>
      <HeaderText
        fontSize="30px"
        fontWeight="bold"
        text="CSS Properties"
        margin="0 auto 40px auto"
      />
      <ListTable
        width="100%"
        dataList={cssProperties}
        itemDescriptor="property"
        handleRowClick={handleRowClick}
        searchPlaceholder="Search CSS properties"
      />
      {
        <Modal
          showModal={showDetailModal}
          closeModal={setShowDetailModal}
          headerText={`${currentProperty?.title} Property`}
        >
          <PropertyDetailModalView property={currentProperty} />
        </Modal>
      }
    </Container>
  );
};

export default PropertiesTab;
