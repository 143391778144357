import React from 'react';

import Text from 'components/text';
import HeaderText from 'components/header-text';

import RuleExample from './RuleExample';
import RuleExampleId from './RuleExampleId';
import RuleExampleClass from './RuleExampleClass';
import RuleExamplePseudo from './RuleExamplePseudo';
import RuleExampleAttribute from './RuleExampleAttribute';

import '../../index.scss';

const SelectorsTab = () => {
  return (
    <div className="tab-view">
      <HeaderText
        fontSize="30px"
        fontWeight="bold"
        margin="0 0 40px 0"
        text="CSS Selectors"
      />
      <div className="inner-tab-content">
        <Text
          width="80%"
          lineHeight={2}
          margin="0 auto"
          fontSize="18px"
          textAlign="left"
          text="Selectors"
          fontWeight="bold"
        />
        <Text
          width="80%"
          lineHeight={2}
          textAlign="left"
          text="There are a few ways to target elements for styling. Below are examples of each type of selector available."
          margin="0 auto 40px auto"
        />
        <RuleExample />
        <RuleExampleId />
        <RuleExampleClass />
        <RuleExampleAttribute />
        <RuleExamplePseudo />
      </div>
    </div>
  );
};

export default SelectorsTab;
