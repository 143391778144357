import React from 'react';

import Text from 'components/text';
import Container from 'components/container';

const TagAttributes = ({ tag, handleAttributeClick }) => {
  return (
    <Container>
      <Text
        width="100%"
        fontSize="18px"
        textAlign="left"
        fontWeight="bold"
        text="Attributes"
        margin="20px 0 10px 0"
      />
      <section className="modal-attribute-table">
        {tag?.attributes ? (
          tag?.attributes?.map((attribute) => (
            <div
              key={attribute?.name}
              className="modal-attribute-table-row"
              onClick={() => handleAttributeClick(attribute)}
            >
              <Text
                width="10%"
                textAlign="left"
                fontWeight="bold"
                margin="0 20px 10px 0"
                text={attribute?.name}
                fontSize={attribute.type === 'big' ? '12px' : '16px'}
              />
              <Text
                width="70%"
                fontSize="14px"
                textAlign="left"
                lineHeight={1.5}
                margin="0 auto 0 20px"
                text={attribute?.description}
              />
            </div>
          ))
        ) : (
          <Text
            textAlign="left"
            margin="10px 0 0 0"
            text="This tag only support global attributes."
          />
        )}
      </section>
    </Container>
  );
};

export default TagAttributes;
