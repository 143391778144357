import React from 'react';

import Button from 'components/button';

const CloseReleaseInfoButton = ({ handleClick }) => (
  <div className="close-release-info-button">
    <Button
      type="secondary"
      buttonText="close"
      handleClick={handleClick}
    />
  </div>
);

export default CloseReleaseInfoButton;
