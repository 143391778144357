export const learnCss = {
  section: 'Learn',
  items: [
    {
      title: 'CSS Workspace',
      value:
        'Just like with HTML, you will need an IDE (integrated development environment) or a code editor to work with CSS. If you do not already have one installed (or are not sure which one to install) you can check out our Resources page for our recommendations.',
      link: '/infobook/css',
      linkHelper: 'Learn',
    },
    {
      title: 'CSS File Naming',
      value:
        'The system needs to know this document is CSS and not a regular text document. To do this, end the file name with .css when saving. Examples: index.css or myFile.css.',
      link: '/infobook/css',
      linkHelper: 'Learn',
    },
    {
      title: 'CSS Rule Structure',
      value: `CSS is made up of rules which are defined by "selectors" with "property-value pairs" enclosed in curly brackets. Selectors define the HTML target for styling. This could an element's type, name, class, attribute, or id. Property-value pairs are used change the look and behavior of the elements.`,
      link: '/infobook/css',
      linkHelper: 'Learn',
    },
  ],
};
