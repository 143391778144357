import React from 'react';

const Text = ({
  width,
  margin,
  fontFamily,
  textDecoration,
  color = '#222',
  fontSize = '16px',
  lineHeight = '1em',
  textAlign = 'center',
  text = 'example text',
  fontWeight = 'normal',
  letterSpacing = '1px',
  restOfProps,
}) => (
  <p
    style={{
      color,
      width,
      margin,
      fontSize,
      textAlign,
      fontWeight,
      fontFamily,
      lineHeight,
      letterSpacing,
      textDecoration,
    }}
    {...restOfProps}
  >
    {text}
  </p>
);

export default Text;
