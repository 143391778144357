import React from 'react';

import Text from 'components/text';
import Container from 'components/container';
import CssImplementation from 'components/css-implementation';

const RuleExample = () => (
  <Container width="80%" margin="20px auto 40px auto">
    <Text
      width="100%"
      fontSize="18px"
      textAlign="left"
      fontWeight="bold"
      margin="0 auto 10px 0"
      text="CSS Type Rule Example"
    />
    <Text
      lineHeight="2"
      textAlign="left"
      margin="0 auto 10px 0"
      text="Here we are targeting all h1 elements and giving them a red color."
    />
    <CssImplementation
      selectorName="h1"
      idForCopy="css-rule-example"
      properties={[{ name: 'color', value: '"red"' }]}
    />
  </Container>
);

export default RuleExample;
