import React from 'react';
import { useNavigate } from 'react-router-dom';

import Tooltip from 'components/tooltip';

import InfobookIcon from './InfobookIcon';

const IconColumnOne = () => {
  const navigate = useNavigate();

  const makeButtonNavigate = (route) => {
    navigate(route);
  };

  return (
    <div className="icon-column">
      <Tooltip content="Click to learn HTML">
        <InfobookIcon
          itemText="HTML"
          iconName="IoLogoHtml5"
          onClick={() => makeButtonNavigate('/infobook/html')}
        />
      </Tooltip>
      <Tooltip content="Click to learn CSS">
        <InfobookIcon
          itemText="CSS"
          iconName="IoLogoCss3"
          onClick={() => makeButtonNavigate('/infobook/css')}
        />
      </Tooltip>
      <InfobookIcon
        disabled={true}
        itemText="JavaScript"
        iconName="IoLogoJavascript"
        onClick={() => makeButtonNavigate('/infobook/javascript')}
      />
      {/* <Tooltip content="Click to learn JavaScript">
        <InfobookIcon
          itemText="JavaScript"
          iconName="IoLogoJavascript"
          onClick={() => makeButtonNavigate('/infobook/javascript')}
        />
      </Tooltip> */}
    </div>
  );
};

export default IconColumnOne;
