import React, { useContext } from 'react';

import { attributes } from 'data/html/attributes';

import GlobalContext from 'GlobalContext';

import Text from 'components/text';
import Modal from 'components/modal';
import Container from 'components/container';
import ListTable from 'components/list-table';

import AttributeDetailModalView from './AttributeDetailModalView';

const AttributesTab = () => {
  const {
    showDetailModal,
    setShowDetailModal,
    currentAttribute,
    setCurrentAttribute,
  } = useContext(GlobalContext);

  const handleRowClick = (attribute) => {
    setCurrentAttribute(attribute);
    setShowDetailModal(true);
  };

  return (
    <Container>
      <Text
        fontSize="30px"
        fontWeight="bold"
        margin="0 0 40px 0"
        text="HTML Attributes"
      />
      <ListTable
        dataList={attributes}
        itemDescriptor="attribute"
        handleRowClick={handleRowClick}
        searchPlaceholder="Search HTML attributes"
      />
      <Modal
        showModal={showDetailModal}
        closeModal={setShowDetailModal}
        headerText={`${currentAttribute?.title} Attribute`}
      >
        <AttributeDetailModalView attribute={currentAttribute} />
      </Modal>
    </Container>
  );
};

export default AttributesTab;
