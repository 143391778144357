import React from 'react';

import { learnData } from 'data/html/learn';

import DescriptionEntry from 'components/description-entry';

const WorkSpaceSetup = () => {
  const item = learnData?.items.find(
    (item) => item.title === 'HTML Workspace'
  );

  return <DescriptionEntry entry={item} />;
};
export default WorkSpaceSetup;
