import React from 'react';

import InfobookIcon from './InfobookIcon';

const IconColumnTwo = () => (
  <div className="icon-column">
    <InfobookIcon iconName="IoCopy" itemText="Templates" disabled />
    <InfobookIcon iconName="IoAlbums" itemText="Resources" disabled />
    <div style={{ height: '60px' }}></div>
  </div>
);

export default IconColumnTwo;
