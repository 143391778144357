import React, { useContext } from 'react';

import GlobalContext from 'GlobalContext';

import SingleTag from './SingleTag';
import RegularTag from './RegularTag';
import RegularTagWithAttribute from './RegularTagWithAttribute';
import SingleTagWithAttribute from './SingleTagWithAttribute';
import SingleTagWithAttributes from './SingleTagWithAttributes';
import RegularTagWithAttributes from './RegularTagWithAttributes';

import './index.scss';

const TagImplementation = ({ attributes, tagType, tagName, tagText }) => {
  const {
    setShowNotification,
    setNotificationText,
    setNotificationStatus,
  } = useContext(GlobalContext);

  const copyToClipboard = async (element) => {
    const copyText = document.querySelector(element).innerText;
    await navigator.clipboard.writeText(copyText);
    setNotificationText('Code copied to clipboard!');
    setNotificationStatus('success');
    setShowNotification(true);
  };

  if (tagType === 'self') {
    if (attributes?.length > 1) {
      return (
        <SingleTagWithAttributes
          tagName={tagName}
          tagText={tagText}
          attributes={attributes}
          handleCopy={copyToClipboard}
        />
      );
    }

    if (attributes?.length === 1) {
      return (
        <SingleTagWithAttribute
          tagName={tagName}
          attributes={attributes}
          handleCopy={copyToClipboard}
        />
      );
    }

    return <SingleTag tagName={tagName} />;
  }

  if (attributes?.length > 1) {
    return (
      <RegularTagWithAttributes
        tagName={tagName}
        tagText={tagText}
        attributes={attributes}
        handleCopy={copyToClipboard}
      />
    );
  }

  if (attributes?.length === 1) {
    return (
      <RegularTagWithAttribute
        tagName={tagName}
        tagText={tagText}
        attributes={attributes}
        handleCopy={copyToClipboard}
      />
    );
  }

  return (
    <RegularTag
      tagName={tagName}
      tagText={tagText}
      handleCopy={copyToClipboard}
    />
  );
};

export default TagImplementation;
