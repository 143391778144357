import React from 'react';

import { learnData } from 'data/html/learn';

import DescriptionEntry from 'components/description-entry';

const TagStructure = () => {
  const item = learnData?.items.find(
    (item) => item.title === 'HTML Tag Structure'
  );

  return <DescriptionEntry entry={item} />;
};

export default TagStructure;
