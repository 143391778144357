import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import GlobalContext from 'GlobalContext';

import Text from 'components/text';
import Container from 'components/container';

import InfobookLoader from './InfobookLoader';
import DisplayedSearchTerm from './DisplayedSearchTerm';
import DisplayedResultCounter from './DisplayedResultCounter';
import DisplayedSearchResults from './DisplayedSearchResults';

import './index.scss';

const InfobookSearchResults = ({ searchString, results }) => {
  const navigate = useNavigate();
  const { setHtmlViewTab, setCssViewTab } = useContext(GlobalContext);

  const makeButtonNavigate = (entry) => {
    if (entry?.link.includes('html')) {
      localStorage.setItem('htmlTab', entry?.linkHelper);
      setHtmlViewTab(entry?.linkHelper);
    }

    if (entry?.link.includes('css')) {
      localStorage.setItem('cssTab', entry?.linkHelper);
      setCssViewTab(entry?.linkHelper);
    }

    navigate(entry?.link);
  };

  if (!searchString?.length) {
    return;
  }

  if (searchString?.length < 2) {
    return <InfobookLoader searchString={searchString} />;
  }

  return (
    <div className="search-results-container">
      <div className="displayed-search-term-row">
        <Container flex>
          <Text fontSize="18px" text="Your search:" margin="20px 0" />
          <DisplayedSearchTerm
            results={results}
            searchString={searchString}
          />
        </Container>
        <DisplayedResultCounter results={results} />
      </div>
      <DisplayedSearchResults
        results={results}
        searchString={searchString}
        makeButtonNavigate={makeButtonNavigate}
      />
    </div>
  );
};

export default InfobookSearchResults;
