import React from 'react';

import './index.scss';

const Spinner = ({ width, margin }) => {
  return (
    <div className="spinner" style={{ margin, width, height: width }} />
  );
};

export default Spinner;
