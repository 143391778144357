import React from 'react';

import Text from 'components/text';

import './index.scss';

const Card = ({
  icon,
  children,
  type = '',
  restOfProps,
  headerText = 'example header text',
}) => (
  <section
    {...restOfProps}
    className={type === '' ? 'card' : 'card-static'}
  >
    <div className="card-header">
      <div />
      <Text text={headerText} color="#fff" fontSize="32px" margin="0" />
      {icon && icon}
    </div>
    {children}
  </section>
);

export default Card;
