import React from 'react';

import IconComponent from 'components/icon';

import './index.scss';

const Modal = ({ headerText, closeModal, showModal, children }) => {
  if (!showModal) return;

  return (
    <div className="modal-container">
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-close" onClick={() => closeModal()}>
            <IconComponent iconName="IoCloseOutline" color="#222" />
          </div>
          <h1 className="modal-header-text">{headerText}</h1>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
