import IconColumnOne from './IconColumnOne';
import IconColumnTwo from './IconColumnTwo';

import './index.scss';

const InfobookIconContainer = () => (
  <div className="infobook-icon-container">
    <IconColumnOne />
    <IconColumnTwo />
  </div>
);

export default InfobookIconContainer;
