import React from 'react';
import { NavLink } from 'react-router-dom';

const Menu = () => (
  <div className="menu">
    <NavLink
      to="/Infobook"
      className={({ isActive }) =>
        isActive ? 'nav-link-active' : 'nav-link'
      }
    >
      Infobook
    </NavLink>
    <NavLink
      to="/about"
      className={({ isActive }) =>
        isActive ? 'nav-link-active' : 'nav-link'
      }
    >
      About Us
    </NavLink>
  </div>
);

export default Menu;
