const removeKeys = (array) => {
  return array.map((item) => {
    const { key, ...rest } = item;

    if (rest.attributes) {
      rest.attributes = rest.attributes.map((attr) => {
        const { key, ...attrRest } = attr;
        return attrRest;
      });
    }

    if (rest.values) {
      rest.values = rest.values.map((val) => {
        const { key, ...valRest } = val;
        return valRest;
      });
    }

    return rest;
  });
};

const tagData = {
  section: 'Tags',
  items: [
    {
      key: 'tags1c',
      name: 'a',
      title: 'Anchor',
      listDisplay: `<a>`,
      attributes: ['href'],
      link: '/infobook/html',
      linkHelper: 'Tags',
      attributes: [
        {
          name: 'download',
          value: '"any-string-here"',
          description:
            'A text string alternative to display on browsers that do not display images.',
        },
        {
          name: 'href',
          value: '"www.techtutz.com"',
          description: 'The URL of a linked resource.',
        },
        {
          name: 'hreflang',
          value: '"en"',
          description: 'Specifies the language of the linked resource.',
        },
        {
          name: 'ping',
          value: '"www.techtutz.com"',
          description:
            'The ping attribute specifies a space-separated list of URLs to be notified if a user follows the hyperlink.',
        },
        {
          type: 'big',
          name: 'referrerpolicy',
          value: '"no-referrer"',
          description:
            'Specifies which referrer is sent when fetching the resource.',
        },
        {
          name: 'rel',
          value: '"icon"',
          description:
            'Specifies the relationship of the target object to the link object.',
        },
        {
          name: 'target',
          value: '"_blank"',
          description:
            'Specifies where to open the linked document or where to display the response received.',
        },
        {
          name: 'type',
          value: '"jpeg/image"',
          description:
            "Hints at the linked URL's format with a MIME type.",
        },
      ],
      requiredAttributes: [
        {
          name: 'href',
          value: '"www.techtutz.com"',
          description: 'The URL of a linked resource.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Anchor tag defines a link from one page to another - a hyperlink.',
        },
      ],
    },
    {
      key: 'tags2c',
      name: 'abbr',
      title: 'Abbreviation',
      listDisplay: `<abbr>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          title: 'Description:',
          value: 'The Abbreviation tag defines an acronym.',
        },
      ],
    },
    {
      key: 'tags3c',
      name: 'address',
      title: 'Address',
      listDisplay: `<address>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Address tag defines the contact information on a document.',
        },
      ],
    },
    {
      key: 'tags4c',
      name: 'area',
      type: 'self',
      title: 'Area',
      listDisplay: `<area>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      requiredAttributes: [
        {
          key: 'tags1cc',
          name: 'alt',
          value: "'displayed alt text'",
          description:
            "Alternative text in case an image can't be displayed.",
        },
        {
          key: 'tags2cc',
          name: 'coords',
          value: "'0, 0, 250, 250'",
          description:
            'A set of values specifying the coordinates of the hot-spot region.',
        },
        {
          key: 'tags3cc',
          name: 'href',
          value: "'www.techtutz.com'",
          description:
            'The URL that the hyperlink points to. Links are not restricted to HTTP-based URLs — they can use any URL scheme supported by browsers like medial files, phone numbers, and email addresses.',
        },
        {
          key: 'tags4cc',
          name: 'shape',
          value: "'rect'",
          description:
            'Defines the shape of a clickable region in an image map.',
        },
      ],
      attributes: [
        {
          key: 'tags1cc',
          name: 'alt',
          value: "'displayed alt text'",
          description:
            "Alternative text in case an image can't be displayed.",
        },
        {
          key: 'tags2cc',
          name: 'coords',
          value: "'0, 0, 250, 250'",
          description:
            'A set of values specifying the coordinates of the hot-spot region.',
        },
        {
          key: 'tags3cc',
          name: 'href',
          value: "'www.techtutz.com'",
          description:
            'The URL that the hyperlink points to. Links are not restricted to HTTP-based URLs — they can use any URL scheme supported by browsers like medial files, phone numbers, and email addresses.',
        },
        {
          key: 'tags4cc',
          name: 'shape',
          value: "'rect'",
          description:
            'Defines the shape of a clickable region in an image map.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Area tag defines an area inside an image with clickable areas.',
        },
      ],
    },
    {
      key: 'tags5c',
      name: 'article',
      title: 'Article',
      listDisplay: `<article>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Article tag specifies self-contained content.',
        },
      ],
    },
    {
      key: 'tags6c',
      name: 'aside',
      title: 'Aside',
      listDisplay: `<aside>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Aside tag defines some content aside from other content, like in a sidebar.',
        },
      ],
    },
    {
      key: 'tags7c',
      name: 'audio',
      title: 'Audio',
      listDisplay: `<audio>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      requiredAttributes: [
        {
          key: 'tags1cc',
          name: 'controls',
          description:
            'Indicates whether the browser should show playback controls to the user.',
        },
      ],
      attributes: [
        {
          key: 'tags1cc',
          name: 'controls',
          description:
            'Indicates whether the browser should show playback controls to the user.',
        },
        {
          key: 'tags1cc',
          name: 'crossorigin',
          description: 'How the element handles cross-origin requests.',
        },
        {
          key: 'tags1cc',
          name: 'loop',
          description:
            "Indicates whether the media should start playing from the start when it's finished.",
        },
        {
          key: 'tags1cc',
          name: 'muted',
          description:
            'Indicates whether the audio will be initially silenced on page load.',
        },
        {
          key: 'tags1cc',
          name: 'preload',
          description:
            'Indicates whether the whole resource, parts of it or nothing should be preloaded.',
        },
        {
          key: 'tags1cc',
          name: 'src',
          description: 'The URL of the embeddable content.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Audio tag specifies sound content such as music and other audio sources.',
        },
      ],
    },
    {
      key: 'tags8c',
      name: 'b',
      title: 'Bold',
      listDisplay: `<b>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Bold tag specifies bold text.',
        },
      ],
    },
    {
      key: 'tags9c',
      type: 'self',
      name: 'base',
      title: 'Base',
      link: '/infobook/html',
      linkHelper: 'Tags',
      listDisplay: `<base>`,
      requiredAttributes: [
        {
          name: 'href',
          value: '"www.techtutz.com"',
          description: 'The URL of a linked resource.',
        },
      ],
      attributes: [
        {
          name: 'href',
          value: '"www.techtutz.com"',
          description: 'The URL of a linked resource.',
        },
        {
          name: 'target',
          value: '"_blank"',
          description:
            'Specifies where to open the linked document or where to display the response received.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Base tag specifies the base URL and/or target for all relative URLs in a document and is placed in the Head of the document.',
        },
      ],
    },
    {
      key: 'tags10c',
      name: 'bdi',
      title: 'BDI',
      listDisplay: `<bdi>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Bidirectional Isolate tag isolates a part of text that might be formatted in a different direction from other text outside it, such as embedding content with an unknown text direction. Bidirectional text is text that may contain both sequences of characters that are arranged left-to-right (LTR) and sequences of characters that are arranged right-to-left (RTL), such as an Arabic quotation embedded in an English string.',
        },
      ],
      note: 'Like all other HTML elements, this element supports the global attributes, except that the dir attribute behaves differently than normal: it defaults to auto, meaning its value is never inherited from the parent element. This means that unless you specify a value of either rtl or ltr for dir, the user agent will determine the correct directionality to use based on the contents of the tag.',
    },
    {
      key: 'tags10c',
      name: 'bdo',
      title: 'BDO',
      listDisplay: `<bdo>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The BDO element overrides the current directionality of text, so that the text within is rendered in a different direction.',
        },
      ],
      note: "The text's characters are drawn from the starting point in the given direction; the individual characters' orientation is not affected (so characters don't get drawn backward).",
    },
    {
      key: 'tags11c',
      name: 'blockquote',
      title: 'Blockquote',
      listDisplay: `<blockquote>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      requiredAttributes: [
        {
          name: 'cite',
          value: '"www.techtutz.com"',
          description:
            'Contains a URI which points to the source of the quote or change.',
        },
      ],
      attributes: [
        {
          name: 'cite',
          value: '"www.techtutz.com"',
          description:
            'Contains a URI which points to the source of the quote or change.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Blockquote tag specifies a section that is quoted from another source.',
        },
      ],
    },
    {
      key: 'tags12c',
      name: 'body',
      title: 'Body',
      listDisplay: `<body>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      requiredAttributes: [
        {
          name: 'onafterprint',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call after the user has printed the document.',
        },
        {
          name: 'onbeforeprint',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call after the user requests to print document.',
        },
        {
          name: 'onbeforeunload',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the document is about to be unloaded.',
        },
        {
          name: 'onblur',
          type: 'big',
          value: '{someFunction()}',
          description: 'Function to call when the document loses focus.',
        },
        {
          name: 'onerror',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the document fails to load properly.',
        },
        {
          name: 'onfocus',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the document receives focus.',
        },
        {
          name: 'onhashchange',
          type: 'big',
          value: '{someFunction()}',
          description:
            "Function to call when the fragment identifier part (starting with the hash ('#') character) of the document's current address has changed.",
        },
        {
          name: 'onlanguagechange',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the preferred languages changed.',
        },
        {
          name: 'onload',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the document has finished loading.',
        },
        {
          name: 'onmessage',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the document has received a message.',
        },
        {
          name: 'onoffline',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when network communication has failed.',
        },
        {
          name: 'ononline',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when network communication has been restored.',
        },
        {
          name: 'onpopstate',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the user has navigated session history.',
        },
        {
          name: 'onredo',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the user has navigated session history.',
        },
        {
          name: 'onresize',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the document has been resized.',
        },
        {
          name: 'onstorage',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the storage area has changed.',
        },
        {
          name: 'onundo',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the user has moved backward in undo transaction history.',
        },
        {
          name: 'onunload',
          type: 'big',
          value: '{someFunction()}',
          description: 'Function to call when the document is going away.',
        },
      ],
      attributes: [
        {
          name: 'onafterprint',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call after the user has printed the document.',
        },
        {
          name: 'onbeforeprint',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call after the user requests to print document.',
        },
        {
          name: 'onbeforeunload',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the document is about to be unloaded.',
        },
        {
          name: 'onblur',
          type: 'big',
          value: '{someFunction()}',
          description: 'Function to call when the document loses focus.',
        },
        {
          name: 'onerror',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the document fails to load properly.',
        },
        {
          name: 'onfocus',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the document receives focus.',
        },
        {
          name: 'onhashchange',
          type: 'big',
          value: '{someFunction()}',
          description:
            "Function to call when the fragment identifier part (starting with the hash ('#') character) of the document's current address has changed.",
        },
        {
          name: 'onlanguagechange',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the preferred languages changed.',
        },
        {
          name: 'onload',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the document has finished loading.',
        },
        {
          name: 'onmessage',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the document has received a message.',
        },
        {
          name: 'onoffline',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when network communication has failed.',
        },
        {
          name: 'ononline',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when network communication has been restored.',
        },
        {
          name: 'onpopstate',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the user has navigated session history.',
        },
        {
          name: 'onredo',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the user has navigated session history.',
        },
        {
          name: 'onresize',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the document has been resized.',
        },
        {
          name: 'onstorage',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the storage area has changed.',
        },
        {
          name: 'onundo',
          type: 'big',
          value: '{someFunction()}',
          description:
            'Function to call when the user has moved backward in undo transaction history.',
        },
        {
          name: 'onunload',
          type: 'big',
          value: '{someFunction()}',
          description: 'Function to call when the document is going away.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Body tag specifies the body of the document and contains all the contents such as text, headings, images, etc.',
        },
      ],
      note: 'There can be only one Body element in a document.',
    },
    {
      key: 'tags13c',
      name: 'br',
      title: 'Break',
      type: 'self',
      listDisplay: `<br>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Break tag simply specifies a new line break.',
        },
      ],
    },
    {
      key: 'tags14c',
      name: 'button',
      title: 'Button',
      listDisplay: `<button>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      attributes: [
        {
          name: 'autofocus',
          description:
            'The element should be automatically focused after the page loaded.',
        },
        {
          name: 'disabled',
          description:
            'Indicates whether the user can interact with the element.',
        },
        {
          name: 'form',
          description:
            'Indicates the form that is the owner of the element.',
        },
        {
          name: 'formaction',
          description:
            'Indicates the action of the element, overriding the action defined in the Form element.',
        },
        {
          name: 'formenctype',
          description:
            "If the button/input is a submit button, this attribute sets the encoding type to use during form submission. If this attribute is specified, it overrides the enctype attribute of the button's form owner.",
        },
        {
          name: 'formmethod',
          description:
            "If the button/input is a submit button, this attribute sets the submission method to use during form submission (GET, POST, etc.). If this attribute is specified, it overrides the method attribute of the button's form owner.",
        },
        {
          type: 'big',
          name: 'formnovalidate',
          description:
            "If the button/input is a submit button, this boolean attribute specifies that the form is not to be validated when it is submitted. If this attribute is specified, it overrides the novalidate attribute of the button's form owner.",
        },
        {
          name: 'formtarget',
          description:
            "If the button/input is a submit button, this attribute specifies the browsing context (for example, tab, window, or inline frame) in which to display the response that is received after submitting the form. If this attribute is specified, it overrides the target attribute of the button's form owner.",
        },
        {
          name: 'name',
          description: 'Name of the element.',
        },
        {
          name: 'type',
          description: 'The default behavior of the button.',
        },
        {
          name: 'value',
          description:
            "Defines the value associated with the button's name when it's submitted with the form data.",
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Button tag defines a clickable button.',
        },
      ],
    },
    {
      key: 'tags15c',
      name: 'canvas',
      title: 'Canvas',
      listDisplay: `<canvas>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      attributes: [
        {
          name: 'height',
          description: 'Specifies the height of element',
        },
        {
          name: 'width',
          description: 'Specifies the width of element',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Canvas tag is used to draw graphics via scripting.',
        },
      ],
    },
    {
      key: 'tags16c',
      name: 'caption',
      title: 'Caption',
      listDisplay: `<caption>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Caption tag defines a table caption.',
        },
      ],
      note: 'If used, it must be the first child of its parent Table element.',
    },
    {
      key: 'tags17c',
      name: 'cite',
      title: 'Cite',
      listDisplay: `<cite>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Cite tag defines the title of a creative work like a book, song, movie, etc',
        },
      ],
    },
    {
      key: 'tags18c',
      name: 'code',
      title: 'Code',
      listDisplay: `<code>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            "The Code tag is used to define a piece of computer code and will be displayed in the browser's default mono-space font.",
        },
      ],
    },
    {
      key: 'tags19c',
      name: 'column',
      title: 'Column',
      listDisplay: `<column>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Column tag specifies a column in a table.',
        },
      ],
      attributes: [
        {
          name: 'span',
          description:
            'This attribute contains a positive integer indicating the number of consecutive columns the element spans (defaults to 1).',
        },
      ],
    },
    {
      key: 'tags20c',
      name: 'colgroup',
      title: 'Column Group',
      listDisplay: `<colgroup>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Column Group tag specifies column properties for each column within an element.',
        },
      ],
      attributes: [
        {
          name: 'span',
          description:
            'This attribute contains a positive integer indicating the number of consecutive columns the element spans (defaults to 1).',
        },
      ],
    },
    {
      key: 'tags21c',
      name: 'data',
      title: 'Data',
      listDisplay: `<data>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      requiredAttributes: [
        {
          name: 'value',
          value: '"value123"',
          description:
            'Defines a default value which will be displayed in the element on page load.',
        },
      ],
      attributes: [
        {
          name: 'value',
          value: '"value123"',
          description:
            'Defines a default value which will be displayed in the element on page load.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Data tag is used to add a machine-readable translation to some content.',
        },
      ],
    },
    {
      key: 'tags22c',
      name: 'datalist',
      title: 'Datalist',
      listDisplay: `<datalist>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Datalist tag is used to display a list of pre-defined options for a given input.',
        },
      ],
    },
    {
      key: 'tags23c',
      name: 'dd',
      title: 'Description Detail',
      listDisplay: `<dd>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Description Detail tag is used to display a list of predefined options for a given input.',
        },
      ],
    },
    {
      key: 'tags23c',
      name: 'del',
      title: 'Delete',
      listDisplay: `<del>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      attributes: [
        {
          name: 'cite',
          description:
            'Contains a URI which points to the source of the quote or change.',
        },
        {
          name: 'datetime',
          description:
            'Indicates the date and time associated with the element.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'This element represents a range of text that has been deleted from a document. This can be used when rendering "track changes" or source code diff information.',
        },
      ],
    },
    {
      key: 'tags24c',
      name: 'details',
      title: 'Details',
      listDisplay: `<details>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      attributes: [
        {
          name: 'open',
          description:
            'Indicates whether the contents are currently visible.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Details tag defines an expandable component meaning it can be opened and closed.',
        },
      ],
    },
    {
      key: 'tags25c',
      name: 'dfn',
      title: 'Definition',
      listDisplay: `<dfn>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Definition tag defines an element to indicate a term being defined within the context of a definition phrase or sentence',
        },
      ],
      note: 'For this tag the title attribute is different, the value of the title attribute is considered to be the term being defined.',
    },
    {
      key: 'tags26c',
      name: 'dialogue',
      title: 'Dialogue',
      listDisplay: `<dialogue>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      attributes: [
        {
          name: 'open',
          description:
            'Specifies whether the dialog is active and can be interacted with.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Dialog tag represents a dialog box.',
        },
      ],
    },
    {
      key: 'tags27c',
      name: 'div',
      title: 'Division',
      listDisplay: `<div>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Division tag specifies a section of content.',
        },
      ],
      note: 'The Division tag should be used only when no other semantic element is appropriate.',
    },
    {
      key: 'tags28c',
      name: 'dl',
      title: 'Description List',
      listDisplay: `<dl>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Description List tag is used to define a descriptive list.',
        },
      ],
    },
    {
      key: 'tags29c',
      name: 'dt',
      title: 'Description Term',
      listDisplay: `<dt>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Description Term tag defines a term in a descriptive list.',
        },
      ],
    },
    {
      key: 'tags30c',
      name: 'emp',
      title: 'Emphasis',
      listDisplay: `<emp>`,
      link: '/infobook/html',
      linkHelper: 'Tags',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Emphasis tag is used to define text that renders in italic and is read with vocal stress by screen readers.',
        },
      ],
    },
    {
      key: 'tags31c',
      type: 'self',
      name: 'embed',
      title: 'Embed',
      linkHelper: 'Tags',
      listDisplay: `<embed>`,
      link: '/infobook/html',
      requiredAttributes: [
        {
          name: 'src',
          value: '"./someFile.jpeg"',
        },
      ],
      attributes: [
        {
          name: 'height',
          description: 'Specifies the height of the element',
        },
        {
          name: 'type',
          description: 'Defines the type of the element.',
        },
        {
          name: 'src',
          description: 'The URL of the resource being embedded.',
        },
        {
          name: 'width',
          description: 'Specifies the width of the element',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Embed tag defines a container for an external resource, such as web pages and media.',
        },
      ],
    },
    {
      key: 'tags32c',
      name: 'fieldset',
      title: 'Fieldset',
      linkHelper: 'Tags',
      listDisplay: `<fieldset>`,
      link: '/infobook/html',
      requiredAttributes: [
        {
          name: 'form',
          value: '"some-form-name"',
        },
      ],
      attributes: [
        {
          name: 'disabled',
          description:
            'Indicates whether the user can interact with the element.',
        },
        {
          name: 'form',
          description:
            'Indicates the form that is the owner of the element.',
        },
        {
          name: 'name',
          description: 'The name associated with the group.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Embed tag defines a container for an external resource, such as web pages and media.',
        },
      ],
    },
    {
      key: 'tags34c',
      name: 'figcaption',
      title: 'Figure Caption',
      linkHelper: 'Tags',
      listDisplay: `<figcaption>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Figure Caption tag declares a caption for a Figure element.',
        },
      ],
    },
    {
      key: 'tags33c',
      name: 'figure',
      title: 'Figure',
      linkHelper: 'Tags',
      listDisplay: `<figure>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Figure tag represents self-contained content, potentially with an optional caption, which is specified using the <figcaption> element.',
        },
      ],
    },
    {
      key: 'tags35c',
      name: 'footer',
      title: 'Footer',
      linkHelper: 'Tags',
      listDisplay: `<footer>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Footer tag defines a footer section and usually contains like authorship and copyright information, contact information, and various other links and document specific information.',
        },
      ],
    },
    {
      key: 'tags36c',
      name: 'form',
      title: 'Form',
      linkHelper: 'Tags',
      listDisplay: `<form>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'accept-charset',
          description: 'List of supported character sets.',
        },
        {
          name: 'autocomplete',
          description:
            'Indicates whether controls in this form can by default have their values automatically completed by the browser.',
        },
        {
          name: 'name',
          description: 'The name of the form.',
        },
        {
          name: 'rel',
          description:
            'Specifies the relationship of the target object to the link object.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Form tag is used to create a container for getting input from the user. It can contain other elements also, but user input is the focus.',
        },
      ],
    },
    {
      key: 'tags37c',
      name: 'h1',
      title: 'Header 1',
      linkHelper: 'Tags',
      listDisplay: `<h1>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Heading tags are used to define six levels of section headings. The h1 tag defines the most important heading. The h6 tag defines the least important heading. It is recommended that you use these in order of importance and to only include one heading per section.',
        },
      ],
    },
    {
      key: 'tags38c',
      name: 'h2',
      title: 'Header 2',
      linkHelper: 'Tags',
      listDisplay: `<h2>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Heading tags are used to define six levels of section headings. The h1 tag defines the most important heading. The h6 tag defines the least important heading. It is recommended that you use these in order of importance and to only include one heading per section.',
        },
      ],
    },
    {
      key: 'tags39c',
      name: 'h3',
      title: 'Header 3',
      linkHelper: 'Tags',
      listDisplay: `<h3>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Heading tags are used to define six levels of section headings. The h1 tag defines the most important heading. The h6 tag defines the least important heading. It is recommended that you use these in order of importance and to only include one heading per section.',
        },
      ],
    },
    {
      key: 'tags40c',
      name: 'h4',
      title: 'Header 4',
      linkHelper: 'Tags',
      listDisplay: `<h4>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Heading tags are used to define six levels of section headings. The h1 tag defines the most important heading. The h6 tag defines the least important heading. It is recommended that you use these in order of importance and to only include one heading per section.',
        },
      ],
    },
    {
      key: 'tags41c',
      name: 'h5',
      title: 'Header 5',
      linkHelper: 'Tags',
      listDisplay: `<h5>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Heading tags are used to define six levels of section headings. The h1 tag defines the most important heading. The h6 tag defines the least important heading. It is recommended that you use these in order of importance and to only include one heading per section.',
        },
      ],
    },
    {
      key: 'tags42c',
      name: 'h6',
      title: 'Header 6',
      linkHelper: 'Tags',
      listDisplay: `<h6>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Heading tags are used to define six levels of section headings. The h1 tag defines the most important heading. The h6 tag defines the least important heading. It is recommended that you use these in order of importance and to only include one heading per section.',
        },
      ],
    },
    {
      key: 'tags43c',
      name: 'head',
      title: 'Head',
      linkHelper: 'Tags',
      listDisplay: `<head>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Head tag is a container for metadata - or data about the HTML document. Metadata typically defines the document title, character set, styles, and scripts.',
        },
      ],
    },
    {
      key: 'tags43c',
      name: 'header',
      title: 'Header',
      linkHelper: 'Tags',
      listDisplay: `<head>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'Specifies introductory content, typically a group of introductory or navigational aids.',
        },
      ],
    },
    {
      key: 'tags44c',
      name: 'hr',
      title: 'Horizontal Rule',
      type: 'self',
      linkHelper: 'Tags',
      listDisplay: `<hr>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Horizontal Rule element represents a thematic break between elements like a shift of topic within a section. It displays as a horizontal line.',
        },
      ],
    },
    {
      key: 'tags45c',
      name: 'html',
      title: 'HTML',
      linkHelper: 'Tags',
      listDisplay: `<html>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'xmlns',
          description:
            'Specifies the XML Namespace of the document. Default value is "http://www.w3.org/1999/xhtml". This is required in documents parsed with XML parsers, and optional in text/html documents.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The HTML tag represents the root element (or top-level element) of an HTML document. All other HTML elements must be descendants of this element.',
        },
      ],
    },
    {
      key: 'tags46c',
      name: 'i',
      title: 'Idiomatic',
      linkHelper: 'Tags',
      listDisplay: `<i>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Idiomatic tag defines text in an alternate way and is usually rendered as italic. It is often used to indicate a technical term, a phrase from another language, a side-note, etc.',
        },
      ],
    },
    {
      key: 'tags47c',
      name: 'iframe',
      title: 'Inline Frame',
      linkHelper: 'Tags',
      listDisplay: `<iframe>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'allow',
          description: 'Specifies a policy for the iframe.',
        },
        {
          name: 'allowfullscreen',
          description:
            'Set to true if the element can activate fullscreen mode by calling the requestFullscreen() method.',
        },
        {
          name: 'height',
          description: 'Specifies the height for the iframe.',
        },
        {
          name: 'loading',
          description:
            'Indicates if the element should be loaded lazily (loading="lazy") or loaded immediately (loading="eager").',
        },
        {
          name: 'name',
          description: 'Specifies the name of the element.',
        },
        {
          type: 'big',
          name: 'referrerpolicy',
          description:
            'Specifies which referrer is sent when fetching the resource.',
        },
        {
          name: 'sandbox',
          description:
            'Stops a document loaded in an iframe from using certain features (such as submitting forms or opening new windows).',
        },
        {
          name: 'src',
          description: 'The URL of the page to embed.',
        },
        {
          name: 'srcdoc',
          description:
            'The URL of the page to embed (if not provided, defaults to src URL).',
        },
        {
          name: 'width',
          description: 'Specifies the width for the iframe.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Inline Frame element is used to embed another document. Each embedded browsing context has its own session history and document and the browsing context that embeds the others is called the parent browsing context.',
        },
      ],
    },
    {
      key: 'tags48c',
      name: 'img',
      type: 'self',
      title: 'Image',
      linkHelper: 'Tags',
      listDisplay: `<img>`,
      link: '/infobook/html',
      requiredAttributes: [
        {
          name: 'alt',
          value: '"alt-text"',
        },
        {
          name: 'src',
          value: '"./image.jpeg"',
        },
      ],
      attributes: [
        {
          name: 'alt',
          description:
            "Alternative text in case an image can't be displayed.",
        },
        {
          name: 'crossorigin',
          description: 'How the element handles cross-origin requests.',
        },
        {
          name: 'decoding',
          description:
            'Indicates the preferred method to decode the image.',
        },
        {
          name: 'height',
          description: 'Specifies the height of element.',
        },
        {
          name: 'ismap',
          description:
            'Indicates that the image is part of a server-side image map.',
        },
        {
          name: 'loading',
          description:
            'Indicates if the element should be loaded lazily (loading="lazy") or loaded immediately (loading="eager").',
        },
        {
          name: 'referrerpolicy',
          description:
            'Specifies which referrer is sent when fetching the resource.',
        },
        {
          name: 'sizes',
          description:
            'Specifies the intended display size or sizes of a resource.',
        },
        {
          name: 'src',
          description: 'The URL of the embedded content.',
        },
        {
          name: 'srcset',
          description: 'One or more responsive image candidates.',
        },
        {
          name: 'width',
          description: 'Specifies the width of element',
        },
        {
          name: 'usemap',
          description:
            'Used to associate an element with a client-side image map',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Image tag is used to embed an image. The tag creates a holding space for the image, and links the two together.',
        },
      ],
      note: 'Please note that some attributes are part of HTML5 and may not work on older browsers.',
    },
    {
      key: 'tags49c',
      name: 'input',
      type: 'self',
      title: 'Input',
      linkHelper: 'Tags',
      listDisplay: `<input>`,
      link: '/infobook/html',
      requiredAttributes: [
        {
          name: 'type',
          value: '"text"',
        },
      ],
      attributes: [
        {
          name: 'accept',
          description:
            'Hint for expected file type in file upload controls.',
        },
        {
          name: 'alt',
          description:
            "Alternative text in case an image can't be displayed.",
        },
        {
          name: 'autocomplete',
          description:
            'Indicates whether controls in this form can by default have their values automatically completed by the browser.',
        },
        {
          name: 'capture',
          description: 'Specifies a new file can be captured.',
        },
        {
          name: 'checked',
          description:
            'Indicates whether the element should be checked on page load.',
        },
        {
          name: 'dirname',
          description:
            'Indicates whether the element should be checked on page load.',
        },
        {
          name: 'disabled',
          description:
            'Indicates whether the element is able to be interacted with.',
        },
        {
          name: 'form',
          description:
            'Indicates the form that is the owner of the element.',
        },
        {
          name: 'formaction',
          description:
            'Indicates the action of the element, overriding the action defined in the Form element.',
        },
        {
          name: 'formenctype',
          description:
            "If the button/input is a submit button, this attribute sets the encoding type to use during form submission. If this attribute is specified, it overrides the enctype attribute of the button's form owner.",
        },
        {
          name: 'formmethod',
          description:
            "If the button/input is a submit button, this attribute sets the submission method to use during form submission (GET, POST, etc.). If this attribute is specified, it overrides the method attribute of the button's form owner.",
        },
        {
          type: 'big',
          name: 'formnovalidate',
          description:
            "If the button/input is a submit button, this boolean attribute specifies that the form is not to be validated when it is submitted. If this attribute is specified, it overrides the novalidate attribute of the button's form owner.",
        },
        {
          name: 'formtarget',
          description:
            "If the button/input is a submit button, this attribute specifies the browsing context (for example, tab, window, or inline frame) in which to display the response that is received after submitting the form. If this attribute is specified, it overrides the target attribute of the button's form owner.",
        },
        {
          name: 'height',
          description: 'Specifies the height of the element.',
        },
        {
          name: 'list',
          description:
            'Value of the id attribute of the Datalist element of autocomplete options.',
        },
        {
          name: 'max',
          description: 'Specifies the maximum value allowed.',
        },
        {
          name: 'maxlength',
          description:
            'Defines the maximum number of characters allowed in the element.',
        },
        {
          name: 'min',
          description: 'Specifies the minimum value allowed.',
        },
        {
          name: 'minlength',
          description:
            'Defines the minimum number of characters allowed in the element.',
        },
        {
          name: 'multiple',
          description: 'Specifies whether to allow multiple values.',
        },
        {
          name: 'name',
          description: 'Specifies the name of the element.',
        },
        {
          name: 'pattern',
          description: 'Pattern the value must match to be valid.',
        },
        {
          name: 'placeholder',
          description:
            'Text that appears in the form control when it has no value set.',
        },
        {
          name: 'popovertarget',
          description:
            'Designates a button Input element as a control for a popover element.',
        },
        {
          type: 'big',
          name: 'popovertarget action',
          description:
            'The popovertargetaction attribute specifies the action that a popover control should perform.',
        },
        {
          name: 'readonly',
          description: 'Specifies if the element is not editable.',
        },
        {
          name: 'required',
          description: 'Specifies if the input is required.',
        },
        {
          name: 'size',
          description: 'Specifies the width of the element.',
        },
        {
          name: 'src',
          description: 'Specifies the URL of the embedded content.',
        },
        {
          name: 'step',
          description: 'Specifies increments in the accepted value(s).',
        },
        {
          name: 'type',
          description: 'Specifies the type of input.',
        },
        {
          name: 'value',
          description: 'Specifies the value of the element.',
        },
        {
          name: 'width',
          description: 'Specifies the width of the element.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Input tag specifies a field where users can enter data, and is usually used within forms.',
        },
      ],
    },
    {
      key: 'tags50c',
      name: 'ins',
      title: 'Inserted',
      linkHelper: 'Tags',
      listDisplay: `<ins>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'cite',
          description:
            'Contains a URI which points to the source of the quote or change.',
        },
        {
          name: 'datetime',
          description:
            'Indicates the date and time associated with the element.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Inserted defines a text that has been inserted and will usually render as underlined.',
        },
      ],
    },
    {
      key: 'tags51c',
      name: 'kbd',
      title: 'Keyboard Input',
      linkHelper: 'Tags',
      listDisplay: `<kbd>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            "The Keyboard Input tag is used to define keyboard input and will be displayed in the browser's default mono-space font.",
        },
      ],
    },
    {
      key: 'tags52c',
      name: 'label',
      title: 'Label',
      linkHelper: 'Tags',
      listDisplay: `<label>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'for',
          description:
            'Specifies the element that the label is representing.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            "The Label defines a label for an element - usually an input. To ensure the relation of an label and it's element the for attribute of the label must be the same as the id of the related element - or it can be bound by placing the label inside the element.",
        },
      ],
    },
    {
      key: 'tags53c',
      name: 'legend',
      title: 'Legend',
      linkHelper: 'Tags',
      listDisplay: `<legend>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Legend tag defines a caption for the fieldset element, usually on a form.',
        },
      ],
    },
    {
      key: 'tags54c',
      name: 'li',
      title: 'List Item',
      linkHelper: 'Tags',
      listDisplay: `<li>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'value',
          description: 'Specifies the value of the element.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The List Item tag defines a list item for Ordered Lists, Unordered Lists, and Menus. In Unordered Lists and Menus, the items will usually be displayed with bullet points. In Ordered Lists, the items will usually be displayed with numbers or letters.',
        },
      ],
    },
    {
      key: 'tags55c',
      name: 'link',
      title: 'Link',
      type: 'self',
      linkHelper: 'Tags',
      listDisplay: `<link>`,
      link: '/infobook/html',
      requiredAttributes: [
        {
          name: 'type',
          value: '"text/css"',
        },
        {
          name: 'rel',
          value: '"stylesheet"',
        },
        {
          name: 'href',
          value: '"www.techtutz.com"',
        },
      ],
      attributes: [
        {
          name: 'as',
          description:
            'Specifies the type of content being loaded by the Link element.',
        },
        {
          name: 'crossorigin',
          description: 'How the element handles cross-origin requests.',
        },
        {
          name: 'href',
          description: 'The URL of a linked resource.',
        },
        {
          name: 'hreflang',
          description: 'The language of a linked resource.',
        },
        {
          name: 'imagesizes',
          description:
            'Indicates to preload the appropriate resource used by an Image element with corresponding values for its srcset and sizes attributes.',
        },
        {
          name: 'imagesrcset',
          description:
            'Indicates to preload the appropriate resource used by an Image element with corresponding values for its srcset and sizes attributes.',
        },
        {
          name: 'integrity',
          description:
            'Specifies a Subresource Integrity value that allows browsers to verify what they fetch.',
        },
        {
          name: 'media',
          description:
            'Specifies a the media for which the linked resource was designed.',
        },
        {
          name: 'referrerpolicy',
          description:
            'Specifies which referrer is sent when fetching the resource.',
        },
        {
          name: 'rel',
          description:
            'Specifies the relationship of the target object to the link object.',
        },
        {
          name: 'type',
          description:
            'Defines the type of the content the tag is linked to.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Link tag defines the relationship between the current document and an external resource.',
        },
      ],
    },
    {
      key: 'tags57c',
      name: 'main',
      title: 'Main',
      linkHelper: 'Tags',
      listDisplay: `<main>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Main tag specifies the main content of a document.',
        },
      ],
    },
    {
      key: 'tags58c',
      name: 'map',
      title: 'Map',
      linkHelper: 'Tags',
      listDisplay: `<map>`,
      link: '/infobook/html',
      requiredAttributes: [
        {
          name: 'name',
          value: '"my-map-name"',
        },
      ],
      attributes: [
        {
          name: 'name',
          description: 'Specifies a name for the element.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Map tag defines an image map.',
        },
      ],
    },
    {
      key: 'tags60c',
      name: 'mark',
      title: 'Mark',
      linkHelper: 'Tags',
      listDisplay: `<mark>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Mark tag specifies marked or highlighted text.',
        },
      ],
    },
    {
      key: 'tags60c',
      name: 'menu',
      title: 'Menu',
      linkHelper: 'Tags',
      listDisplay: `<menu>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Menu tag is used as a semantic alternative to Unordered List.',
        },
      ],
    },
    {
      key: 'tags61c',
      name: 'meta',
      title: 'Meta',
      linkHelper: 'Tags',
      listDisplay: `<meta>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'charset',
          description:
            'Declares the character encoding of the page or script.',
        },
        {
          name: 'content',
          description:
            'A value associated with http-equiv or name depending on the context.',
        },
        {
          name: 'http-equiv',
          description: 'Defines a pragma directive.',
        },
        {
          name: 'name',
          description: 'Specifies a name for the element.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Meta defines data or information related to an HTML document.',
        },
      ],
    },
    {
      key: 'tags62c',
      name: 'meter',
      title: 'Meter',
      linkHelper: 'Tags',
      listDisplay: `<meter>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'min',
          description: 'Indicates the minimum value allowed.',
        },
        {
          name: 'max',
          description: 'Indicates the maximum value allowed.',
        },
        {
          name: 'low',
          description: 'Indicates the upper bound of the lower range.',
        },
        {
          name: 'high',
          description: 'Indicates the lower bound of the upper range.',
        },
        {
          name: 'optimum',
          description: 'Indicates the optimal numeric value.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Meter defines represents either a scalar value within a known range or a fractional value.',
        },
      ],
    },
    {
      key: 'tags63c',
      name: 'nav',
      title: 'Navigation',
      linkHelper: 'Tags',
      listDisplay: `<nav>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Nav tag represents a section of a page whose purpose is to provide navigation links, either within the current document or to other documents.',
        },
      ],
    },
    {
      key: 'tags64c',
      name: 'noscript',
      title: 'Noscript',
      linkHelper: 'Tags',
      listDisplay: `<noscript>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            "The Noscript tag defines an alternate content to be displayed to users that have disabled scripts in their browser or have a browser that doesn't support script.",
        },
      ],
    },
    {
      key: 'tags65c',
      name: 'object',
      title: 'Object',
      linkHelper: 'Tags',
      listDisplay: `<object>`,
      link: '/infobook/html',
      requiredAttributes: [
        {
          name: 'data',
          value: '"./some-file.jpeg"',
        },
        {
          name: 'type',
          value: '"image/jpg"',
        },
      ],
      attributes: [
        {
          name: 'data',
          description: 'The address of the resource as a valid URL.',
        },
        {
          name: 'form',
          description:
            'The Form element that the object element is associated with',
        },
        {
          name: 'height',
          description: 'Specifies the height of the object.',
        },
        {
          name: 'name',
          description: 'Specifies the name of the object.',
        },
        {
          name: 'type',
          description: 'Specifies the type of the data content.',
        },
        {
          name: 'usemap',
          description:
            'Specifies the hash-name of the related Map element.',
        },
        {
          name: 'width',
          description: 'Specifies the width of the object.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Object tag defines a container for an external resource.',
        },
      ],
    },
    {
      key: 'tags66c',
      name: 'ol',
      title: 'Ordered List',
      linkHelper: 'Tags',
      listDisplay: `<ol>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'reversed',
          description:
            'Indicates whether the list should be displayed in a descending order instead of an ascending order.',
        },
        {
          name: 'start',
          description: 'Defines the first number if other than 1.',
        },
        {
          name: 'type',
          description: 'Specifies the type of the list.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Ordered List tag defines an Ordered List. In Ordered Lists, the list items will usually be displayed with numbers or letters in ascending order.',
        },
      ],
    },
    {
      key: 'tags67c',
      name: 'optgroup',
      title: 'Option Group',
      linkHelper: 'Tags',
      listDisplay: `<optgroup>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'disabled',
          description:
            'Indicates whether the user can interact with the element.',
        },
        {
          name: 'label',
          description: 'Specifies a user-readable title of the element.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Option Group tag is used to group options together in a select drop down element.',
        },
      ],
    },
    {
      key: 'tags68c',
      name: 'option',
      title: 'Option',
      linkHelper: 'Tags',
      listDisplay: `<option>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'disabled',
          description:
            'Indicates whether the user can interact with the element.',
        },
        {
          name: 'label',
          description: 'Specifies a user-readable title of the element.',
        },
        {
          name: 'selected',
          description:
            'Indicates whether the option is initially selected.',
        },
        {
          name: 'value',
          description: 'The value of the option.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Option tag is used to define an item contained in a "Select", an "Option Group", or a "Data List" element.',
        },
      ],
    },
    {
      key: 'tags69c',
      name: 'output',
      title: 'Output',
      linkHelper: 'Tags',
      listDisplay: `<output>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'for',
          description: 'Describes elements which belongs to this one.',
        },
        {
          name: 'form',
          description:
            'Indicates the form that is the owner of the element.',
        },
        {
          name: 'name',
          description: 'Specifies the name of the element.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Output tag specifies a container element into which a site or app can inject the results of a calculation or the outcome of a user action.',
        },
      ],
    },
    {
      key: 'tags69c',
      name: 'p',
      title: 'Paragraph',
      linkHelper: 'Tags',
      listDisplay: `<p>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Paragraph tag specifies a paragraph of text.',
        },
      ],
    },
    {
      key: 'tags70c',
      name: 'picture',
      title: 'Picture',
      linkHelper: 'Tags',
      listDisplay: `<Picture>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Picture tag contains zero or more Source elements and one Image element to offer alternative versions of an image for different display/device scenarios.',
        },
      ],
      note: "To decide which URL to load, the user agent examines each Source element's srcset, media, and type attributes to select a compatible image that best matches the current layout and capabilities of the display device.",
    },
    {
      key: 'tags71c',
      name: 'pre',
      title: 'Preformatted',
      linkHelper: 'Tags',
      listDisplay: `<pre>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Preformatted tag is used to define a piece of pre-formatted text.',
        },
      ],
    },
    {
      key: 'tags72c',
      name: 'progress',
      title: 'Progress',
      linkHelper: 'Tags',
      listDisplay: `<progress>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'max',
          description: 'Specifies the maximum value allowed.',
        },
        {
          name: 'value',
          description: 'Specifies the value of the element.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Progress tag defines a representation of the completion progress of a task.',
        },
      ],
    },
    {
      key: 'tags73c',
      name: 'quote',
      title: 'Quote',
      linkHelper: 'Tags',
      listDisplay: `<quote>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'cite',
          description:
            'Contains a URI which points to the source of the quote or change.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Quote tag specifies a short quotation.',
        },
      ],
    },
    {
      key: 'tags74c',
      name: 'ruby',
      title: 'Ruby',
      linkHelper: 'Tags',
      listDisplay: `<ruby>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Ruby tag specifies a ruby annotation - which is small extra text, attached to the main text to indicate the pronunciation or meaning of the corresponding characters.',
        },
      ],
    },
    {
      key: 'tags75c',
      name: 'rp',
      title: 'Ruby Parenthesis',
      linkHelper: 'Tags',
      listDisplay: `<rp>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Ruby Parenthesis tag is used to provide parentheses around a ruby text, to be shown by browsers that do not support ruby annotations.',
        },
      ],
    },
    {
      key: 'tags76c',
      name: 'rt',
      title: 'Ruby Text',
      linkHelper: 'Tags',
      listDisplay: `<rt>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Ruby Text tag is used to define an explanation or pronunciation of characters for East Asian typography in a Ruby annotation.',
        },
      ],
    },
    {
      key: 'tags77c',
      name: 's',
      title: 'Strikethrough',
      linkHelper: 'Tags',
      listDisplay: `<s>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Strikethrough tag specifies text that is no longer correct, accurate, or relevant. The text will be displayed with a line through it.',
        },
      ],
    },
    {
      key: 'tags78c',
      name: 'samp',
      title: 'Sample',
      linkHelper: 'Tags',
      listDisplay: `<samp>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Sample is used to define sample output from a computer program.',
        },
      ],
    },
    {
      key: 'tags79c',
      name: 'script',
      title: 'Script',
      linkHelper: 'Tags',
      listDisplay: `<script>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'async',
          description: 'Executes the script asynchronously.',
        },
        {
          name: 'crossorigin',
          description: 'How the element handles cross-origin requests.',
        },
        {
          name: 'defer',
          description:
            'Indicates that the script should be executed after the page has been parsed.',
        },
        {
          name: 'integrity',
          description:
            'Specifies a Subresource Integrity value that allows browsers to verify what they fetch.',
        },
        {
          name: 'nomodule',
          description:
            'This Boolean attribute is set to indicate that the script should not be executed in browsers that support ES modules — in effect, this can be used to serve fallback scripts to older browsers that do not support modular JavaScript code.',
        },
        {
          name: 'nonce',
          description:
            'A cryptographic nonce (number used once) to allow scripts in a script-src Content-Security-Policy.',
        },
        {
          name: 'referrerpolicy',
          description:
            'Specifies which referrer is sent when fetching the resource.',
        },
        {
          name: 'src',
          description: 'The URL of the embedded content.',
        },
        {
          name: 'type',
          description: 'Defines the type of the element.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Script tag is used to embed client-side JavaScript.',
        },
      ],
    },
    {
      key: 'tags80c',
      name: 'section',
      title: 'Section',
      linkHelper: 'Tags',
      listDisplay: `<section>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'async',
          description: 'Executes the script asynchronously.',
        },
        {
          name: 'crossorigin',
          description: 'How the element handles cross-origin requests.',
        },
        {
          name: 'defer',
          description:
            'Indicates that the script should be executed after the page has been parsed.',
        },
        {
          name: 'integrity',
          description:
            'Specifies a Subresource Integrity value that allows browsers to verify what they fetch.',
        },
        {
          name: 'nomodule',
          description:
            'This Boolean attribute is set to indicate that the script should not be executed in browsers that support ES modules — in effect, this can be used to serve fallback scripts to older browsers that do not support modular JavaScript code.',
        },
        {
          name: 'nonce',
          description:
            'A cryptographic nonce (number used once) to allow scripts in a script-src Content-Security-Policy.',
        },
        {
          name: 'referrerpolicy',
          description:
            'Specifies which referrer is sent when fetching the resource.',
        },
        {
          name: 'src',
          description: 'The URL of the embedded content.',
        },
        {
          name: 'type',
          description: 'Defines the type of the element.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Section tag specifies self-contained content.',
        },
      ],
    },
    {
      key: 'tags81c',
      name: 'select',
      title: 'Select',
      linkHelper: 'Tags',
      listDisplay: `<select>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'autocomplete',
          description:
            'Indicates whether controls in this form can by default have their values automatically completed by the browser.',
        },
        {
          name: 'autofocus',
          description:
            'Indicates whether controls in this form can by default have their values automatically completed by the browser.',
        },
        {
          name: 'disabled',
          description:
            'Indicates whether the element is able to be interacted with.',
        },
        {
          name: 'form',
          description:
            'Indicates the form that is the owner of the element.',
        },
        {
          name: 'multiple',
          description: 'Specifies whether to allow multiple values.',
        },
        {
          name: 'name',
          description: 'Specifies the name of the element.',
        },
        {
          name: 'pattern',
          description: 'Pattern the value must match to be valid.',
        },
        {
          name: 'placeholder',
          description:
            'Text that appears in the form control when it has no value set.',
        },
        {
          name: 'popovertarget',
          description:
            'Designates a button Input element as a control for a popover element.',
        },
        {
          type: 'big',
          name: 'popovertarget action',
          description:
            'The popovertargetaction attribute specifies the action that a popover control should perform.',
        },
        {
          name: 'readonly',
          description: 'Specifies if the element is not editable.',
        },
        {
          name: 'required',
          description: 'Specifies if the input is required.',
        },
        {
          name: 'size',
          description: 'Specifies the width of the element.',
        },
        {
          name: 'src',
          description: 'Specifies the URL of the embedded content.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Select tag is used to create a drop-down list.',
        },
      ],
    },
    {
      key: 'tags82c',
      name: 'slot',
      title: 'Slot',
      linkHelper: 'Tags',
      listDisplay: `<slot>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The <slot> HTML element—part of the Web Components technology suite—is a placeholder inside a web component that you can fill with your own markup, which lets you create separate DOM trees and present them together.',
        },
      ],
    },
    {
      key: 'tags82c',
      name: 'small',
      title: 'Small',
      linkHelper: 'Tags',
      listDisplay: `<small>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Small tag defines smaller text content.',
        },
      ],
    },
    {
      key: 'tags83c',
      name: 'source',
      title: 'Source',
      linkHelper: 'Tags',
      listDisplay: `<source>`,
      link: '/infobook/html',
      requiredAttributes: [
        {
          name: 'src',
          value: '"./some-file.jpeg"',
        },
        {
          name: 'media',
          value: '"(min-width): 900px"',
        },
      ],
      attributes: [
        {
          name: 'height',
          description: 'Specifies the height of the element.',
        },
        {
          name: 'media',
          description: 'The media type of the content.',
        },
        {
          name: 'src',
          description: 'The URL of the embedded content.',
        },
        {
          name: 'srcset',
          description: 'One or more responsive image candidates.',
        },
        {
          name: 'sizes',
          description:
            'A list of source sizes that describes the final rendered width of the image represented by the source.',
        },
        {
          name: 'width',
          description: 'Specifies the width of the element.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Source tag specifies multiple media resources for the "Picture", the "Audio", or the "Video" element.',
        },
      ],
    },
    {
      key: 'tags84c',
      name: 'span',
      title: 'Span',
      linkHelper: 'Tags',
      listDisplay: `<span>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Span tag specifies self-contained content.',
        },
      ],
    },
    {
      key: 'tags84c',
      name: 'strong',
      title: 'Strong',
      linkHelper: 'Tags',
      listDisplay: `<strong>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Strong tag is used to define text with strong importance.',
        },
      ],
    },
    {
      key: 'tags85c',
      name: 'style',
      title: 'Style',
      linkHelper: 'Tags',
      listDisplay: `<style>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'media',
          value:
            'This attribute defines which media the style should be applied to. Its value is a media query, which defaults to all if the attribute is missing.',
        },
        {
          name: 'nonce',
          value:
            'A cryptographic nonce (number used once) used to allow inline styles in a style-src Content-Security-Policy.',
        },
        {
          name: 'title',
          value: 'Specifies the title of the element.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Style tag is used to define CSS for a document.',
        },
      ],
    },
    {
      key: 'tags86c',
      name: 'sub',
      title: 'Subscript',
      linkHelper: 'Tags',
      listDisplay: `<sub>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Subscript tag defines text that appears half a character below the normal line, and is sometimes rendered in a smaller font.',
        },
      ],
    },
    {
      key: 'tags87c',
      name: 'sup',
      title: 'Superscript',
      linkHelper: 'Tags',
      listDisplay: `<sup>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Superscript tag defines text half a character above the normal line, and is sometimes rendered in a smaller font.',
        },
      ],
    },
    {
      key: 'tags88c',
      name: 'summary',
      title: 'Summary',
      linkHelper: 'Tags',
      listDisplay: `<summary>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            "The Summary tag specifies a summary, caption, or legend for a 'Details' element's disclosure box. Clicking the 'Summary' element toggles the state of the parent 'Details' element open and closed.",
        },
      ],
    },
    {
      key: 'tags89c',
      name: 'svg',
      title: 'Scalable Vector Graphics',
      linkHelper: 'Tags',
      listDisplay: `<svg>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Scalable Vector Graphics tag is a container that defines a new coordinate system and viewport. It is used as the outermost element of SVG documents.',
        },
      ],
    },
    {
      key: 'tags90c',
      name: 'table',
      title: 'Table',
      linkHelper: 'Tags',
      listDisplay: `<table>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Table tag defines an HTML table.',
        },
      ],
    },
    {
      key: 'tags91c',
      name: 'tbody',
      title: 'Table Body',
      linkHelper: 'Tags',
      listDisplay: `<tbody>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Table Body tag groups the body content in an HTML table.',
        },
      ],
    },
    {
      key: 'tags92c',
      name: 'td',
      title: 'Table Data',
      linkHelper: 'Tags',
      listDisplay: `<td>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'colspan',
          description:
            'The colspan attribute defines the number of columns a cell should span.',
        },
        {
          name: 'headers',
          description:
            'Ids of the Table Head elements which applies to this element.',
        },
        {
          name: 'rowspan',
          description: 'Specifies the title of the element.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Table Data tag defines a data cell in a table.',
        },
      ],
    },
    {
      key: 'tags93c',
      name: 'template',
      title: 'Template',
      linkHelper: 'Tags',
      listDisplay: `<template>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Template is used as a container to hold content for repeated use - hidden from the user when the page loads.',
        },
      ],
    },
    {
      key: 'tags94c',
      name: 'textarea',
      title: 'Text Area',
      linkHelper: 'Tags',
      listDisplay: `<textarea>`,
      link: '/infobook/html',
      attributes: [
        {
          type: 'big',
          name: 'autocomplete',
          description:
            'Indicates whether controls in this form can by default have their values automatically completed by the browser.',
        },
        {
          name: 'autofocus',
          description:
            'The element should be automatically focused after the page loaded.',
        },
        {
          name: 'cols',
          description: 'Defines the number of columns in a textarea.',
        },
        {
          name: 'disabled',
          description:
            'Indicates whether the user can interact with the element.',
        },
        {
          name: 'form',
          description:
            'Indicates the form that is the owner of the element.',
        },
        {
          name: 'maxlength',
          description:
            'Defines the maximum number of characters allowed in the element.',
        },
        {
          name: 'minlength',
          description:
            'Defines the minimum number of characters allowed in the element.',
        },
        {
          name: 'name',
          description: 'Specifies the name of the element.',
        },
        {
          type: 'big',
          name: 'placeholder',
          description:
            'Text that appears in the form control when it has no value set.',
        },
        {
          name: 'readonly',
          description: 'Indicates whether the element can be edited.',
        },
        {
          name: 'required',
          description: 'Specifies if the input is required.',
        },
        {
          name: 'rows',
          description: 'Defines the number of rows in a text area.',
        },
        {
          name: 'spellcheck',
          description:
            'Indicates whether spell checking is allowed for the element.',
        },
        {
          name: 'wrap',
          description: 'Indicates whether the text should be wrapped.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Text Area tag defines a multi-line text input control.',
        },
      ],
    },
    {
      key: 'tags95c',
      name: 'tfoot',
      title: 'Table Footer',
      linkHelper: 'Tags',
      listDisplay: `<tfoot>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Table Footer tag groups the footer content in an HTML table.',
        },
      ],
    },
    {
      key: 'tags97c',
      name: 'thead',
      title: 'Table Head',
      linkHelper: 'Tags',
      listDisplay: `<thead>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Table Header tag defines a header cell in a table.',
        },
      ],
    },
    {
      key: 'tags96c',
      name: 'th',
      title: 'Table Header',
      linkHelper: 'Tags',
      listDisplay: `<th>`,
      link: '/infobook/html',
      attributes: [
        {
          name: 'abbr',
          description:
            "This attribute contains a short abbreviated description of the cell's content. Some user-agents, such as speech readers, may present this description before the content itself.",
        },
        {
          name: 'colspan',
          description:
            'The colspan attribute defines the number of columns a cell should span.',
        },
        {
          name: 'headers',
          description:
            'Ids of the Table Head elements which applies to this element.',
        },
        {
          name: 'rowspan',
          description:
            'Defines the number of rows a table cell should span over.',
        },
        {
          name: 'scope',
          description:
            'Defines the cells that the header test relates to.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Table Head tag defines a header cell in a table.',
        },
      ],
    },
    {
      key: 'tags98c',
      name: 'time',
      title: 'Time',
      linkHelper: 'Tags',
      listDisplay: `<time>`,
      link: '/infobook/html',
      requiredAttributes: [
        {
          name: 'datetime',
          value: '"2023-01-01T22:55:03Z"',
        },
      ],
      attributes: [
        {
          name: 'datetime',
          description:
            'Indicates the date and time associated with the element.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Time tag defines a specific time and date. It may include the datetime attribute to translate dates into machine-readable format, allowing for better search engine results or custom features.',
        },
      ],
    },
    {
      key: 'tags99c',
      name: 'title',
      title: 'Title',
      linkHelper: 'Tags',
      listDisplay: `<title>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Title tag defines the title of the document.',
        },
      ],
    },
    {
      key: 'tags100c',
      name: 'tr',
      title: 'Table Row',
      linkHelper: 'Tags',
      listDisplay: `<tr>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value: 'The Table Row tag defines a row in a table.',
        },
      ],
    },
    {
      key: 'tags101c',
      name: 'track',
      title: 'Track',
      linkHelper: 'Tags',
      listDisplay: `<track>`,
      link: '/infobook/html',
      requiredAttributes: [
        {
          name: 'kind',
          value: '"subtitles"',
        },
        {
          name: 'src',
          value: '"./subtitles.vtt"',
        },
      ],
      attributes: [
        {
          name: 'default',
          description:
            "Indicates that the track should be enabled unless the user's preferences indicate something different.",
        },
        {
          name: 'kind',
          description: 'Specifies the kind of text track.',
        },
        {
          name: 'label',
          description: 'Specifies a user-readable title of the element.',
        },
        {
          name: 'src',
          description: 'The URL of the embedded content.',
        },
        {
          name: 'srclang',
          description: 'The language of the embedded content.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Track tag specifies text tracks for audio or video content.',
        },
      ],
    },
    {
      key: 'tags102c',
      name: 'u',
      title: 'Unarticulated Annotation',
      linkHelper: 'Tags',
      listDisplay: `<u>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Unarticulated Annotation tag specifies text that is unarticulated and styled differently, such as misspelled words or foreign words.',
        },
      ],
    },
    {
      key: 'tags103c',
      name: 'ul',
      title: 'Unordered List',
      linkHelper: 'Tags',
      listDisplay: `<ul>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Unordered List tag defines a list with items that usually will be displayed with bullet points.',
        },
      ],
    },
    {
      key: 'tags104c',
      name: 'var',
      title: 'Variable',
      linkHelper: 'Tags',
      listDisplay: `<var>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Variable tag is used to define keyboard input and will usually be displayed in italic.',
        },
      ],
    },
    {
      key: 'tags105c',
      name: 'video',
      title: 'Video',
      linkHelper: 'Tags',
      listDisplay: `<video>`,
      link: '/infobook/html',
      requiredAttributes: [
        {
          key: 'tags1cc',
          name: 'controls',
          description:
            'Indicates whether the browser should show playback controls to the user.',
        },
      ],
      attributes: [
        {
          key: 'tags1cc',
          name: 'autoplay',
          description:
            'The audio or video should play as soon as possible.',
        },
        {
          key: 'tags1cc',
          name: 'controls',
          description:
            'Indicates whether the browser should show playback controls to the user.',
        },
        {
          key: 'tags1cc',
          name: 'crossorigin',
          description: 'How the element handles cross-origin requests.',
        },
        {
          key: 'tags1cc',
          name: 'loop',
          description:
            "Indicates whether the media should start playing from the start when it's finished.",
        },
        {
          key: 'tags1cc',
          name: 'muted',
          description:
            'Indicates whether the audio will be initially silenced on page load.',
        },
        {
          key: 'tags1cc',
          name: 'preload',
          description:
            'Indicates whether the whole resource, parts of it or nothing should be preloaded.',
        },
        {
          key: 'tags1cc',
          name: 'poster',
          description:
            'A URL for an image to be shown while the video is downloading.',
        },
        {
          key: 'tags1cc',
          name: 'src',
          description: 'The URL of the embedded content.',
        },
        {
          key: 'tags1cc',
          name: 'width',
          description: 'Specifies the width of the content.',
        },
      ],
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Video tag is used to embed video or movie content in a document.',
        },
      ],
    },
    {
      key: 'tags107c',
      name: 'wbr',
      title: 'Word Break',
      linkHelper: 'Tags',
      listDisplay: `<wbr>`,
      link: '/infobook/html',
      values: [
        {
          key: 'tags1cc',
          title: 'Description:',
          value:
            'The Word Break tag represents a word break opportunity—a position within text where the browser may optionally break a line, though its line-breaking rules would not otherwise create a break at that location.',
        },
      ],
    },
  ],
};

export const updatedTags = removeKeys(tagData?.items)
  ?.sort((a, b) => {
    const titleA = a.name.toUpperCase();
    const titleB = b.name.toUpperCase();

    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }

    return 0;
  })
  ?.map((item) => {
    const newItem = {
      ...item,
      description: item?.values?.[0]?.value,
    };
    delete newItem.key;
    delete newItem.values;

    return newItem;
  });
