import React from 'react';
import Text from 'components/text';

const DisplayedSearchTermRow = ({ results, searchString }) => {
  return results?.length ? (
    <Text
      margin="0 20px"
      color="#26e07f"
      fontSize="24px"
      fontWeight="bold"
      text={searchString}
    />
  ) : (
    <Text
      color="#999"
      margin="0 20px"
      fontSize="24px"
      fontWeight="bold"
      text={searchString}
    />
  );
};

export default DisplayedSearchTermRow;
