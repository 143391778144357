import React, { useEffect, useContext } from 'react';

import GlobalContext from 'GlobalContext';

import Tabs from 'components/tabs';
import Card from 'components/card';
import BackIcon from 'components/back-arrow-icon';

import TagsView from './tabs/tags';
import LearnView from './tabs/learn';
import AboutView from './tabs/about';
import AttributesView from './tabs/attributes';

import './tabs/index.scss';

const tabs = [
  {
    key: 0,
    index: 0,
    text: 'About',
    content: <AboutView />,
  },
  {
    key: 1,
    index: 1,
    text: 'Learn',
    content: <LearnView />,
  },
  {
    key: 2,
    index: 2,
    text: 'Tags',
    content: <TagsView />,
  },
  {
    key: 3,
    index: 3,
    text: 'Attributes',
    content: <AttributesView />,
  },
];

const InfobookHtmlView = () => {
  const { htmlViewTab, setHtmlViewTab } = useContext(GlobalContext);

  const updateHtmlTab = (tab) => {
    localStorage.setItem('htmlTab', tab);
    setHtmlViewTab(tab);
  };

  useEffect(() => {
    const savedTab = localStorage.getItem('htmlTab');

    if (savedTab) {
      setHtmlViewTab(savedTab);
    }
  }, []);

  return (
    <Card type="static" headerText="HTML" icon={<BackIcon />}>
      <Tabs
        tabs={tabs}
        activeTab={htmlViewTab}
        setActiveTab={updateHtmlTab}
      />
    </Card>
  );
};

export default InfobookHtmlView;
