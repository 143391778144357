export const learnData = {
  section: 'Learn',
  items: [
    {
      title: 'HTML Workspace',
      value:
        'HTML is a computer language, and you will need an IDE (integrated development environment) or a code editor to work with it. If you do not already have one installed (or are not sure which one to install) you can check out our Resources page for our recommendations.',
      link: '/infobook/html',
      linkHelper: 'Learn',
    },
    {
      title: 'HTML File Naming',
      value:
        'The system needs to know this document is HTML and not a regular text document. To do this, end the file name with .html when saving - example: index.html or myFile.html.',
      link: '/infobook/html',
      linkHelper: 'Learn',
    },
    {
      title: 'HTML Tag Structure',
      value:
        'HTML is made up of elements which are defined by tags. There are two kinds of tags: standard tags and self closing tags. Standard tags consist of 2 opening and closing angle/carrot brackets each around a tag name. Self closing tags consist of one opening and closing angle/carrot brackets around a single tag name.',
      link: '/infobook/html',
      linkHelper: 'Learn',
    },
    {
      title: 'HTML Tag Example',
      value:
        'Below is an example of a regular tag. The Paragraph Tag is used to denote text on the screen, with the content to display between the two tags.',
      link: '/infobook/html',
      linkHelper: 'Learn',
    },
    {
      title: 'HTML Single Tag Example',
      values: [
        {
          value: 'Below is an example of a self closing tag.',
        },
        {
          value:
            'This Break Tag is an example of a self closing tag, in which no content is displayed in the element so a single tag can be used.',
        },
      ],
      link: '/infobook/html',
      linkHelper: 'Learn',
    },
  ],
};
