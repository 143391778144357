import React from 'react';

import Text from 'components/text';
import Container from 'components/container';
import TagImplementation from 'components/tag-implementation';

const TagExample = () => (
  <Container width="80%" margin="0 auto">
    <Text
      width="100%"
      lineHeight={2}
      margin="0 auto"
      textAlign="left"
      fontWeight="bold"
      text="Regular Tag Example"
    />
    <Text
      margin="0"
      width="100%"
      lineHeight={2}
      textAlign="left"
      text="The Paragraph tag represents text on the page."
    />
    <TagImplementation tagName="p" tagText="tag example" />
  </Container>
);

export default TagExample;
