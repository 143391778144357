import React from 'react';

import './index.scss';

const ViewContainer = ({ children, restOfProps }) => {
  return (
    <div className="view-container" {...restOfProps}>
      {children}
    </div>
  );
};

export default ViewContainer;
