import React from 'react';

import Text from 'components/text';
import Container from 'components/container';
import CssImplementation from 'components/css-implementation';

const RuleExampleId = () => (
  <Container width="80%" margin="20px auto">
    <Text
      fontSize="18px"
      textAlign="left"
      fontWeight="bold"
      margin="0 auto 10px 0"
      text="CSS ID Rule Example"
    />
    <Text
      lineHeight="2"
      textAlign="left"
      margin="0 auto 10px 0"
      text="Here we are targeting the element with the id 'icon' and giving it a border radius of 5px."
    />
    <CssImplementation
      selectorName="#icon"
      idForCopy="css-id-example"
      properties={[{ name: 'border-radius', value: '5px' }]}
    />
  </Container>
);

export default RuleExampleId;
