export const attributes = [
  {
    title: 'Accept',
    name: 'accept',
    tags: ['form', 'input'],
    value: '"image/jpeg"',
    description:
      'List of types the server accepts, typically a file type.',
  },
  {
    title: 'Accepted Character Set',
    name: 'accept-charset',
    tags: ['form'],
    value: '"text/plain"',
    description: 'List of supported character sets.',
  },
  {
    title: 'Access Key',
    name: 'accesskey',
    value: '"K"',
    description:
      'Keyboard shortcut to activate or add focus to the element.',
  },
  {
    title: 'Action',
    name: 'action',
    tags: ['form'],
    value: '"/some-path"',
    description:
      'The URI of a program that processes the information submitted via the form. This can be an absolute or a relative URL. If the attribute is omitted, the data will be submitted to the current URL.',
    note: `It's important to note that the "action" attribute alone does not handle form data processing. It merely specifies the destination URL or script. The server-side code or script associated with the specified URL is responsible for processing the form data and generating the appropriate response.`,
  },
  {
    name: 'allowfullscreen',
    title: 'Allow Fullscreen',
    tags: ['iframe'],
    description:
      'Sets if the tag can activate fullscreen mode by calling the requestFullscreen() method.',
  },
  {
    name: 'alt',
    title: 'Alternate Text',
    value: '"any-text-here',
    tags: ['area', 'image', 'input'],
    description: "Alternative text in case an image can't be displayed.",
  },
  {
    name: 'async',
    title: 'Asynchronous',
    tags: ['script'],
    description: 'Specifies to execute the script asynchronously.',
  },
  {
    name: 'autocapitalize',
    title: 'Autocapitalize',
    description:
      'Sets whether input is automatically capitalized when entered by user.',
  },
  {
    name: 'autocomplete',
    title: 'Autocomplete',
    tags: ['form', 'input', 'select', 'textarea'],
    description:
      'Indicates whether controls in this form can by default have their values automatically completed by the browser.',
  },
  {
    name: 'autofocus',
    title: 'Autofocus',
    tags: ['button', 'input', 'select', 'textarea'],
    description:
      'The element should be automatically focused after the page loaded.',
  },
  {
    name: 'autoplay',
    title: 'Autoplay',
    tags: ['audio', 'video'],
    description: 'The audio or video should play as soon as possible.',
  },
  {
    name: 'capture',
    title: 'Capture',
    tags: ['input'],
    description:
      'The capture attribute specifies that, optionally, a new file should be captured, and which device should be used to capture that new media of a type defined by the accept attribute.',
  },
  {
    name: 'checked',
    title: 'Checked',
    tags: ['meta', 'script'],
    description:
      'Indicates whether the element should be checked on page load.',
  },
  {
    name: 'cite',
    title: 'Cite',
    value: '"www.techtutz.com"',
    tags: ['q', 'ins', 'del', 'blockquote'],
    description:
      'Contains a URI which points to the source of the quote or change.',
  },
  {
    name: 'class',
    title: 'Class',
    value: 'my-class',
    description:
      'The class global attribute is a space-separated list of the case-sensitive classes of the element. Classes allow CSS and JavaScript to select and access specific elements via the class selectors or functions like the DOM method document.getElementsByClassName.',
  },
  {
    name: 'cols',
    title: 'Columns',
    value: '"2"',
    description:
      'The visible width of the text control, in average character widths. If it is specified, it must be a positive integer. If it is not specified, the default value is 20.',
  },
  {
    name: 'colspan',
    title: 'Column Span',
    tags: ['td', 'th'],
    value: '"5"',
    description:
      'This attribute contains a non-negative integer value that indicates for how many columns the cell extends. Its default value is 1. Values higher than 1000 will be considered as incorrect and will be set to the default value (1).',
  },
  {
    name: 'content',
    title: 'Content',
    value: '"Some Content"',
    tags: ['meta'],
    description:
      'This attribute contains the value for the http-equiv or name attribute, depending on which is used.',
  },
  {
    name: 'contenteditable',
    title: 'Content Editable',
    description:
      'The contenteditable global attribute is an enumerated attribute indicating if the element should be editable by the user.',
  },
  {
    name: 'controls',
    title: 'Controls',
    tags: ['audio', 'video'],
    description:
      'The contenteditable global attribute is an enumerated attribute indicating if the element should be editable by the user.',
  },
  {
    name: 'coords',
    title: 'Coordinates',
    tags: ['area'],
    description:
      'The coords attribute details the coordinates of the shape attribute in size, shape, and placement of an Area element. This attribute must not be used if the shape attribute is set to default.',
  },
  {
    name: 'crossorigin',
    title: 'Cross Origin',
    tags: ['audio', 'img', 'link', 'script', 'video'],
    description:
      "The crossorigin attribute provides support for CORS, defining how the element handles cross-origin requests, thereby enabling the configuration of the CORS requests for the element's fetched data. Depending on the element, the attribute can be a CORS settings attribute.",
  },
  {
    name: 'data',
    title: 'Data',
    tags: ['object'],
    description: 'The address of the resource as a valid URL.',
  },
  {
    name: 'data-*',
    title: 'Data Identifier',
    description:
      'The data-* global attributes form a class of attributes called custom data attributes, that allow proprietary information to be exchanged between the HTML and its DOM representation by scripts.',
  },
  {
    name: 'datetime',
    title: 'Date Time',
    tags: ['del', 'ins', 'time'],
    description:
      'Indicates the date and time associated with the element.',
  },
  {
    name: 'decoding',
    title: 'Image',
    tags: ['img'],
    description: 'Provides an image decoding hint to the browser.',
  },
  {
    name: 'default',
    title: 'Default',
    tags: ['track'],
    description:
      "This attribute indicates that the track should be enabled unless the user's preferences indicate that another track is more appropriate. This may only be used on one track element per media element.",
  },
  {
    name: 'defer',
    title: 'Defer',
    tags: ['script'],
    description:
      'This Boolean attribute is set to indicate to a browser that the script is meant to be executed after the document has been parsed, but before firing DOMContentLoaded.',
  },
  {
    name: 'dir',
    title: 'Direction',
    description:
      'Defines the text direction. Allowed values are ltr (Left-To-Right) or rtl (Right-To-Left).',
  },
  {
    name: 'dirname',
    title: 'Direction Name',
    tags: ['input', 'textarea'],
    description: `The dirname attribute's purpose is to inform the server the text direction of the user's input. When the form is submitted, the dirname attribute creates a new field containing the directionality of the text entered (either "ltr" for left-to-right or "rtl" for right-to-left), which is then sent to the server.`,
  },
  {
    name: 'disabled',
    title: 'Disabled',
    tags: [
      'button',
      'fieldset',
      'input',
      'optgroup',
      'option',
      'select',
      'textarea',
    ],
    description:
      'The Boolean disabled attribute, when present, makes the element not mutable, focusable, or even submitted with the form. The user can neither edit nor focus on the control, nor its form control descendants.',
  },
  {
    name: 'download',
    title: 'Download',
    tags: ['a', 'area'],
    description:
      'Indicates that the hyperlink is to be used for downloading a resource.',
  },
  {
    name: 'draggable',
    title: 'Draggable',
    tags: ['a', 'area'],
    description:
      'The draggable global attribute is an enumerated attribute that indicates whether the element can be dragged, either with native browser behavior or the HTML Drag and Drop API.',
  },
  {
    name: 'enctype',
    title: 'Enctype',
    tags: ['form'],
    description:
      'If the value of the method attribute is post, enctype is the MIME type of the form submission.',
  },
  {
    name: 'for',
    title: 'For',
    tags: ['label', 'output'],
    description:
      'The for attribute is an allowed attribute for <label> and <output>. When used on a Label element it indicates the form element that this label describes. When used on an <output> element it allows for an explicit relationship between the elements that represent values which are used in the output.',
  },
  {
    name: 'form',
    title: 'Form',
    tags: [
      'button',
      'fieldset',
      'input',
      'label',
      'meter',
      'object',
      'output',
      'select',
      'textarea',
    ],
    description:
      'The for attribute is an allowed attribute for <label> and <output>. When used on a Label element it indicates the form element that this label describes. When used on an <output> element it allows for an explicit relationship between the elements that represent values which are used in the output.',
  },
  {
    name: 'formaction',
    title: 'Form Action',
    tags: ['button', 'input'],
    description:
      'Indicates the action of the element, overriding the action defined in the Form element.',
  },
  {
    name: 'formenctype',
    title: 'Form Encryption Type',
    tags: ['button', 'input'],
    description: `If the button/input is a submit button (e.g. type="submit"), this attribute sets the encoding type to use during form submission. If this attribute is specified, it overrides the enctype attribute of the button's form owner.`,
  },
  {
    name: 'formmethod',
    title: 'Form Method',
    tags: ['button', 'input'],
    description: `If the button/input is a submit button (e.g. type="submit"), this attribute sets the submission method to use during form submission (GET, POST, etc.). If this attribute is specified, it overrides the method attribute of the button's form owner.`,
  },
  {
    name: 'formnovalidate',
    title: 'Form Validate',
    tags: ['button', 'input'],
    description: `If the button/input is a submit button (e.g. type="submit"), this boolean attribute specifies that the form is not to be validated when it is submitted. If this attribute is specified, it overrides the novalidate attribute of the button's form owner.`,
  },
  {
    name: 'formtarget',
    title: 'Form Target',
    tags: ['button', 'input'],
    description: `If the button/input is a submit button (e.g. type="submit"), this attribute specifies the browsing context (for example, tab, window, or inline frame) in which to display the response that is received after submitting the form. If this attribute is specified, it overrides the target attribute of the button's form owner.`,
  },
  {
    name: 'headers',
    title: 'Headers',
    tags: ['td', 'tr'],
    description:
      'IDs of the Table Header elements which applies to this element.',
  },
  {
    name: 'height',
    title: 'Height',
    tags: ['canvas', 'embed', 'iframe', 'img', 'input', 'object', 'video'],
    description: 'Specifies the height of element.',
  },
  {
    name: 'hidden',
    title: 'Hidden',
    description:
      "The hidden global attribute is an enumerated attribute indicating that the browser should not render the contents of the element. For example, it can be used to hide elements of the page that can't be used until the login process has been completed.",
  },
  {
    name: 'high',
    title: 'High',
    description:
      'The lower numeric bound of the high end of the measured range. This must be less than the maximum value (max attribute), and it also must be greater than the low value and minimum value (low attribute and min attribute, respectively), if any are specified. If unspecified, or if greater than the maximum value, the high value is equal to the maximum value.',
  },
  {
    name: 'href',
    title: 'Hyperlink Reference',
    tags: ['a', 'area', 'base', 'link'],
    description:
      'The href attribute defines a link to a resource as a reference URL. The exact meaning of that link depends on the context of each element using it.',
  },
  {
    name: 'hreflang',
    title: 'Href Language',
    tags: ['a', 'link'],
    description: 'Specifies the language of the linked resource.',
  },
  {
    name: 'http-equiv',
    title: 'HTTP Equivalent',
    value: '"text/html"',
    tags: ['meta'],
    description:
      'This attribute acts as a substitute for the actual HTTP response header that would be sent by the server. Content-security-policy: This value is used to define a content policy for the current page. It specifies the allowed server origins and script endpoints to mitigate cross-site scripting (XSS) attacks. Content-type: This value declares the MIME type and character encoding of the document. It is typically used with the text/html MIME type to specify that the document is an HTML file. The content attribute for this value should be set to something like "text/html; charset=utf-8" to indicate the character encoding. Default-style: This value is used to specify the name of the default CSS style sheet set for the document. It allows authors to define a preferred style sheet that should be used if no other style sheets are explicitly specified. X-ua-compatible: This value is used to specify the desired compatibility mode for Internet Explorer (IE). It allows authors to control how IE renders the document. Setting the content attribute to "IE=edge" indicates that the latest rendering engine should be used, ignoring any compatibility modes.',
  },
  {
    name: 'id',
    title: 'Identifier',
    value: '"some-string"',
    description:
      'The id global attribute defines an identifier (id) which must be unique in the whole document. Its purpose is to identify the element when linking (using a fragment identifier), scripting, or styling (with CSS).',
  },
  {
    name: 'integrity',
    title: 'Integrity',
    value: '"sha384-oqV..."',
    tags: ['link', 'script'],
    description:
      'Subresource Integrity (SRI) is a security feature that enables browsers to verify that resources they fetch (for example, from a CDN) are delivered without unexpected manipulation. It works by allowing you to provide a cryptographic hash that a fetched resource must match.',
  },
  {
    name: 'inputmode',
    title: 'Input Mode',
    tags: ['textarea'],
    value: '"decimal"',
    description:
      "The inputmode global attribute is an enumerated attribute that hints at the type of data that might be entered by the user while editing the element or its contents. This allows a browser to display an appropriate virtual keyboard.Values can be - None: No virtual keyboard. For when the page implements its own keyboard input control. Text: Standard input keyboard for the user's current locale. Decimal: Fractional numeric input keyboard containing the digits and decimal. Numeric: Numeric input keyboard, but only requires the digits 0 through 9. Telephone: A telephone keypad input. Search: A virtual keyboard optimized for search input. Email: A virtual keyboard optimized for entering email addresses. Url: A keypad optimized for entering URLs.",
  },
  {
    name: 'ismap',
    title: 'Is Map',
    tags: ['img'],
    value: '{true}',
    description:
      'This Boolean attribute indicates that the image is part of a server-side map. If so, the coordinates where the user clicked on the image are sent to the server.',
  },
  {
    name: 'itemprop',
    title: 'Item Prop',
    value: '{color: "green"}',
    description:
      'The itemprop global attribute is used to add properties to an item. Every HTML element can have an itemprop attribute specified, and an itemprop consists of a name-value pair.',
  },
  {
    name: 'kind',
    title: 'Kind',
    tags: ['track'],
    value: 'subtitles',
    description:
      'How the text track is meant to be used. If omitted the default kind is subtitles. Subtitles: Subtitles provide translation of content that cannot be understood by the viewer. For example speech or text that is not English in an English language film. Captions: Closed captions provide a transcription and possibly a translation of audio. Descriptions: Textual description of the video content. Chapters: Chapter titles are intended to be used when the user is navigating the media resource. Metadata: Tracks used by scripts. Not visible to the user.',
  },
  {
    name: 'label',
    title: 'Label',
    tags: ['option', 'optgroup', 'track'],
    value: '"my-label"',
    description: 'Specifies a user-readable title of the element.',
  },
  {
    name: 'lang',
    title: 'Language',
    value: '"en-GB"',
    description:
      'The lang global attribute helps define the language of an element: the language that non-editable elements are written in, or the language that the editable elements should be written in by the user. The attribute contains a single "language tag" in the format defined in RFC 5646: Tags for Identifying Languages (also known as BCP 47). If the attribute value is the empty string (lang=""), the language is set to unknown; if the language tag is not valid according to BCP47, it is set to invalid.',
  },
  {
    name: 'loop',
    title: 'Loop',
    tags: ['audio', 'video'],
    description:
      "Indicates whether the media should start playing from the start when it's finished.",
  },
  {
    name: 'list',
    title: 'List',
    value: '"some-list"',
    tags: ['input'],
    description:
      'Value of the id attribute of the Datalist element of autocomplete options.',
  },
  {
    name: 'low',
    title: 'low',
    value: '"5"',
    tags: ['meter'],
    description:
      'Value of the id attribute of the Datalist element of autocomplete options.',
  },
  {
    name: 'max',
    title: 'Max',
    tags: ['input', 'meter', 'progress'],
    value: '{20}',
    description:
      "Valid for date, month, week, time, datetime-local, number, and range, it defines the greatest value in the range of permitted values. If the value entered into the element exceeds this, the element fails constraint validation. If the value of the max attribute isn't a number, then the element has no maximum value.",
  },
  {
    name: 'maxlength',
    title: 'Max Length',
    tags: ['input', 'textarea'],
    value: '{20}',
    description:
      'Valid for text, search, url, tel, email, and password, it defines the maximum number of characters (as UTF-16 code units) the user can enter into the field. This must be an integer value 0 or higher. If no maxlength is specified, or an invalid value is specified, the field has no maximum length. This value must also be greater than or equal to the value of minlength.',
  },
  {
    name: 'min',
    title: 'Min',
    tags: ['input', 'meter'],
    value: '{10}',
    description:
      "Valid for date, month, week, time, datetime-local, number, and range, it defines the lowest value in the range of permitted values. If the value entered into the element exceeds this, the element fails constraint validation. If the value of the max attribute isn't a number, then the element has no maximum value.",
  },
  {
    name: 'minlength',
    title: 'Min Length',
    tags: ['input', 'textarea'],
    value: '{10}',
    description:
      'Valid for text, search, url, tel, email, and password, it defines the minimum number of characters (as UTF-16 code units) the user can enter into the entry field. This must be a non-negative integer value smaller than or equal to the value specified by maxlength. If no minlength is specified, or an invalid value is specified, the input has no minimum length.',
  },
  {
    name: 'media',
    title: 'Media',
    tags: ['a', 'area', 'link', 'source', 'style'],
    value: '"(min-width: 600px)"',
    description:
      'The media attribute specifies a media query that must be matched for a style sheet to apply.',
  },
  {
    name: 'method',
    title: 'method',
    tags: ['form'],
    value: '"(min-width: 600px)"',
    description: 'The HTTP method to submit the form with.',
  },
  {
    name: 'multiple',
    title: 'Multiple',
    tags: ['input', 'select'],
    description:
      'The Boolean multiple attribute, if set, means the user can enter comma separated email addresses in the email widget or can choose more than one file with the file input.',
  },
  {
    name: 'muted',
    title: 'Muted',
    tags: ['audio', 'video'],
    description:
      'Indicates whether the audio will be initially silenced on page load.',
  },
  {
    name: 'name',
    title: 'Name',
    tags: [
      'button',
      'form',
      'fieldset',
      'iframe',
      'input',
      'object',
      'output',
      'select',
      'textarea',
      'map',
      'meta',
      'param',
    ],
    description: 'Specifies a name for the element.',
  },
  {
    name: 'novalidate',
    title: 'Novalidate',
    tags: ['form'],
    description: `This Boolean attribute indicates that the form shouldn't be validated when submitted. If this attribute is not set (and therefore the form is validated), it can be overridden by a formnovalidate attribute on a Button, Input element belonging to the form.`,
  },
  {
    name: 'open',
    title: 'Open',
    tags: ['details', 'dialog'],
    description:
      'Indicates whether the contents are currently visible (in the case of a Details element) or whether the dialog is active and can be interacted with (in the case of a Dialog element).',
  },
  {
    name: 'optimum',
    title: 'optimum',
    tags: ['meter'],
    description:
      "This attribute indicates the optimal numeric value. It must be within the range (as defined by the min attribute and max attribute). When used with the low attribute and high attribute, it gives an indication where along the range is considered preferable. For example, if it is between the min attribute and the low attribute, then the lower range is considered preferred. The browser may color the meter's bar differently depending on whether the value is less than or equal to the optimum value.",
  },
  {
    name: 'pattern',
    title: 'Pattern',
    tags: ['input'],
    description:
      "The pattern attribute specifies a regular expression the form control's value should match. If a non-null value doesn't conform to the constraints set by the pattern value, the ValidityState object's read-only patternMismatch property will be true.",
  },
  {
    name: 'ping',
    title: 'Ping',
    tags: ['a', 'area'],
    description:
      'A space-separated list of URLs. When the link is followed, the browser will send POST requests with the body PING to the URLs. Typically for tracking.',
  },
  {
    name: 'placeholder',
    title: 'Placeholder',
    tags: ['input', 'textarea'],
    description:
      'Provides a hint to the user of what can be entered in the field.',
  },
  {
    name: 'playsinline',
    title: 'Plays Inline',
    tags: ['video'],
    description: `A Boolean attribute indicating that the video is to be played "inline", that is within the element's playback area. Note that the absence of this attribute does not imply that the video will always be played in fullscreen.`,
  },
  {
    name: 'poster',
    title: 'Poster',
    tags: ['video'],
    description: `A URL for an image to be shown while the video is downloading. If this attribute isn't specified, nothing is displayed until the first frame is available, then the first frame is shown as the poster frame.`,
  },
  {
    name: 'preload',
    title: 'Preload',
    tags: ['audio', 'video'],
    description:
      'Indicates whether the whole resource, parts of it or nothing should be preloaded.',
  },
  {
    name: 'readonly',
    title: 'Read Only',
    tags: ['input', 'textarea'],
    description:
      'The Boolean readonly attribute, when present, makes the element not mutable, meaning the user can not edit the control.',
  },
  {
    name: 'referrerpolicy',
    title: 'Referrer Policy',
    tags: ['a', 'area', 'iframe', 'img', 'link', 'script'],
    description:
      'Specifies which referrer is sent when fetching the resource.',
  },
  {
    name: 'rel',
    title: 'Relationship',
    description:
      'The rel attribute defines the relationship between a linked resource and the current document. Valid on Link, Anchor, Area, and Form, the supported values depend on the element on which the attribute is found.',
  },
  {
    name: 'required',
    title: 'Required',
    description:
      'The Boolean required attribute, if present, indicates that the user must specify a value for the input before the owning form can be submitted.',
  },
  {
    name: 'reversed',
    title: 'Reversed',
    tags: ['ol'],
    description:
      'Indicates whether the list should be displayed in a descending order instead of an ascending order.',
  },
  {
    name: 'role',
    title: 'Role',
    description:
      "ARIA roles provide semantic meaning to content, allowing screen readers and other tools to present and support interaction with object in a way that is consistent with user expectations of that type of object. ARIA roles can be used to describe elements that don't natively exist in HTML or exist but don't yet have full browser support.",
  },
  {
    name: 'rows',
    title: 'Rows',
    tags: ['textarea'],
    description:
      'The number of visible text lines for the control. If it is specified, it must be a positive integer. If it is not specified, the default value is 2.',
  },
  {
    name: 'rowspan',
    title: 'Rowspan',
    tags: ['td', 'th'],
    description: 'Specifies how many rows a cell should span in a table.',
  },
  {
    name: 'sandbox',
    title: 'Sandbox',
    tags: ['iframe'],
    description:
      'Controls the restrictions applied to the content embedded in the Iframe element. The value of the attribute can either be empty to apply all restrictions, or space-separated tokens to lift particular restrictions:',
  },
  {
    name: 'scope',
    title: 'Scope',
    tags: ['th'],
    description:
      'This enumerated attribute defines the cells that the header element relates to.',
  },
  {
    name: 'selected',
    title: 'Selected',
    tags: ['option'],
    description:
      'If present, this Boolean attribute indicates that the option is initially selected. If the  Option element is the descendant of a Select element whose multiple attribute is not set, only one single Option of this Select element may have the selected attribute.',
  },
  {
    name: 'shape',
    title: 'Shape',
    tags: ['a', 'area'],
    description:
      'The shape of the associated hot spot. The specifications for HTML defines the values rect, which defines a rectangular region; circle, which defines a circular region; poly, which defines a polygon; and default, which indicates the entire region beyond any defined shapes.',
  },
  {
    name: 'size',
    title: 'Size',
    tags: ['input', 'select'],
    description:
      "The size attribute defines the width of the Input and the height of the Select element. For the input, if the type attribute is text or password then it's the number of characters. This must be an integer value 0 or higher. If no size is specified, or an invalid value is specified, the input has no size declared, and the form control will be the default width based on the user agent.",
  },
  {
    name: 'sizes',
    title: 'Sizes',
    tags: ['link', 'img', 'source'],
    description:
      'The sizes attribute allows you to specify the layout width of the image for each of a list of media conditions. This provides the ability to automatically select among different images—even images of different orientations or aspect ratios—as the document state changes to match different media conditions.',
  },
  {
    name: 'slot',
    title: 'Slot',
    description:
      "The slot global attribute assigns a slot in a shadow DOM shadow tree to an element: An element with a slot attribute is assigned to the slot created by the Slot element whose name attribute's value matches that slot attribute's value.",
  },
  {
    name: 'span',
    title: 'Span',
    tags: ['col', 'colgroup'],
    description:
      'Used to specify a span of columns that a certain style should apply to.',
  },
  {
    name: 'spellcheck',
    title: 'Spellcheck',
    description:
      'The spellcheck global attribute is an enumerated attribute that defines whether the element may be checked for spelling errors.',
  },
  {
    name: 'src',
    title: 'Source',
    tags: [
      'audio',
      'embed',
      'iframe',
      'img',
      'input',
      'script',
      'source',
      'track',
      'video',
    ],
    description: 'The URL of the embedded content.',
  },
  {
    name: 'srcdoc',
    title: 'Source Doc',
    tags: ['iframe'],
    description:
      'Inline HTML to embed, overriding the src attribute. If a browser does not support the srcdoc attribute, it will fall back to the URL in the src attribute.',
  },
  {
    name: 'srclang',
    title: 'Source Language',
    tags: ['track'],
    description:
      'Language of the track text data. It must be a valid BCP 47 language tag. If the kind attribute is set to subtitles, then srclang must be defined.',
  },
  {
    name: 'srcset',
    title: 'Source Set',
    tags: ['img', 'source'],
    description:
      'The HTMLImageElement property srcset is a string which identifies one or more image candidate strings, separated using commas (,) each specifying image resources to use under given circumstances. Each image candidate string contains an image URL and an optional width or pixel density descriptor that indicates the conditions under which that candidate should be used instead of the image specified by the src property.',
  },
  {
    name: 'start',
    title: 'Start',
    tags: ['ol'],
    description:
      'An integer to start counting from for the list items. Always an Arabic numeral (1, 2, 3, etc.), even when the numbering type is letters or Roman numerals. For example, to start numbering elements from the letter "d" or the Roman numeral "iv," use start="4".',
  },
  {
    name: 'step',
    title: 'Step',
    tags: ['input'],
    description:
      'The step attribute is a number that specifies the granularity that the value must adhere to or the keyword any. It is valid for the numeric input types, including the date, month, week, time, datetime-local, number and range types.',
  },
  {
    name: 'style',
    title: 'Style',
    description:
      'The style global attribute contains CSS styling declarations to be applied to the element. Note that it is recommended for styles to be defined in a separate file or files. This attribute and the <style> element have mainly the purpose of allowing for quick styling, for example for testing purposes.',
  },
  {
    name: 'tabindex',
    title: 'Tab Index',
    description:
      'The tabindex global attribute allows developers to make HTML elements focusable, allow or prevent them from being sequentially focusable (usually with the Tab key, hence the name) and determine their relative ordering for sequential focus navigation.',
  },
  {
    name: 'target',
    title: 'Target',
    tags: ['a', 'area', 'base', 'form'],
    description:
      'The target attribute should be used when there are multiple possible targets for the ending resource, such as when the parent document is embedded within an HTML or XHTML document, or is viewed with a tabbed browser.',
  },
  {
    name: 'title',
    title: 'Title',
    description:
      'The title global attribute contains text representing advisory information related to the element it belongs to.',
  },
  {
    name: 'translate',
    title: 'Translate',
    description:
      "The translate global attribute is an enumerated attribute that is used to specify whether an element's translatable attribute values and its Text node children should be translated when the page is localized, or whether to leave them unchanged.",
  },
  {
    name: 'type',
    title: 'Type',
    tags: [
      'button',
      'input',
      'embed',
      'object',
      'ol',
      'script',
      'source',
      'style',
      'menu',
      'link',
    ],
    description:
      "The type attribute is a generic attribute and it has different meaning based on the context in which it's used.",
  },
  {
    name: 'usemap',
    title: 'Use Map',
    tags: ['img', 'input', 'object'],
    description:
      'The useMap property on the HTMLImageElement interface reflects the value of the HTML usemap attribute, which is a string providing the name of the client-side image map to apply to the image.',
  },
  {
    name: 'value',
    title: 'Value',
    tags: [
      'button',
      'data',
      'input',
      'li',
      'meter',
      'option',
      'progress',
      'param',
    ],
    description:
      'Defines a default value which will be displayed in the element on page load.',
  },
  {
    name: 'width',
    title: 'Width',
    tags: ['canvas', 'embed', 'iframe', 'img', 'input', 'object', 'video'],
    description: 'Specifies the width of the element.',
  },
  {
    name: 'wrap',
    title: 'Wrap',
    tags: ['textarea'],
    description:
      'Indicates how the control should wrap the value for form submission.',
  },
];

export const getAttribute = ({ name }) =>
  attributes?.find((attribute) => attribute.name === name);
