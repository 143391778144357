import React from 'react';

import Container from 'components/container';

import BigSection from './components/BigSection';
import SmallSection from './components/SmallSection';
import ButtonSection from './components/ButtonSection';

import './index.scss';

const LandingPage = () => (
  <Container>
    <BigSection />
    <SmallSection />
    <ButtonSection />
  </Container>
);

export default LandingPage;
