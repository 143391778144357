import React, { useContext } from 'react';

import GlobalContext from 'GlobalContext';

import { getTag } from 'helpers/getTag';

import Text from 'components/text';
import Button from 'components/button';

import AttributeDescription from './AttributeDescription';
import AttributeBasicExample from './AttributeBasicExample';

import './AttributeDetailModalView.scss';

const AttributeDetailModalView = ({ attribute }) => {
  const { setShowDetailModal, setHtmlViewTab, setCurrentTag } =
    useContext(GlobalContext);

  const handleTagButtonClick = (tag) => {
    setShowDetailModal(false);
    setHtmlViewTab('Tags');
    setCurrentTag(getTag(tag));
    setShowDetailModal(true);
  };

  return (
    <section className="attribute-modal">
      <AttributeDescription attribute={attribute} />
      <AttributeBasicExample attribute={attribute} />
      {attribute?.tags?.length && (
        <section>
          <Text
            text="Tags"
            fontSize="18px"
            textAlign="left"
            fontWeight="bold"
          />
          <div className="tag-button-container">
            {attribute?.tags?.map((tag, index) => (
              <div className="tag-button" key={index}>
                <Button
                  buttonText={tag}
                  handleClick={() => handleTagButtonClick(tag)}
                />
              </div>
            ))}
          </div>
        </section>
      )}
      {!attribute?.tags && (
        <div>
          <Text
            text="Note"
            fontSize="18px"
            textAlign="left"
            fontWeight="bold"
          />
          <Text
            textAlign="left"
            text="This is a global attribute - ALL tags accept using it."
          />
        </div>
      )}
      {attribute?.note && (
        <div>
          <Text
            text="Note"
            fontSize="18px"
            margin="40px 0 20px 0"
            textAlign="left"
            fontWeight="bold"
          />
          <Text
            margin="0"
            lineHeight={2}
            textAlign="left"
            text={attribute?.note}
          />
        </div>
      )}
    </section>
  );
};

export default AttributeDetailModalView;
