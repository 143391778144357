import React from 'react';

import Text from 'components/text';
import Container from 'components/container';

const DescriptionEntry = ({ entry }) => (
  <Container width="80%" margin="0 auto">
    <Text
      width="100%"
      lineHeight={2}
      margin="0 auto"
      textAlign="left"
      key={entry?.key}
      fontWeight="bold"
      text={entry?.title}
    />
    {entry?.values ? (
      entry?.values?.map((value) => (
        <Text
          width="100%"
          text={value}
          lineHeight={2}
          textAlign="left"
          key={entry?.key}
          margin="0 auto 20px auto"
        />
      ))
    ) : (
      <Text
        width="100%"
        lineHeight={2}
        textAlign="left"
        key={entry?.key}
        text={entry?.value}
        margin="0 auto 20px auto"
      />
    )}
  </Container>
);

export default DescriptionEntry;
