import React from 'react';

import Text from 'components/text';
import Container from 'components/container';

const DisplayedResultCounter = ({ results }) => {
  return results?.length ? (
    <Container flex>
      <Text
        margin="0"
        text="Results"
        color="#26e07f"
        fontSize="20px"
        fontWeight="bold"
      />
      <p className="results-counter">{results?.length}</p>
    </Container>
  ) : (
    <Text
      margin="0"
      text="Results"
      color="#e5e5e5"
      fontSize="20px"
      fontWeight="bold"
    />
  );
};

export default DisplayedResultCounter;
