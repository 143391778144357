import React from 'react';

import Tooltip from 'components/tooltip';
import IconComponent from 'components/icon';

const SingleTagWithAttribute = ({ handleCopy, attributes, tagName }) => (
  <Tooltip content="Clip to copy code to your clipboard!">
    <div
      className="implementation-container"
      onClick={() => handleCopy('#markup-with-attribute')}
    >
      <section
        id="markup-with-attribute"
        className="markup-with-attributes"
      >
        <span className="tag-name">{`<${tagName}>`}</span>
      </section>
      <div className="tag-copy">
        <IconComponent iconName="IoClipboardOutline" size="30px" />
      </div>
    </div>
  </Tooltip>
);

export default SingleTagWithAttribute;
