import React, { useEffect, useContext } from 'react';

import GlobalContext from 'GlobalContext';

import Tabs from 'components/tabs';
import Card from 'components/card';
import BackIcon from 'components/back-arrow-icon';

import AboutView from './tabs/about';
import LearnView from './tabs/learn';
import SelectorsTab from './tabs/selectors';
import PropertiesTab from './tabs/properties';

const tabs = [
  {
    key: 0,
    index: 0,
    text: 'About',
    content: <AboutView />,
  },
  {
    key: 1,
    index: 1,
    text: 'Learn',
    content: <LearnView />,
  },
  {
    key: 2,
    index: 2,
    text: 'Selectors',
    content: <SelectorsTab />,
  },
  {
    key: 3,
    index: 3,
    text: 'Properties',
    content: <PropertiesTab />,
  },
];

const InfobookCssView = () => {
  const { cssViewTab, setCssViewTab } = useContext(GlobalContext);

  const updateCssTab = (tab) => {
    localStorage.setItem('cssTab', tab);
    setCssViewTab(tab);
  };

  useEffect(() => {
    const savedTab = localStorage.getItem('cssTab');

    if (savedTab) {
      updateCssTab(savedTab);
    }
  }, []);

  return (
    <Card headerText="CSS" type="static" icon={<BackIcon />}>
      <Tabs
        tabs={tabs}
        activeTab={cssViewTab}
        setActiveTab={updateCssTab}
      />
    </Card>
  );
};

export default InfobookCssView;
