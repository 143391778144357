import Text from 'components/text';
import Container from 'components/container';

const TextBanner = ({
  bannerText,
  height = '45px',
  width = '85%',
  letterSpacing = '1px',
  backgroundColor = '#26e07f',
}) => (
  <Container
    width={width}
    height={height}
    backgroundColor={backgroundColor}
  >
    <Text
      fontSize="18px"
      fontWeight="bold"
      text={bannerText}
      letterSpacing={letterSpacing}
    />
  </Container>
);

export default TextBanner;
