import React, { useEffect, useState } from 'react';

import IconComponent from 'components/icon';

const IconWithEffect = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisible(!visible);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [visible]);

  return (
    <div className="icon-container-with-effect">
      <IconComponent size="75px" iconName="IoPrism" />
    </div>
  );
};
export default IconWithEffect;
