import React, { useState } from 'react';

import { releaseNoteData } from 'data/releaseNotesData';

import Text from 'components/text';

import ReleaseNoteOpen from './ReleaseNoteOpen';
import ReleaseNoteClosed from './ReleaseNoteClosed';

import './index.scss';

const InfobookReleaseNotes = () => {
  const [showNote, setShowNote] = useState(false);
  const [releaseToDisplay, setReleaseToDisplay] = useState({});

  const closeRelease = () => {
    setReleaseToDisplay({});
    setShowNote(false);
  };

  return (
    <section className="release-notes">
      <Text
        width="85%"
        margin="0 auto 40px auto"
        text="Click a release to see more info about it."
      />
      <div className="release-notes-list">
        {showNote ? (
          <ReleaseNoteOpen
            entry={releaseToDisplay}
            handleClick={closeRelease}
          />
        ) : (
          releaseNoteData?.items?.map((entry) => (
            <ReleaseNoteClosed
              entry={entry}
              key={entry?.date}
              showNote={showNote}
              setShowNote={setShowNote}
              setReleaseToDisplay={setReleaseToDisplay}
            />
          ))
        )}
      </div>
    </section>
  );
};

export default InfobookReleaseNotes;
