import React from 'react';

import Text from 'components/text';
import Container from 'components/container';
import CssImplementation from 'components/css-implementation';

const RuleExamplePseudo = () => (
  <Container width="80%" margin="20px auto">
    <Text
      fontSize="18px"
      textAlign="left"
      fontWeight="bold"
      margin="0 auto 10px 0"
      text="CSS Pseudo Rule Example"
    />
    <Text
      lineHeight="2"
      textAlign="left"
      margin="0 auto 10px 0"
      text="Here we are targeting all Header 1 elements and giving them a green font color when the cursor hovers over them."
    />
    <CssImplementation
      selectorName="h1::hover"
      idForCopy="css-attribute-example"
      properties={[{ name: 'color', value: 'green' }]}
    />
  </Container>
);

export default RuleExamplePseudo;
